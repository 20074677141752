import initialState from "./initialState";

export default function userReducer(state = initialState.users, action) {
  switch (action.type) {
    case "LOAD_USERS":
      return { ...state, usersList: action.users };
    case "USER_SELECTED":
      return { ...state, selectedUser: action.selectedUser };
    case "LOAD_USERS_BY_COMPANY":
      return { ...state, allUsersByCompany: action.users };
    default:
      return state;
  }
}
