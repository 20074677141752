export const reportFilterValidator = (values) => {
    const errors = {};

    if (!values.filterType) {
        errors.filterType = 'Required';
    }
    if (!values.fromDate) {
        errors.fromDate = 'Required';
    }
    if (!values.toDate) {
        errors.toDate = 'Required';
    }

    return errors;
}
