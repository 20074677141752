import React, { useEffect, useState } from "react";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import Ellipse9 from "../../../images/Ellipse9.png";
import camera from "../../../images/camera.svg";
import * as userActions from "../../../action/userActions";
import { connect } from "react-redux";
import Select, { renderInputField } from "../../../common/InputFields";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import { reduxForm, initialize, Field } from "redux-form";
import { loadState } from "../../../store/LocalStorage";
import { userProfileValidator } from "./userProfileValidator";
import MobileNavBar from "../../../components/mobileNavBar";

let UserProfile = (props) => {
  const { loggedIn, user, token, modules } = get(loadState(), "auth") || {};

  let auth = {
    token: token || "",
    loggedIn: loggedIn || false,
    modules: modules || [],
    user: {
      ...user,
    },
  };
  const [profilePic, setProfilePic] = useState(get(auth.user, "profilePic"));
  useEffect(() => {
    // props.dispatch(initialize('user', { ...auth.user, passwordHash: null }));
  });

  const onUpload = (event) => {
    userActions
      .uploadFile(auth.token, get(event, "target.files[0]"))
      .then((res) => {
        userActions
          .updateUserProfilePic(auth.token, {
            _id: auth.user._id,
            profilePic: { ...res },
          })
          .then(() => {
            userActions.getUpdatedUserData(auth.token, { _id: auth.user._id });
            setProfilePic(res);
            alert("Profile pic updated successfully");
          })
          .catch((err) => {
            alert(`Profile pic updation is failed`);
          });
      });
  };

  const handleSubmit = (values) => {};

  var profileBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="right_hading d-none d-sm-inline">My Profile</h2>

              <div className="mt-3">
                <a href="#" className="adcm-btn Cancel ms-2">
                  {" "}
                  Cancel
                </a>
                <a href="#" className="adcm-btn ms-2">
                  {" "}
                  Add
                </a>
              </div>
            </div>
            <h2 className="right_hading d-sm-none d-block">Add a New Module</h2>

            <div className="row">
              <div className="col-lg-12">
                <div className="company-main m-prof">
                  <form onSubmit={handleSubmit}>
                    <div
                      className="company-profile"
                      style={profileBackgroundStyle}
                    ></div>
                    <div className="company-fillout">
                      <div className="circle-outer">
                        <div className="circle">
                          {/* <img className="profile-pic" src="assets/images/Ellipse9.png" alt=""/> */}
                          {/* <img className="profile-pic" src={Ellipse9} alt="" /> */}
                          <img
                            className="profile-pic"
                            src={get(profilePic, "Location") || Ellipse9}
                            alt=""
                          />
                          /
                        </div>
                        <div className="p-image">
                          {/* <img className="upload-button" src="assets/images/camera.svg" alt=""/> */}
                          <label for="hgg">
                            <img className="profile-pic" src={camera} alt="" />
                          </label>

                          {/* <input className="file-upload" type="file" accept="image/*"/> */}
                          <div
                            className="photoEdit mt-3"
                            style={{ position: "relative" }}
                          >
                            <input
                              id="hgg"
                              onChange={onUpload}
                              accept={".jpg, .jpeg, .png"}
                              type="file"
                              style={{
                                opacity: 0,
                                width: "100%",
                                position: "absolute",
                              }}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <h3 className="fill-heaing">Employee Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Name</label>
                            {/* <input type="text"
                                                         name="name"
                                                           className="form-control"
                                                             placeholder="ABC"
                                                             value={get(auth.user, 'name')}
                                                               /> */}
                            <Field
                              name="name"
                              component={renderInputField}
                              type="text"
                              label="Enter name"
                            />
                          </div>
                          <div className="form-group">
                            <label>Module Guide</label>
                            {/* <select className="form-select form-control" aria-label="Default select example">
                                                            <option selected>Male</option>
                                                            <option value="1">Female</option>
                                                        </select> */}
                            <Field
                              name="gender"
                              className="form-select form-control"
                              component={Select}
                              type="dropdown"
                              label="Gender"
                              options={{ Male: "Male", Female: "Female" }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email Address</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="akshay@abcmail.com"
                              value={get(auth.user, "emailAddress")}
                              autocomplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="company-fillout snd">
                      <h3 className="fill-heaing">Update Password</h3>
                      <div className="row align-items-end">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Password</label>
                            <div
                              className="input-group"
                              id="show_hide_password"
                            >
                              {/* <input className="form-control" type="password" placeholder="**************" /> */}
                              <Field
                                name="passwordHash"
                                component={renderInputField}
                                type="password"
                                label="Enter new password"
                              />
                              <div className="input-group-addon">
                                <a href="">
                                  <img
                                    src="assets/images/eye-regular.svg"
                                    alt=""
                                    className="toggle-password"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Password</label>
                            <div
                              className="input-group"
                              id="show_hide_password"
                            >
                              {/* <input className="form-control" type="password" placeholder="**************" /> */}
                              <Field
                                name="confirmPasswordHash"
                                component={renderInputField}
                                type="password"
                                label="Confirm password"
                              />
                              <div className="input-group-addon">
                                <a href="">
                                  <img
                                    src="assets/images/eye-regular.svg"
                                    alt=""
                                    className="toggle-password"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserProfile = reduxForm({
  form: "user",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: userProfileValidator,
})(UserProfile);

const mapStateToProps = (state) => {
  return {
    auth: get(state, "auth", {}),
    userData: get(state, "form.user.values"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    userActions: bindActionCreators(userActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

// export default UserProfile;
