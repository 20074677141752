import React from "react";
import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
const DataGrid = (props) => {
  return (
    <div>
      <DataTable
        onChangePage={props.onChangePage}
        columns={props.columns}
        title={props.title}
        data={props.data}
        pagination={props.pagination || true}
        paginationServer={props.paginationServer || true}
        paginationPerPage={props.paginationPerPage}
        striped={true}
        highlightOnHover={true}
        // noDataComponent={<h4>No Data Available! </h4>}
        noDataComponent={<h4> </h4>}
        sortServer={props.sortServer || true}
        onSort={props.onSort}
        keyField={props.keyField}
        paginationTotalRows={props.paginationTotalRows}
        noHeader={props.noHeader}
        expandableRows={props.expandableRows}
        expandableRowsComponent={props.expandableRowsComponent}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        customStyles={props.customStyles}
        className={props.className}
        progressPending={props.progressPending}
      />
    </div>
  );
};
DataGrid.propTypes = {
  onSort: PropTypes.func,
  title: PropTypes.string,
  data: PropTypes.array,
  onChangePage: PropTypes.func,
  columns: PropTypes.array,
  keyField: PropTypes.string,
  paginationTotalRows: PropTypes.number,
  noHeader: PropTypes.bool,
  expandableRows: PropTypes.bool,
  expandableRowsComponent: PropTypes.object,
  paginationPerPage: PropTypes.number,
  onChangeRowsPerPage: PropTypes.func,
};
export default DataGrid;
