import initialState from './initialState';

export default function authReducer(state = initialState.global, action) {

    switch (action.type) {
        case 'LOADING_STARTED':
            return { ...state, isLoading:true };
        case 'LOADING_STOPPED':
            return { ...state, isLoading:false };

        default:
            return state;
    }
}
