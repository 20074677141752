import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as moduleActions from "../../../action/moduleActions";
import * as userActions from "../../../action/userActions";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Ellipse9 from "../../../images/Ellipse9.png";
import { useLocation } from "react-router-dom";
import { get } from "lodash";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ModifyUserDetails = (props) => {
  const { handleSubmit, allModulesByCompany, auth, token, user } = props;
  const [selectedUser, setSelectedUser] = useState([]);
  let [searchTerm, setsearchTerm] = useState("");
  const [profilePic, setProfilePic] = useState("");
  let query = useQuery();
  const history = useHistory();

  localStorage.setItem('userSelectId',query.get("user_id"))

  useEffect(() => {
    moduleActions.getUserDetailbyUserid(query.get("user_id")).then((result) => {
      console.log("getting user detail", result.data);
      setSelectedUser(result.data);
      setProfilePic(result.data.profilePic);
    });
  }, []);

  const keys = ["moduleName"];
  const search = (data) => {
    return data.filter((item) =>
      keys?.some((key) =>
        item?.moduleId?.[key]?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    );
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <div className="d-flex justify-content-between align-items-center">
              <div className="sec1 logo d-block d-lg-none">
                <a href="index.html">
                  <img src={EldcLogo} alt="" />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">User Report</h2>
              {/* <a> */}
              <Link
                to={"/user-management"}
                className="adcm-btn Cancel ms-1 force-return-but"
              >
                <span>Return</span>
              </Link>
              {/* </a> */}
            </div>
            <h2 className="right_hading d-md-none d-block">User Report</h2>

            <div className="row">
              <div className="col-lg-12">
                <div className="company-main user-report">
                  <form action="">
                    <div className="company-profile"></div>
                    <div className="company-fillout">
                      <div className="circle-outer profile-image-circle mb-3 mb-md-0">
                        <div className="circle">
                          <img
                            className="profile-pic"
                            src={get(profilePic, "Location") || Ellipse9}
                            alt=""
                          />
                        </div>
                        <div className="p-image">
                          <img
                            className="upload-button"
                            src="assets/images/camera.svg"
                            alt=""
                          />
                          <input
                            className="file-upload"
                            type="file"
                            accept="image/*"
                          />
                        </div>
                      </div>
                      <div className="profile-image-circle-right">
                        <div className="form-group mb-0">
                          <ul className="row">
                            <li className="col-xl-3 col-md-6 margin-unset">
                              <span className="d-block">Name</span>{" "}
                              {selectedUser.name}
                            </li>
                            <li className="col-xl-3 col-md-6 margin-unset">
                              <span className="d-block"> Username</span>{" "}
                              {selectedUser.name}{" "}
                            </li>
                            <li className="col-xl-3 col-md-6 margin-unset">
                              <span className="d-block"> Email</span>{" "}
                              {selectedUser.emailAddress}
                            </li>
                            <li className="col-xl-3 col-md-6 margin-unset">
                              <span className="d-block">Department</span>{" "}
                              {selectedUser.department}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="row align-items-end">
              <div className="col-md-4 offset-md-8 mb-3">
                <form action="" className="search">
                  <input
                    type="text"
                    placeholder="search ..."
                    style={{ backgroundColor: "aliceblue" }}
                    onChange={(event) => {
                      setsearchTerm(event?.target?.value?.toLowerCase());
                    }}
                  />
                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="modular-table big-table table-responsive">
                  <table className="table align-middle-modify">
                    <thead>
                      <tr>
                        <th scope="col">Module Name</th>
                        {/* <th scope="col" className="text-center">
                          Assign Date
                        </th> */}
                        <th scope="col" className="text-center">
                          Status
                        </th>
                        <th scope="col" className="text-center">
                          Last Attempt
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedUser &&
                        selectedUser.modules &&
                        search(selectedUser.modules).map((item) => (
                          <tr key={item._id}>
                            <td>
                              <Link
                                to={`/module-report/${item?.moduleId?._id}`}
                              >
                                {item?.moduleId?.moduleName}
                              </Link>
                            </td>
                            {/* <td className="text-center">
                              {new Date(item?.createdAt).toLocaleDateString()}
                            </td> */}

                            <td className="text-center">
                              {item.status ? (
                                <a className="activate">active</a>
                              ) : (
                                <a className="incomplete">inactive</a>
                              )}
                            </td>
                            <td className="text-center">
                              {(item.score * 100).toFixed(0)} %
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    initialValues: state.users.selectedUser,
    token: state.auth.token,
    allModulesByCompany: state.modules.allModulesByCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyUserDetails);

// export default ModifyUserDetails;
