import React, { useState, useEffect, Component, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as companyActions from "../../../action/companyActions";
import { reset, initialize, destroy } from "redux-form";
import { Link } from "react-router-dom";
import SearchCompany from "./SearchCompany";
import DataGrid from "../../../common/dataGrid";
import DataTable from "react-data-table-component";
import EldcLogo from "../../../images/MedVR_Logo.png";
import plus from "../../../images/plus.svg";
import Swal from "sweetalert2";
import { getAllDepartments } from "../../../action/departmentActions";
import MobileNavBar from "../../../components/mobileNavBar";
import { GrAdd } from 'react-icons/gr';
import { FiEdit3 } from 'react-icons/fi';

const CompaniesList = (props) => {
  let history = useHistory();
  let [companies, setCompanies] = useState([]);
  let [loading, setLoading] = useState(true);
  let [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (props.auth.user.role == "Manager") {
      props.history.push("/user-management");
    }
    fetchAllCompanies();
  }, []);
  const customStyles = {
    headCells: {
      style: {
        "border-top": "none",
        "font-size": "15px",
        color: "#2F4153",
        "font-weight": 700,
        "&:last-child": {
          "text-align": "right",
        },
      },
    },
  };
  const statusCell = (row) => {
    return (
      <div className="statusText">
        <label className={row.poweredByELDC ? "active" : "inactive"}>
          {row.poweredByELDC ? "Active" : "InActive"}{" "}
        </label>
      </div>
    );
  };

  const actionCell = (row) => {
    return (
      <div style={{ textAlign: "right" }}>
        <Link to={`/modifydepartment/${row._id}`}>
          <span className="primary_link">
            <FiEdit3 />
          </span>
        </Link>
      </div>
    );
  };

  let columns = [
    {
      name: "Department Name",
      selector: "departmentName",
      sortable: true,
      wrap: true,
    },
    {
      name: "Number Of Users",
      selector: "max_users",
      sortable: true,
    },

    { name: "Action", cell: actionCell },
  ];

  const fetchAllCompanies = () => {
    setLoading(true);
    companyActions
      .fetchAllCompanies(props.auth.token)
      .then((response) => setCompanies(response.data))
      // .catch((error) =>
      //   //   toast.error(error)
      //   Swal.fire({
      //     title: "Error",
      //     text: `Error: ${error}`,
      //     icon: "error",
      //     confirmButtonText: "Ok",
      //   })
      // )
      .finally(() => setLoading(false));
  };

  const selectRow = (company) => {
    selectCompany(company);
  };

  const selectCompany = (company) => {
    props.companyActions.selectCompany(company);
    history.push("/modifydepartment");
  };
  const addnewcompany = () => {
    history.push("/addnewcompany");
  };

  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllDepartments().then((data) => {
      // console.log("apply", data.data)
      setLoading(false);
      setDepartments(data.data)
    });
  }, []);

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">
                All Departments
              </h2>
              {/* <form action="" className="search">
                <SearchCompany
                  onItemSelected={selectCompany}
                  companyList={companies}
                />
              </form> */}
              <a className="adcm-btn ms-2 px-3 mt-2 " onClick={addnewcompany}>
                <GrAdd /> Add
                Department
              </a>
            </div>
            <h2 className="right_hading d-md-none d-block">All Companies</h2>

            <div className="row">
              <div className="col-xl-12">
                <div className="modular-table table-responsive-xl">
                  <DataTable
                    noHeader
                    data={departments?.reverse()}
                    columns={columns}
                    keyField="_id"
                    // progressPending={loading}
                    customStyles={customStyles}
                    pagination
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {!loading ?
          <></>
          :
          <div className="loader-wrapper">
            <div className="loader"></div>

          </div>

        }
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    companiesList: state.companies.companiesList,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    companyActions: bindActionCreators(companyActions, dispatch),
    dispatch: dispatch,
  };
};
export default connect(mapStatetoProps, mapDispatchToProps)(CompaniesList);
