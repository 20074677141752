import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import blankImg from "../../../images/Image.png";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { loadState } from "../../../store/LocalStorage";
import * as moduleActions from "../../../action/moduleActions";
import * as userdashboardActions from "../../../action/userdashboardActions";
import Search from "../../common/Search";
import { initialize } from "redux-form";
import { getDateInFormat } from "../../../common/utilityFunctions";
import { getValueByName } from "../../../common/utilityFunctions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import arrowdown from "../../../images/arrow-down-left.svg";
import { get } from "lodash";
import MobileNavBar from "../../../components/mobileNavBar";

const getExpiryDate = (data, companyId) => {
  if (data && data.length > 0 && companyId) {
    const compObj = data.find(
      (company) => companyId === get(company, "companyId")
    );
    return getDateInFormat(get(compObj, "validTill", ""));
  }
};

const ModuleLibrary = (props) => {
  // const {  auth2, moduleList } = props;
  let [averageScore, setAverageScore] = useState(null);
  const [allModules, setAllModules] = useState([]);
  let [modulesStatus, setModulesStatus] = useState(null);
  const history = useHistory();
  const { loggedIn, user, token, modules } = get(loadState(), "auth") || {};
  let auth = {
    token: token || "",
    loggedIn: loggedIn || false,
    modules: modules || [],
    user: {
      ...user,
    },
  };
  const [moduleList, setmoduleList] = useState(auth.modules);
  const [click, setclick] = useState(false);

  useEffect(() => {
    loadUserAverageScore();
    loadUserModulesStatus();
    userdashboardActions.getAllModulesWithScore().then((res) => {
      setAllModules(res.data);
    });
    //  moduleActions.getUserModules(auth.token);
  }, []);

  const loadUserAverageScore = () => {
    userdashboardActions
      .getUserAverageScore()
      .then((response) => setAverageScore(response.data.score))
      .catch((error) =>
        console.error(
          `failed to get user Average Score  ${error.response.data.message}`
        )
      );
  };
  const loadUserModulesStatus = () => {
    userdashboardActions
      .getUserModuleStatus()
      .then((response) => setModulesStatus(response.data))
      .catch((error) =>
        console.error(
          `failed to get user modules data  ${error.response.data.message}`
        )
      );
  };

  const onItemSelected = (module) => {
    const serializedState = JSON.stringify(module);
    localStorage.setItem("selectedModule", serializedState);

    history.push("/library-details");
  };

  const selectedModule = (data) => {
    const serializedState = JSON.stringify(data);
    localStorage.setItem("selectedModule", serializedState);
    // props.dispatch(initialize('selectedmodule', { ...data }));
    // history.push(`/${goto}`);
    history.push("/library-details");
  };
  const DonloadModule = () => {
    history.push("/library-details-download");
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">
                Module Library
              </h2>
              <form action="" className="search mt-3">
                {/* <input type="text" placeholder="Search..." /> */}
                <Search
                  {...{
                    data: moduleList,
                    onItemSelected,
                    populateFieldName: "moduleName",
                    placeholderLabel: "Search...",
                  }}
                />
              </form>
            </div>
            <h2 className="right_hading d-md-none d-block">Module Library</h2>

            <div className="row">
              <div className="row">
                {moduleList &&
                  moduleList.map((item) => {
                    if (item.status) {
                      return (
                        <div
                          className={
                            !item.status
                              ? "col-xxl-3 col-xl-4 col-lg-6 col-sm-6"
                              : "col-xxl-3 col-xl-4 col-lg-6 col-sm-6"
                          }
                        >
                          <div className="mlbox">
                            <div className="tp-bx">
                              <img
                                src={
                                  get(item, "bannerImage.Location") || blankImg
                                }
                                alt=""
                                onClick={() => selectedModule(item)}
                              />
                            </div>
                            <div className="bt-bx">
                              {/* <h4>{item.moduleName}</h4> */}
                              <h6>{item.moduleName}</h6>
                              <p>
                                {item.platforms &&
                                  item.platforms.map((platform) => (
                                    <p> {platform} &nbsp; </p>
                                  ))}
                              </p>
                              <div
                                className="d-flex"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <Link
                                  to={`/library-details`}
                                  className="vw-ml-btn"
                                  onClick={() => selectedModule(item)}
                                >
                                  View Module
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    moduleList: state.modules.userModulesList,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLibrary);
