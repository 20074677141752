import React, { useEffect, useState } from "react";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import Ellipse9 from "../../../images/Ellipse9.png";
import camera from "../../../images/camera.svg";
import eye from "../../../images/eye-regular.svg";
import * as userActions from "../../../action/userActions";
import * as moduleActions from "../../../action/moduleActions";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Select, { renderInputField } from "../../../common/InputFields";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { get } from "lodash";
import Swal from "sweetalert2";
import { reduxForm, initialize, Field } from "redux-form";
import { loadState } from "../../../store/LocalStorage";
import { userProfileValidator } from "./userProfileValidator";
import "react-toastify/dist/ReactToastify.css";
import { getDepartment } from "../../../action/departmentActions";
import MobileNavBar from "../../../components/mobileNavBar";

let EmployeeProfile = (props) => {
  const history = useHistory();
  const { auth, user, token } = props;

  const [passwordShown, setPasswordShown] = useState(false);
  const [ConpasswordShown, setConPasswordShown] = useState(false);
  const [department, setDepartment] = useState({});

  const [profilePic, setProfilePic] = useState(get(user, "profilePic"));
  const [formData, setName] = useState({
    name: get(user, "name"),
    emailAddress: get(user, "emailAddress"),
    passwordHash: "",
    gender: get(user, "gender") || "Male",
    confirmPasswordHash: "",
  });
  useEffect(() => {
    // props.dispatch(initialize('user', { ...auth.user, passwordHash: null }));
  });
  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);
  const onUpload = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      userActions
        .updateUserProfilePic(token, { _id: user._id, profilePic: { ...res } })
        .then(() => {
          props.userActions.getUpdatedUserData(token, {
            _id: user._id,
          });
          setProfilePic(res);
          // toast('Profile pic updated successfully');
          Swal.fire({
            title: "Success",
            text: "Profile pic updated successfully",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        })
        .catch((err) => {
          // toast(`Profile pic updation is failed`);
          Swal.fire({
            title: "Error",
            text: "Profile pic updation is failed",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const values = {
      confirmPasswordHash: parseInt(formData.confirmPasswordHash),
      name: formData.name,
      passwordHash: parseInt(formData.passwordHash),
      gender: formData.gender,
      emailAddress: formData.emailAddress,
      _id: user._id,
    };
    if (
      formData.name &&
      formData.gender
      //   &&
      //   formData.passwordHash &&
      //   formData.confirmPasswordHash
    ) {
      if (formData.passwordHash === formData.confirmPasswordHash) {
        userActions
          .updateUserProfile(token, { ...values })
          .then(() => {
            props.userActions.getUpdatedUserData(token, { _id: user._id });
            Swal.fire({
              title: "Success",
              text: "User profile updated successfully",
              icon: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then(function () {
              // history.push("/");
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: `Error: ${err}`,
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          });
      } else {
        Swal.fire({
          title: "Error",
          text: "Password not matched",
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Please enter all fields",
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConPassword = () => {
    setConPasswordShown(!ConpasswordShown);
  };

  const handleChange = (e) => {
    setName({ ...formData, [e.target.name]: e.target.value });
  };

  var profileBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <form onSubmit={handleSubmit}>
              <MobileNavBar />
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="right_hading d-none d-sm-inline">My Profile</h2>

                <div className="mt-3">
                  <Link to="/" className="adcm-btn Cancel ms-2 align-bottom">
                    <span>Return</span>
                  </Link>

                  <button
                    type="submit"
                    className="btn adcm-btn ms-2"
                    style={{ color: "#fff" }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <h2 className="right_hading d-sm-none d-block">
                Add a New Module
              </h2>
              <div className="row">
                <div className="col-lg-12">
                  <div className="company-main m-prof">
                    <div
                      className="company-profile"
                      style={profileBackgroundStyle}
                    ></div>
                    <div className="company-fillout">
                      <div className="circle-outer">
                        <div className="circle">
                          <img
                            className="profile-pic"
                            src={get(profilePic, "Location") || Ellipse9}
                            alt=""
                          />
                        </div>
                        <div className="p-image">
                          <img className="profile-pic" src={camera} alt="" />
                          {/* <input className="file-upload" type="file" accept="image/*"/> */}
                          <div
                            className="photoEdit mt-3"
                            style={{ position: "relative" }}
                          >
                            <input
                              onChange={onUpload}
                              accept={".jpg, .jpeg, .png"}
                              type="file"
                              className="overlaydpchange"
                            ></input>
                          </div>
                        </div>
                      </div>
                      <h3 className="fill-heaing">Employee Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="ABC"
                              value={formData.name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>Gender</label>
                            <select
                              name="gender"
                              onChange={handleChange}
                              className="form-select form-control"
                              aria-label="Default select example"
                            >
                              {user.gender == "Male" ? (
                                <option value="Male" selected>
                                  Male
                                </option>
                              ) : (
                                <option value="Male">Male</option>
                              )}

                              {user.gender == "Female" ? (
                                <option value="Female" selected>
                                  Female
                                </option>
                              ) : (
                                <option value="Female">Female</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email Address</label>
                            <input
                              type="text"
                              name="emailAddress"
                              className="form-control"
                              placeholder="akshay@abcmail.com"
                              value={formData.emailAddress}
                              autocomplete="off"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="company-fillout snd">
                      <h3 className="fill-heaing">Update Password</h3>
                      <div className="row align-items-end">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Password</label>
                            <div
                              className="input-group"
                              id="show_hide_password"
                            >
                              <input
                                type={passwordShown ? "text" : "password"}
                                id="password-field"
                                name="passwordHash"
                                value={formData.passwordHash}
                                onChange={handleChange}
                                placeholder="***********"
                                className="form-control"
                                // required
                              />
                              <div class="input-group-addon">
                                <a>
                                  <img
                                    src={eye}
                                    className="toggle-password"
                                    onClick={togglePassword}
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Password</label>
                            <div
                              className="input-group"
                              id="show_hide_password"
                            >
                              <input
                                type={ConpasswordShown ? "text" : "password"}
                                id="password-field"
                                name="confirmPasswordHash"
                                value={formData.confirmPasswordHash}
                                onChange={handleChange}
                                placeholder="***********"
                                className="form-control"
                                // required
                              />
                              <div class="input-group-addon">
                                <a>
                                  <img
                                    src={eye}
                                    className="toggle-password"
                                    onClick={toggleConPassword}
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfile);
