import React, { useState, useEffect } from "react";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { getDepartment } from "../../../action/departmentActions";
import "../../../";
import { connect } from "react-redux";
import MobileNavBar from "../../../components/mobileNavBar";

let ImportUsersStep1 = (props) => {
  const { auth, user } = props;
  const [department, setDepartment] = useState({});

  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);

  let {
    onChange,
    onSelectCompany,
    selectedCompany,
    userShouldChangePasswordAtFirstLogin,
    onSubmit,
    userValue,
    file,
  } = props;

  return (
    <>
      <div className="col-xl-12 col-lg-12 overview-left-area your-module">
        <MobileNavBar />
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="right_hading d-none d-sm-inline">Import Users</h2>

          <div className="mt-3 width-100percent-mob text-center">
            <a>
              <Link to={"/user-management"} className="adcm-btn Cancel ms-1 mb-1">
                <span>Cancel</span>
              </Link>
            </a>
            <Link to="/files/csv_template.csv" target="_blank">
              <button className="adcm-btn secondary-btn ms-2 mb-1" style={{ minWidth: "150px" }}>
                {/* <CSVLink
                data={[
                  [
                    "name",
                    "emailAddress",
                    "departmentName",
                    "location",
                    "course",
                    // "role",
                    // "userID",
                    // "gender",
                    // "modules",
                  ],
                ]}
                filename={"users_sample.csv"}
              >
                Download Sample
              </CSVLink> */}
                Download Sample
              </button>
            </Link>
            <button onClick={onSubmit} className="adcm-btn ms-2 mb-1">
              Upload
            </button>
          </div>
        </div>
        <h2 className="right_hading d-sm-none d-block">Import Users</h2>
        <div className="row">
          <div className="col-lg-12">
            <div className="company-main add-module">
              <form action="">
                <div className="company-fillout">
                  <h3 className="fill-heaing">Import Information</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-5">
                        <div className="custom-file">
                          <label>Import CSV</label>
                          <input
                            type="file"
                            className="custom-file-input"
                            onChange={onChange}
                            id="importUserFile"
                            required
                            accept=".csv"
                          />
                          <span
                            className="custom-file-label"
                            for="validatedCustomFile"
                          >
                            {file?.name ? file.name : "Choose file..."}
                          </span>
                          <button
                            type="button"
                            className="btn themesecondarybackground fileUpload"
                          >
                            
                          </button>
                        </div>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input me-2"
                          name="UserpassPolicy"
                          checked={userShouldChangePasswordAtFirstLogin}
                          id="UserpassPolicy"
                          onChange={onChange}
                        />
                        <label className="form-check-label" for="exampleCheck1">
                          User should change password on first login
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ImportUsersStep1);
