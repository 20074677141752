import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Search from "../../common/Search";
import * as moduleActions from "../../../action/moduleActions";
import * as userActions from "../../../action/userActions";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import MobileNavBar from "../../../components/mobileNavBar";

function ModuleAssignment(props) {

    const { auth, allUsersByCompany = [] } = props || {};
    // console.log("user Info", auth?.user?._id)
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    let [moduleList, setAllModules] = useState([]);
  
    useEffect(() => {
        // props.moduleActions.getAllModules(auth.token);
        props.userActions.getAllUsersByCompany(auth.token, auth.user.companyId);
        loadAllModules();
      }, []);
    
      const loadAllModules = () => {
        moduleActions
          .fetchAllModulesByManager(auth?.user?._id)
          .then((response) => setAllModules(response.data))
          .catch((error) =>
            // toast.error(`Unable to fetch modules ${error.data}`)
            Swal.fire({
              allowOutsideClick: false,
              title: "Error",
              text: `Unable to fetch modules: ${error}`,
              icon: "error",
              confirmButtonText: "Ok",
            })
          );
      };
      const onUserItemSelected = (user) => {
        setSelectedUsers([...selectedUsers, user]);
      };
      const onModuleItemSelected = (module) => {
        console.log("select module", module)
        setSelectedModules([...selectedModules, module]);
        // setSelectedModules([module]);
      };
      // const removeUser = (userID) => {
      //     const newSelectedUsers = selectedUsers.filter(item => item._id !== userID);
      //     setSelectedUsers([...newSelectedUsers]);
      // }
      const onRemoveUserClick = (e, userID) => {
        e.stopPropagation();
        const newSelectedUsers = selectedUsers.filter(
          (item) => item._id !== userID
        );
        setSelectedUsers([...newSelectedUsers]);
      };
    
      // const removeModule = (moduleID) => {
      //     const newSelectedModules = selectedModules.filter(item => item._id !== moduleID);
      //     setSelectedModules([...newSelectedModules]);
      // }
      const onRemoveModuleClick = (e, moduleID) => {
        e.stopPropagation();
        const newSelectedModules = selectedModules.filter(
          (item) => item._id !== moduleID
        );
        setSelectedModules([...newSelectedModules]);
      };
    
      const assignModuleToUser = () => {
        const users = selectedUsers.map((item) => item._id);
        const modules = selectedModules.map((item) => item.moduleId);
    
        userActions
          .assignModuleToUser(auth.token, { users, modules })
          .then(() => {
            Swal.fire({
              allowOutsideClick: false,
              title: "Success",
              text: "Selected modules are assigned to the selected users",
              icon: "success",
              confirmButtonText: "Ok",
            });
          })
          .catch((err) => {
            Swal.fire({
              allowOutsideClick: false,
              title: "Error",
              text: `${(err.message, { type: "error" })}`,
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      };
    

    return (
        <>
        <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <form action="">
              <MobileNavBar />
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="right_hading d-none d-sm-inline">Module Assignment</h2>

                <div className="mt-3">
                  <a>
                    <Link to="/" className="adcm-btn Cancel ms-1">
                      <span>Cancel</span>
                    </Link>
                  </a>
                  <a
                    href="#"
                    className="adcm-btn ms-2"
                    onClick={() => assignModuleToUser()}
                  >
                    {" "}
                    Save
                  </a>
                  {/* <div className="field-group text-right mt-3" onClick={() => assignModulesToUsers()}>
                                        <button type="submit" className="btn middiumBttn themeColorBttn">Assign</button></div> */}
                </div>
              </div>
              <h2 className="right_hading d-sm-none d-block">Assignment</h2>

              <div className="row">
                <div className="col-lg-12">
                  <div className="company-main add-module">
                    <div className="company-fillout">
                      <h3 className="fill-heaing">Learners Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Learners Name</label>  
                            {/* <input type="text" className="form-control" placeholder="ABC Co" autocomplete="off" /> */}
                            <Search
                              {...{
                                data: allUsersByCompany,
                                onItemSelected: onUserItemSelected,
                                populateFieldName: "name",
                                placeholderLabel: "Search Users",
                              }}
                            />
                            <div className="flex2fieldForm">
                              <div className="field-group">
                                {selectedUsers && selectedUsers.length > 0 && (
                                  <div className="flex2fieldForm">
                                    <div className="field-group">
                                      <div className="radioGroup">
                                        {selectedUsers.map((item, index) => (
                                          <div
                                            key={`${item}-${index}`}
                                            className="mr-4 moduleCompetencyCard"
                                          >
                                            <label htmlFor="Module">
                                              {item.name}
                                            </label>
                                            <button
                                              onClick={(e) =>
                                                onRemoveUserClick(e, item._id)
                                              }
                                            >
                                              X
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="company-fillout snd">
                      <h3 className="fill-heaing">Module Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Modules</label>
                            <Search
                              {...{
                                data: moduleList,
                                onItemSelected: onModuleItemSelected,
                                populateFieldName: "moduleName",
                                placeholderLabel: "Select Modules",
                              }}
                            />
                            <div className="flex2fieldForm">
                              <div className="field-group">
                                {selectedModules &&
                                  selectedModules.length > 0 && (
                                    <div className="flex2fieldForm">
                                      <div className="field-group">
                                        <div className="radioGroup">
                                          {selectedModules.map(
                                            (item, index) => (
                                              <div
                                                key={`${item}-${index}`}
                                                className="mr-4 moduleCompetencyCard"
                                              >
                                                <label htmlFor="Module">
                                                  {item.moduleName}
                                                </label>
                                                <button
                                                  onClick={(e) =>
                                                    onRemoveModuleClick(
                                                      e,
                                                      item._id
                                                    )
                                                  }
                                                >
                                                  X
                                                </button>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,    
    allUsersByCompany: state.users.allUsersByCompany,
});

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        moduleActions: bindActionCreators(moduleActions, dispatch),
        dispatch: dispatch,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleAssignment);