import { get } from "lodash";
import { loadState } from "../LocalStorage";

const { loggedIn, user, token, modules, loggedInError } =
  get(loadState(), "auth") || {};

export default {
  companies: {
    companiesList: [],
  },
  users: {
    usersList: [],
  },
  modules: {
    modulesList: [],
    userModulesList: [],
    moduleReport: {
      competencyReport: [],
      moduleReport: [],
    },
  },

  globalError: {},
  auth: {
    token: token || "",
    loggedIn: loggedIn || false,
    loggedInError: loggedInError || "",
    loginFailed: false,
    modules: modules || [],
    user: {
      ...user,
    },
  },

  global: {
    isLoading: false,
  },

  theme: "dark-theme",
  companyDefaults: {},
};
