import initialState from "./initialState";

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case "LOGIN_SUCCESSFUL":
      return {
        ...state,
        token: action.loginResult.token,
        loggedIn: true,
        user: action.loginResult.user,
        loggedInError: null,
        hello: "Akhtar",
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        token: "",
        loggedIn: false,
        user: null,
        loggedInError: action.failure?.response?.data,
      };
    case "LOGOUT":
      return {
        ...state,
        token: "",
        loggedIn: false,
        user: null,
        loggedInError: null,
      };

    default:
      return state;
  }
}
