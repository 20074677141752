import React, { useState, useEffect } from "react";
import ImportUsersStep1 from "./ImportUsersStep1";
import ImportUsersStep2 from "./ImportUsersStep2";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../../action/userActions";
import * as globalActions from "../../../action/globalActions";
import { toast } from "react-toastify";
import * as companyActions from "../../../action/companyActions";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";

let ImportUsersPage = (props) => {
  const { user } = props;

  let [companies, setCompanies] = useState([]);
  let [step, setStep] = useState(1);
  const history = useHistory();
  let [companyId, setCompanyId] = useState(null);
  let [company, setCompany] = useState(null);
  let [loading, setLoading] = useState(false);
  let [file, selectFile] = useState(null);
  let [importResult, setImportResults] = useState(null);

  let [
    userShouldChangePasswordAtFirstLogin,
    setUserShouldChangePasswordAtFirstLogin,
  ] = useState(false);

  useEffect(() => {
    props.companyActions.getAllCompanies();
    setCompanies(props.companies.companiesList);
  }, []);

  const onChange = (event) => {
    if (event.target.id === "importUserFile") selectFile(event.target.files[0]);

    if (event.target.id === "UserpassPolicy")
      setUserShouldChangePasswordAtFirstLogin(event.target.checked);
  };
  const selectCompany = (data) => {
    setCompanyId(data && data._id);
    setCompany(data && data.companyName);
  };

  const validateFile = () => {
    props.dispatch(globalActions.initiateLoad());
    userActions
      .validateImportUserFile(
        props.token,
        file,
        // companyId,
        "638f418c1c5f3d0f0a38ee25",
        userShouldChangePasswordAtFirstLogin
      )
      .then((response) => {
        setImportResults(response.data);
        setStep(2);
      })
      .catch((error) => {
        Swal.fire({
          title: "Failed to upload !",
          text: error.response.data.message,
          icon: "error",
          confirmButtonColor: "#15bd8c",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(() => {
          window.location.reload();
        });
        // toast(error.response ? error.response.data.message : error.message);
      })
      .finally(() => props.dispatch(globalActions.endLoad()));
  };

  const processFile = () => {
    props.dispatch(globalActions.initiateLoad());
    userActions
      .processImportUsersFile(props.token, importResult._id)
      .then((response) => {
        console.log(response);
        Swal.fire({
          title: `${response.data.length} users added successfully !`,
          text: response.data.message,
          icon: "success",
          allowOutsideClick: false,
          showCancelButton: true,
          cancelButtonColor: "#15bd8c",
          cancelButtonText: "Close",
          confirmButtonColor: "#15bd8c",
          confirmButtonText: "Send Email Invite",
        }).then((result) => {
          if (result.isConfirmed) {
            userActions
              .sendInviteAction(props.token, response.data)
              .then(() =>
                Swal.fire({
                  title: "Success",
                  text: "The invitation links have been sent to the users",
                  icon: "success",
                  allowOutsideClick: false,
                })
              )
              .catch((error) =>
                Swal.fire({
                  title: "Error",
                  text: `Error: ${error}`,
                  icon: "error",
                  confirmButtonText: "Close",
                  allowOutsideClick: false,
                })
              );
          }
        });
        history.push("/user-management");
      })
      .catch((error) =>
        Swal.fire({
          title: "Failed to add users !",
          text: error.response ? error.response.data.message : error.message,
          icon: "success",
          confirmButtonColor: "#15bd8c",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        })
      )
      .finally(() => props.dispatch(globalActions.endLoad()));
  };

  const onBack = () => setStep(1);

  return (
    <>
      <div class="overview-cont">
        <div class="row">
          {step === 1 && (
            <ImportUsersStep1
              selectedCompany={company}
              onSelectCompany={selectCompany}
              userShouldChangePasswordAtFirstLogin={
                userShouldChangePasswordAtFirstLogin
              }
              onChange={onChange}
              onSubmit={validateFile}
              userValue={user}
              companyList={props.companies.companiesList}
              file={file}
            />
          )}

          {step === 2 && (
            <ImportUsersStep2
              users={importResult.successRecords}
              onSubmit={processFile}
              onBack={onBack}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    companies: state.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    companyActions: bindActionCreators(companyActions, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportUsersPage);
