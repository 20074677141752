import initialState from './initialState';

export default function companyReducer(state = initialState.companies, action) {

    switch (action.type) {
        case 'LOAD_COMPANIES':
            return { ...state, companiesList: action.companies };
        case 'COMPANY_SELECTED':
            return { ...state, selectedCompany: action.selectedCompany };

        default:
            return state;
    }
}
