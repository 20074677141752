import React, { useEffect, useState } from "react";
import charthree from "../../../images/charthree.jpg";
import chartfour from "../../../images/chartfour.jpg";
import chartfive from "../../../images/chartfive.jpg";
import EldcLogo from "../../../images/MedVR-Logo.png";
import { AreaChart, HorizontalBarChart } from "../../../common/chart";
import * as admindashboardActions from "../../../action/adminDashboardActions";
import { get } from "lodash";

const Dashboard = () => {
  let [kpis, setKpis] = useState(null);
  let [activeusersByMonth, setActiveUsersByMonth] = useState(null);
  let [coursesOverview, setCoursesOverview] = useState(null);
  const navbarToggler = document.body.querySelector(".navbar-toggler");

  useEffect(() => {
    // fetchActiveUsersList();
    fetchCompanyKpis();
    fetchSessionsByMonth();
    fetchCoursesOverview();
  }, []);

  const fetchSessionsByMonth = () => {
    admindashboardActions
      .getActiveSessionsByMonth()
      .then((response) => {
        setActiveUsersByMonth(response.data);
      })
      .catch((error) =>
        console.error(
          `There was error while fetching Sessions By Month ${error.response.data.message}`
        )
      );
  };
  const fetchCompanyKpis = () => {
    admindashboardActions
      .getCompanyKpis()
      .then((response) => setKpis(response.data))
      .catch((error) =>
        console.error(
          `There was error while fetching Company Kpi ${error.response.data.message}`
        )
      );
  };
  const fetchCoursesOverview = () => {
    admindashboardActions
      .getCoursesOverview()
      .then((response) => {
        const coursesOverview =
          response.data &&
          response.data.length > 0 &&
          response.data.slice(0, 5);
        const coursesOverviewDataToShowOnGraph =
          coursesOverview &&
          coursesOverview.map((item) => {
            return {
              moduleName: get(item, "_id.moduleName"),
              failed: get(item, "failed"),
              passed: get(item, "passed"),
            };
          });
        setCoursesOverview(coursesOverviewDataToShowOnGraph);
      })
      .catch((error) =>
        console.error(
          `There was error while fetching Courses overview ${error.response.data.message}`
        )
      );
  };

  const activerUserData = () => {
    return [
      {
        userType: "Active Users",
        userCount: kpis && kpis.activeUsersCount,
      },
      {
        userType: "Total Users",
        userCount: kpis && kpis.totalUsersCount,
      },
    ];
  };
  const responsiveNavItems = [].slice.call(
    document.querySelectorAll("#navbarResponsive .nav-link")
  );

  const handleToggle = () => {
    responsiveNavItems.map(function (responsiveNavItems) {
      responsiveNavItems.addEventListener("click", () => {
        if (window.getComputedStyle(navbarToggler).display !== "none") {
          navbarToggler.click();
        }
      });
    });
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <div className="d-flex justify-content-between align-items-center">
              <div className="sec1 logo d-block d-lg-none">
                <a href="index.html">
                  <img src={EldcLogo} alt="" />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span
                  onClick={handleToggle}
                  className="navbar-toggler-icon"
                ></span>
              </button>
            </div>
            <div className="d-flex align-items-center">
              <h2 className="right_hading d-none d-md-inline">Dashboard</h2>

              <form action="" className="search m-auto">
                <input type="text" placeholder="Search..." />
              </form>
            </div>
            <h2 className="right_hading d-md-none d-block">Dashboard</h2>

            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 progress-bar-sec">
                <div className="sec1">
                  <h4>Average Session</h4>
                  <div className="row">
                    <div className="col-7 pr-0">
                      <h5>
                        {kpis && kpis.averageSessionDuration
                          ? `${kpis.averageSessionDuration.toFixed(2)} min `
                          : "N/A"}{" "}
                      </h5>
                    </div>
                    {/* <div className="col-5 text-end">
                                            <h6>9% <i className="fa-sharp fa-solid fa-arrow-down"></i></h6>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 progress-bar-sec">
                <div className="sec1">
                  <h4>Active Customer</h4>
                  <div className="row">
                    <div className="col-7 pr-0">
                      <h5>{kpis && kpis.activeUsersCount} </h5>
                    </div>
                    {/* <div className="col-5 text-end">
                                            <h6 className="green-text">17% <i className="fa-sharp fa-solid fa-arrow-down"></i></h6>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 progress-bar-sec">
                <div className="sec1">
                  <h4>Total Customer</h4>
                  <div className="row">
                    <div className="col-7 pr-0">
                      <h5>{kpis && kpis.totalUsersCount} </h5>
                    </div>
                    {/* <div className="col-5 text-end">
                                            <h6>13% <i className="fa-sharp fa-solid fa-arrow-down"></i></h6>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 progress-bar-sec">
                <div className="sec1">
                  <h4>Most Viewed Module</h4>
                  <div className="row">
                    <div className="col-7 pr-0">
                      <h5>
                        {kpis && kpis.mostViewedModule
                          ? kpis.mostViewedModule
                          : "N/A"}
                      </h5>
                    </div>
                    {/* <div className="col-5 text-end">
                                            {/* <h6 className="green-text">17% <i className="fa-sharp fa-solid fa-arrow-down"></i></h6> 
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="sec2">
                  <form action="">
                    <div className="tableHeadingDBM p-0">
                      <h4>Today Trends</h4>
                    </div>
                    {/* <p>as of {new Date().toDateString()}</p> */}
                    {/* <img src={charthree} alt="" /> */}
                    {activeusersByMonth && activeusersByMonth.length > 0 && (
                      <AreaChart
                        data={activeusersByMonth}
                        dataKey="count"
                        axisLine={false}
                        width={1000}
                        height={300}
                        nameKey="month"
                      />
                    )}
                    {activeusersByMonth && activeusersByMonth.length === 0 && (
                      <div className="text-center">
                        {" "}
                        <label>No Session History Available.</label>{" "}
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="sec2">
                  <form action="">
                    <div className="sec2-header">
                      <h2>Active Sessions</h2>
                    </div>
                    {/* <img src={chartfour} alt="" /> */}
                    <HorizontalBarChart
                      data={activerUserData()}
                      dataKey="userType"
                      barColor="#4458FE"
                      barSize={72}
                      axisLine={false}
                      tickLineXaxis={false}
                      width={350}
                      height={300}
                    />
                    <div className="barchart-yaxis-text">USERS</div>
                  </form>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="sec2">
                  <form action="">
                    <div className="sec2-header">
                      <h2>
                        Courses Overview <span>(Today)</span>{" "}
                      </h2>

                      <p className="swst active">SUCCESSFUL</p>
                      <p className="swst danger">FAILED</p>
                    </div>
                    {/* <img src={chartfive} alt="" /> */}
                    <HorizontalBarChart
                      data={coursesOverview}
                      dataKey="moduleName"
                      barColors={["#ea4335", "#00D0B5"]}
                      barSize={25}
                      axisLine={false}
                      tickLineXaxis={false}
                      width={750}
                      height={300}
                    />
                    <div className="barchart-yaxis-text">COURSE</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
