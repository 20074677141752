import React, { useEffect, useState,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import * as moduleActions from "../../../action/moduleActions";
import { reduxForm, Field, initialize } from "redux-form";
import { get } from "lodash";
import Background from "../../../images/company-profile-bg.jpg";
import * as userActions from "../../../action/userActions";
import MobileNavBar from "../../../components/mobileNavBar";
import { useLocation } from "react-router-dom";
import { FaPlus, FaTrash, FaChevronDown } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { axios } from 'axios';
import Swal from "sweetalert2";
import Select from "react-select"
import { debounce } from 'lodash';
import { loadState } from "../../../store/LocalStorage";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
let ModifyGrouppage = (props) => {
  const { handleSubmit, auth, token } = props;
  const [module, setModuleData] = useState({});
  const [groupDetail, setGroupDetail] = useState({});
  const [HomePage, setHomepage] = useState(Background);
  const [groupModuleData, setGroupModuleData] = useState([]);
  const [fetchModuleData, setFetchModuleData] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [assignedUserNames, setAssignedUserNames] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  let [moduleList, setAllModules] = useState([]);
  const [allModuleData, setAllModuleData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [isAssignOpen, setIsAssignOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownAssignRef = useRef(null);

  let query = useQuery();

  const [userInfo, setuserInfo] = useState("");
  const dispatch = useDispatch();
  const { loggedIn, user, tokens, modules } = get(loadState(), "auth") || {};

  let auths = {
    token: tokens || "",
    loggedIn: loggedIn || false,
    modules: modules || [],
    user: {
      ...user,
    },
  };
  useEffect(() => {
    // dispatch(getAllPosts());
    setuserInfo(auths.user.role);
    console.log("user role", auths.user.role)

    // console.log(
    //   "CHECK",
    //   JSON.parse(sessionStorage.getItem("reloaded")) !== null
    // );

    // console.log(JSON.parse(localStorage.getItem("isAuthenticated")) === false);

    // if (
    //   JSON.parse(sessionStorage.getItem("reloaded")) === true &&
    //   JSON.parse(localStorage.getItem("isAuthenticated")) === false
    // ) {
    //   console.log("reload!!!");
    //   window.location = "/signin";
    // }
  }, []);


  useEffect(() => {
    const fetchGroupDetail = async () => {
      try {
        const response = await moduleActions.getGroupDetail(
          query.get("categoryId")
        );
        console.log(
          "Group Detail Data",
          response.data.modules.map((item) => item.moduleName)
        );
        const moduleValue = response.data.modules;
        setGroupModuleData(moduleValue);
        setGroupDetail(response.data);
        allModuleData(moduleValue)
      } catch (error) {
        console.error("Error fetching group detail:", error);
      }
    };
    fetchGroupDetail();



    // const fetchGroupByDepartment = async () => {
    //   try {
    //     const response = await moduleActions.getAllGroups(auth.user.departmentId[0]);
    //     console.log("resdponse data", response);
    //   } catch (error) {
    //     console.error("Error while fetching.")
    //   }
    // }

    // fetchGroupByDepartment();
  }, [query]);

  useEffect(() => {
    // props.moduleActions.getAllModules(auth.token);

    // props.userActions.getAllUsersByCompany(auth.token, auth.user.companyId);
    // loadAllModules();
    userActions.getModules().then((response) => {
      setAllModules(response.data);
      // console.log("module data", response.data)
    });
  }, []);

  useEffect(() => {
    const fetchModule = async () => {
      try {
        const response = await moduleActions.getModule();
        console.log("module name data", response.data);
        setFetchModuleData(response.data);
      } catch (error) {
        console.log("error in fetchmodule", error);
      }
    };

    fetchModule();
  }, []);

  const fetchUsers = async (term) => {
    if (!term || !term.trim()) return; // Don't fetch if the term is empty or just whitespace
    console.log("Fetching users for query:", term); // Log the term being searched
    try {
      const response = await moduleActions.getAllUser(term);
      if (Array.isArray(response.data)) {
        setAllUsers(response.data);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.log("Error while fetching users", error); // Log the error object
    }
  };

  // Debounced version of fetchUsers
  const debouncedFetchUsers = useRef(debounce((term) => {
    fetchUsers(term);
  }, 300)).current;

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedFetchUsers(value)
  };

  const handleUserSelect = (user) => {
    if (!selectedUserIds.includes(user._id)) {
      setSelectedUserIds([...selectedUserIds, user._id]);
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleUserRemove = (userId) => {
    setSelectedUserIds(selectedUserIds.filter(id => id !== userId));
    setSelectedUsers(selectedUsers.filter(user => user._id !== userId));
  };

  const assignModulesToUsers = async () => {
    const categoryId = query.get("categoryId");
    const userId =  selectedUserIds

    try {
      await moduleActions.assignCategory(categoryId, userId)
      .then(() => {
        setSelectedUserIds([]);
        setSelectedUsers([]);
        Swal.fire({
          allowOutsideClick: false,
          title: 'Success',
          text: 'Assigned user Successfully',
          icon: 'success',
          confirmButtonText: "Ok"
        })
      });
    } catch (error) {
      console.error("Error updating group module data:", error);
      Swal.fire({
        allowOutsideClick: false,
        title: 'Failed to assign',
        text: 'Please assign',
        icon: 'warning',
        confirmButtonText: "Ok"
      })
    }

    // try {
    //   const response = await axios.post('/assignUserModules', requestBody);
    //   alert(response.data.message);
    //   // Clear selected users after successful assignment
    //   setSelectedUserIds([]);
    //   setSelectedUsers([]);
    // } catch (error) {
    //   console.error("Error assigning modules:", error);
    //   alert("Error assigning modules. Please try again.");
    // }
  };

  useEffect(() => {
    // const fetchAllUsers  = async () => {
    //   try {
    //     const response = await moduleActions.getAllUser();
    //     console.log("fetch user response", response.data.map(item => item.userCategories));
    //     // const userNames = response.data; 
    //     // console.log("username", userNames);
    //     setAllUsers(response.data);
    //   } catch (error) {
    //     console.log("error while fetching all users");
    //   }
    // }

    const getAssignedUsers = async () => {
      const categoryId = query.get("categoryId");
      try {
        const response =  await moduleActions.fetchAssignedUser(categoryId);
        console.log("assigned users name", response.data);
        const names = response.data
        setAssignedUserNames(names); 
        console.log("assigned", assignedUserNames)
      } catch (error) {
        console.log("error while fetching assigned users.")
      }
    }

    // fetchAllUsers();
    getAssignedUsers();
  }, []);

  // const handleAssignSelection = (id) => {
  //   console.log("hamdle selection hit", id)
  //   setAssignedUserNames(prev => {
  //     if (prev.includes(id)) {
  //       return prev.filter(u => u !== id);
  //     }
  //     return [...prev, id]; 
  //   });

  //   console.log("assign data update", assignedUserNames.map(item => item.id))
  // };

const handleAssignSelection = (selectedUser) => {
    setSelectedUsers(prev => {
        const isAssigned = prev.some(u => u._id === selectedUser._id);
        if (isAssigned) {
            console.log("Removing user:", selectedUsers);
            return prev.filter(u => u._id !== selectedUser._id);
        } else {
            console.log("Adding user:", selectedUsers);
            return [...prev, selectedUser];
        }
    });
};


  const clearAllAssign = () => {
    setAssignedUserNames([]);
  };


  // const filteredUsers = allUsers.filter(user => 
  //   user.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const removeModule = (moduleId) => {
    setGroupModuleData((prevModules) => {
      const updatedModules = prevModules.filter(module => module._id !== moduleId);
      console.log("Updated data after removal:", updatedModules);
      return updatedModules;
    });
  };
  


  const handleUpdate = async () => {
    console.log("update clicked")
    var currentModuleData;
    if (auth.user.role === "SuperAdmin") {
      currentModuleData = groupModuleData;
    } 
    currentModuleData = groupModuleData;
    const moduleIds = [...new Set(currentModuleData.map(module => module._id))];
    const categoryId = query.get("categoryId"); 
    const assignUser = selectedUsers.map(item => item._id)
    var departmentId = auth.user.departmentId[0];
    console.log("user role", auth.user.role);
    if(auth.user.role === "SuperAdmin") {
      console.log("super admin")
      departmentId = 0;
    }
  
    try {
      await moduleActions.updateGroupModules(categoryId, moduleIds, groupDetail.name, groupDetail.description, assignUser, departmentId)
      .then(() => {
        Swal.fire({
          allowOutsideClick: false,
          title: 'Success',
          text: 'Group Updated Successfully',
          icon: 'success',
          confirmButtonText: "Ok"
        })
      });
    } catch (error) {
      console.error("Error updating group module data:", error);
      Swal.fire({
        allowOutsideClick: false,
        title: 'Failed to update group',
        text: 'Please enter all fields',
        icon: 'warning',
        confirmButtonText: "Ok"
      })
    }
  };
  // const handleUpdate = async () => {
  //   console.log("update clicked");
    
  //   // Directly access the current module data to avoid stale closures
  //   const currentModuleData = groupModuleData; // Get the latest state
  //   const moduleIds = [...new Set(currentModuleData.map(module => module._id))]; // Get unique IDs
  //   const categoryId = query.get("categoryId"); // Get the category ID from the query
  
  //   try {
  //     const updatedData = await moduleActions.updateGroupModules(categoryId, { moduleIds });
  //     console.log("Updated group module data:", updatedData);
  //     // Optionally update state or show a success message
  //   } catch (error) {
  //     console.error("Error updating group module data:", error);
  //     // Optionally handle error (e.g., show an error message)
  //   }
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownAssignRef.current && !dropdownAssignRef.current.contains(event.target)) {
        setIsAssignOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleAssignDropdown = () => setIsAssignOpen(!isAssignOpen);
  const handleModuleSelection = (selectedModule) => {
    setGroupModuleData(prevModules => {
      const exists = prevModules.some(module => module._id === selectedModule._id);
      if (exists) {
        return prevModules.filter(module => module._id !== selectedModule._id);
      } else {
        return [...prevModules, selectedModule];
      }
    });
  };


  const handleManagerModuleSelection = (selectedModule) => {
    setGroupModuleData(prevModules => {
      const exists = prevModules.some(module => module._id === selectedModule._id);
      if (exists) {
        return prevModules.filter(module => module._id !== selectedModule._id);
      } else {
        return [...prevModules, selectedModule];
      }
    });
  };



  
  const styles = {
    container: {
      maxWidth: '400px',
      margin: '0 auto',
    },
    customDropdown: {
      position: 'relative',
      width: '100%',
    },
    dropdownHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    dropdownList: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderTop: 'none',
      maxHeight: '200px',
      overflowY: 'auto',
      zIndex: 1000,
      gap: '10px'
    },
    dropdownItem: {
      padding: '8px 10px',
      gap: '10px'
    },
    checkbox: {
    marginLeft: '10px',
    marginTop: '3px'
    },
    clearAllButton: {
      position: 'absolute',
      right: '-30px',
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    selectedModules: {
      marginTop: '20px',
    },
    selectedModuleItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '5px',
      padding: '5px',
      backgroundColor: '#f0f0f0',
      borderRadius: '4px',
    },
    removeButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      color: '#888',
    },
  };
  

  return (
    <div className="overview-cont">
      <div className="row">
        <div className="col-xl-12 col-lg-12 overview-left-area your-module">
          <MobileNavBar />
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="right_hading d-none d-sm-inline">Modify Category</h2>

            <div className="mt-3">
              <a href="group" className="adcm-btn Cancel ms-2">
                {" "}
                Cancel
              </a>
              <button onClick={handleUpdate} className="adcm-btn Update ms-2">
                {" "}
                <span><FaPlus /></span>
                Update
              </button>
            </div>
          </div>
          <h2 className="right_hading d-sm-none d-block">Modify Category</h2>

          <div className="row">
            <div className="col-lg-12">
              <div className="company-main add-module">
                <div className="company-fillout">
                  <h3 className="fill-heaing">
                  Category Information (Category Id - {groupDetail?._id})
                  </h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Category Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Module name"
                          autocomplete="off"
                          value={groupDetail?.name}
                          onChange={(e) => setGroupDetail({ ...groupDetail, name: e.target.value })}
                          name="name"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Category Description</label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="This group description is...."
                          value={groupDetail?.description}
                          onChange={(e) => setGroupDetail({ ...groupDetail, description: e.target.value })}
                          name="description"
                        ></textarea>
                      </div>
                    </div>

                    {userInfo === 'SuperAdmin' ? (
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Select Module</label>
                            <div
                              className="dropdown-container"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                                <div style={styles.customDropdown} ref={dropdownRef}>
                                  <div style={styles.dropdownHeader} onClick={toggleDropdown}>
                                    <span>{groupModuleData.length ? `${groupModuleData.length} module selected` : 'Select Module'}</span>
                                    <FaChevronDown />
                                  </div>
                                  {isOpen && (
                                    <div style={styles.dropdownList}>
                                      {fetchModuleData.map((module) => (
                                        <div key={module._id} style={styles.dropdownItem}>
                                          <label>
                                            <input
                                              type="checkbox"
                                              style={styles.checkbox}
                                              checked={groupModuleData.some(m => m._id === module._id)}
                                              onChange={() => handleModuleSelection(module)}
                                            />
                                            {module.moduleName}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  <button onClick={clearAllAssign} style={styles.clearAllButton}>
                                    <FaTrash color="red" />
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    ) : (
                      <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Select Module</label>
                            <div
                              className="dropdown-container"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                                <div style={styles.customDropdown} ref={dropdownRef}>
                                  <div style={styles.dropdownHeader} onClick={toggleDropdown}>
                                    <span>Select Module</span>
                                    <FaChevronDown />
                                  </div>
                                  {isOpen && (
                                    <div style={styles.dropdownList}>
                                      {moduleList.map((module) => (
                                        <div key={module._id} style={styles.dropdownItem}>
                                          <label>
                                            <input
                                              type="checkbox"
                                              style={styles.checkbox}
                                              checked={groupModuleData.some(m => m._id === module._id)}
                                              onChange={() => handleManagerModuleSelection(module)}
                                            />
                                            {module.moduleName}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  <button onClick={clearAllAssign} style={styles.clearAllButton}>
                                    <FaTrash color="red" />
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}


                    {userInfo === 'SuperAdmin' ? (
                    <div className="flex2fieldForm">
                      <div className="field-group">
                        {groupModuleData && groupModuleData?.length > 0 && (
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              <div
                                className="radioGroup"
                              >
                                {groupModuleData.map((item, index) => {
                                  if (item.moduleName) {
                                    return (
                                      <div
                                        key={`${item}-${index}`}
                                        className="mr-4 moduleCompetencyCard"
                                      >
                                        <label htmlFor="Module">
                                          {item.moduleName}
                                        </label>
                                        <button type="button" onClick={() => removeModule(item._id)}>
                                          <RxCross2 size={20} />
                                        </button>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ): ( 
                    <div className="flex2fieldForm">
                      <div className="field-group">
                        {groupModuleData && groupModuleData?.length > 0 && (
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              <div
                                className="radioGroup"
                              >
                                {groupModuleData.map((item, index) => {
                                  if (item.moduleName) {
                                    return (
                                      <div
                                        key={`${item}-${index}`}
                                        className="mr-4 moduleCompetencyCard"
                                      >
                                        <label htmlFor="Module">
                                          {item.moduleName}
                                        </label>
                                        <button type="button" onClick={() => removeModule(item._id)}>
                                          <RxCross2 size={20} />
                                        </button>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    )}
                      
                    {/* <div className="col-md-12 mt-4">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Assign Users</label>
                          <div className="dropdown-container">
                            <div onClick={handleDropdownToggle} style={{ cursor: 'pointer' }}>
                              {searchTerm || "Search users..."}
                            </div>
                            {isDropdownOpen && (
                              <div style={{ border: '1px solid #ccc', maxHeight: '150px', overflowY: 'auto' }}>
                                <input 
                                  type="text" 
                                  placeholder="Search..." 
                                  value={searchTerm} 
                                  onChange={(e) => setSearchTerm(e.target.value)} 
                                  style={{ width: '100%', padding: '8px' }}
                                />
                                {filteredUsers.map((user, index) => (
                                  <div 
                                    key={index} 
                                    onClick={() => handleSelectUser(user)} 
                                    style={{ padding: '8px', cursor: 'pointer', background: '#fff', borderBottom: '1px solid #ccc' }}
                                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#f0f0f0'} // Hover effect
                                    onMouseLeave={e => e.currentTarget.style.backgroundColor = '#fff'} // Remove hover effect
                                  >
                                    {user}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="flex2fieldForm mb-6">
                      <div className="field-group">
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              <div
                                className="radioGroup"
                                style={{ marginTop: "3em" }}
                              >
                                  {assignedUserNames.map((item, index) => (
                                    <div key={item.id} className="mr-4 moduleCompetencyCard">
                                    <label htmlFor="Module">
                                          {item.name}
                                        </label>
                                        <button type="button" onClick={() => removeModule(item._id)}>
                                          <RxCross2 size={20} />
                                        </button>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    </div> */}
                    {/* <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group"style={{ marginTop: '30px'}}>
                            <label>Assign Users</label>
                            <div
                              className="dropdown-container"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                                <div style={styles.customDropdown} ref={dropdownAssignRef}>
                                  <div style={styles.dropdownHeader} onClick={toggleAssignDropdown}>
                                    <span>{assignedUserNames.length ? `${assignedUserNames.length} user assigned` : 'Assign users'}</span>
                                    <FaChevronDown />
                                  </div>
                                  {isAssignOpen && (
                                  <div style={styles.dropdownList}>
                                    {allUsers.map((item, index) => {
                                      const assignedUser = assignedUserNames.find(m => m._id === item._id);
                                      const isChecked = assignedUser ? true : false;

                                      return (
                                        <div key={item._id} style={styles.dropdownItem}>
                                          <label>
                                            <input
                                              type="checkbox"
                                              style={styles.checkbox}
                                              checked={isChecked}
                                              onChange={() => handleAssignSelection(item)} // Or handle based on _id
                                            />
                                            {item.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}

                                  <button onClick={clearAllAssign} style={styles.clearAllButton}>
                                    <FaTrash color="red" />
                                  </button>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group" style={{ marginTop: '30px' }}>
                                  <label>Assign Users</label>
                                  <div className="" style={{ 
                                    display: "flex", 
                                    alignItems: "center", 
                                    width: 'auto', 
                                    gap: '20px', 
                                    padding: '10px',
                                    backgroundColor: '#fff', 
                                    borderRadius: '4px'
                                  }}>
                                    <div style={{
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                      overflow: 'visible',
                                      position: 'relative',
                                      flexGrow: 1
                                    }}>
                                      <input
                                        type="text"
                                        placeholder="Search users..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        style={{ 
                                          width: '100%', 
                                          padding: '10px',
                                          border: 'none',
                                          outline: 'none'
                                        }}
                                      />
                                      {searchTerm && allUsers.length > 0 && (
                                        <div style={{
                                          position: 'absolute',
                                          top: '100%',
                                          left: 0,
                                          right: 0,
                                          backgroundColor: '#fff',
                                          border: '1px solid #ccc',
                                          borderTop: 'none',
                                          maxHeight: '200px',
                                          overflowY: 'auto',
                                          zIndex: 1000,
                                          boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                                        }}>
                                          {allUsers.map((item) => (
                                            <div 
                                              key={item._id}  
                                              onClick={() => handleUserSelect(item)}
                                              style={{
                                                padding: '10px',
                                                cursor: 'pointer',
                                                hover: {
                                                  backgroundColor: '#f0f0f0'
                                                }
                                              }}
                                            >
                                              {item.name}
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                      {/* <div>
                                        {selectedUsers.map(user => (
                                          <div key={user._id} style={{ display: 'inline-block', margin: '5px', padding: '5px', border: '1px solid #000' }}>
                                            {user.name}
                                            <button onClick={() => handleUserRemove(user._id)} style={{ marginLeft: '5px' }}>Remove</button>
                                          </div>
                                        ))}
                                      </div> */}
                                    </div>
                                    <button 
                                      type="button" 
                                      className="btn btn-primary"
                                      onClick={assignModulesToUsers} disabled={selectedUserIds.length === 0}
                                    >
                                      Assign
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                              {/* <div className="flex2fieldForm">
                                <div className="field-group">
                                  {assignedUserNames && assignedUserNames?.length > 0 && (
                                    <div className="flex2fieldForm">
                                      <div className="field-group">
                                        <div
                                          className="radioGroup"
                                        >
                                          {assignedUserNames.map((item, index) => {
                                            if (item.name) {
                                              return (
                                                <div
                                                  key={`${item.userId}-${index}`}
                                                  className="mr-4 moduleCompetencyCard"
                                                >
                                                  <label htmlFor="Module">
                                                    {item.name}
                                                  </label>
                                                  <button type="button" onClick={() => removeModule(item._id)}>
                                                    <RxCross2 size={20} />
                                                  </button>
                                                </div>
                                              );
                                            }
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div> */}
                              <div className="flex2fieldForm">
                                <div className="field-group">
                                  {selectedUsers && selectedUsers?.length > 0 && (
                                    <div className="flex2fieldForm">
                                      <div className="field-group">
                                        <div
                                          className="radioGroup"
                                        >
                                          {selectedUsers.map((item, index) => {
                                            if (item.name) {
                                              return (
                                                <div
                                                  key={`${item}-${index}`}
                                                  className="mr-4 moduleCompetencyCard"
                                                >
                                                  <label htmlFor="Module">
                                                    {item.name}
                                                  </label>
                                                  <button type="button" onClick={() => handleUserRemove(item._id)}>
                                                    <RxCross2 size={20} />
                                                  </button>
                                                </div>
                                              );
                                            }
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModifyGrouppage = reduxForm({
  form: "modifygroup",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ModifyGrouppage);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    module: state.modules.selectedModule,
    modifymodule: state.form.modifymodule
      ? state.form.modifymodule.values
      : null,
  };
};

export default connect(mapStateToProps)(ModifyGrouppage);
