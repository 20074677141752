import React from "react";
import VideoModal from "../../../common/api/VideoModel";
import ArrowBack from "../../../images/arrow_back.png";
import confinedSpace from "../../../images/confinedSpace.jpg";
import { Link, useHistory } from "react-router-dom";
import MobileNavBar from "../../../components/mobileNavBar";

const DownloadModule = () => {
  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-9 col-lg-8 overview-left-area">
            <MobileNavBar />

            <h2 className="right_hading">
              <Link to="/module-library">
                <img src={ArrowBack} alt="Arrow Back" />
              </Link>{" "}
            </h2>

            <div className="row">
              <div className="col-lg-12">
                <div className="sec2 ">
                  <div className="abt-top-pic">
                    <span className="green-box"></span>
                    {/* <img src={AboutBg} alt="" /> */}
                    <img src={confinedSpace} alt="confined Space Safety" />
                  </div>
                  <div className="devider2"></div>
                  <div className="cms-content">
                    <h5>DESCRIPTION</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nullam gravida pulvinar purus, id consequat justo. Sed
                      convallis rhoncus orci ac imperdiet. Sed ut dignissim
                      felis, in suscipit ipsum. Nullam consectetur laoreet
                      justo. Phasellus imperdiet, tortor ut consequat ultricies,
                      turpis purus vehicula purus, vitae interdum purus augue
                      sed lacus. Vivamus eu ex dictum libero accumsan sagittis.
                      Nulla facilisi. Proin interdum felis non leo consectetur
                      viverra. In enim dui, maximus vitae mi in, convallis
                      facilisis odio. In hendrerit aliquam tellus, a elementum
                      massa. Praesent metus risus, dignissim et tellus non,
                      porttitor lacinia diam.
                    </p>

                    <p>
                      In ultricies neque vitae dui volutpat, eu congue urna
                      ultrices. In hac habitasse platea dictumst. Vivamus est
                      mauris, dictum vel elementum in, mattis eu nisi. Integer
                      fringilla velit et leo rutrum, vel pulvinar felis
                      tristique. Fusce blandit aliquet dui, vitae malesuada orci
                      varius quis. Aliquam commodo rhoncus nibh sit amet
                      posuere. Donec porta ut arcu et lobortis. Fusce
                      pellentesque tincidunt nulla, id aliquam leo dictum sit
                      amet. Aliquam quam justo, tempus non mattis ac,
                      sollicitudin nec augue. Mauris condimentum arcu vitae
                      risus feugiat tempus. Nullam tincidunt, ante vitae
                      suscipit varius, risus ligula cursus nibh, ut tristique
                      orci turpis ac mi.
                    </p>

                    <p>
                      Etiam ac cursus nulla. Phasellus egestas aliquam orci
                      vitae tempus. In molestie placerat nisi ac venenatis.
                      Maecenas rhoncus non eros sed sagittis. Praesent dapibus
                      eleifend ligula id ornare. Mauris sed elit non arcu
                      egestas lacinia. Ut sit amet elementum neque. Cras in elit
                      vitae lorem condimentum congue id nec diam.
                    </p>

                    <p>
                      Quisque malesuada ornare nisl id varius. Fusce lobortis
                      ipsum dictum ligula commodo, eget finibus nulla volutpat.
                      Aenean a varius turpis. Donec accumsan interdum diam, nec
                      semper velit condimentum sit amet. Nulla ultrices
                      malesuada lectus. Sed porta risus eget velit placerat, non
                      volutpat est tempor. Nunc ut urna eget felis rhoncus
                      blandit non ac lorem. Nulla facilisi. Cras tempor posuere
                      mauris ac maximus.
                    </p>

                    <p>
                      Etiam elementum nisi et bibendum hendrerit. Pellentesque
                      quis imperdiet tortor, ut semper mauris. Donec lacinia sed
                      neque in suscipit. Pellentesque commodo ut ante sit amet
                      efficitur. Morbi finibus ante ut feugiat aliquam. Proin
                      non gravida nulla, et venenatis nisl. Mauris vestibulum
                      lorem eget nibh bibendum, non sollicitudin lectus
                      venenatis.
                    </p>
                    <div className="seemore-block">
                      <a href="#">
                        Show More{" "}
                        <img src="assets/images/caret-down.svg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 right-side-area">
            <VideoModal data={"Check"} />

            <a href="#" className="dl_module">
              {" "}
              Download Module{" "}
            </a>

            <a href="#" className="dl_guide">
              {" "}
              Download Guide{" "}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadModule;
