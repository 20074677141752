import React, { useState, useEffect } from "react";
import { reduxForm, Field, initialize, destroy } from "redux-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as moduleActions from "../../../action/moduleActions";
import Ellipse9 from "../../../images/Ellipse9.png";
import linkBg from "../../../images/link.svg";
import { bindActionCreators } from "redux";
import {
  renderInputField,
  renderTextAreaField,
  renderRadioButtonField,
  renderImageField,
  renderCheckBoxField,
  RenderAwsFileField,
  renderSelect,
} from "../../../common/InputFields";
import * as userActions from "../../../action/userActions";
import { get } from "lodash";
import plus from "../../../images/plus.svg";
import { useHistory } from "react-router-dom";
import { addModuleValidator } from "./ModuleValidators";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import { event } from "jquery";
import MobileNavBar from "../../../components/mobileNavBar";

let AddModule = (props) => {
  const { handleSubmit, addmodule = {}, token, auth } = props;

  const history = useHistory();
  const [competencies, setCompetencies] = useState([]);
  const [errorBorderCss, setErrorBorderCss] = useState(null);
  const [statusErrCss, setStatusErrCss] = useState(null);
  const [platformsErrCss, setPlatformsErrCss] = useState(null);
  const [profilePic, setProfilePic] = useState(Ellipse9);
  const [HomePage, setHomepage] = useState(Background);
  const [productCode, setProductCode] = useState([]);

  const [moduleData, setModuleData] = useState({
    moduleName: "",
    industry: "",
    moduleType: "",
    moduleDescription: "",
    moduleGuide: {},
    moduleDemoLink: "",
    competencies: [],
    platforms: [],
    bannerImage: {},
    product_code: "",
  });

  const onUploadHome = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      let updatedModuleData = Object.assign({}, moduleData);
      updatedModuleData["bannerImage"] = res;
      setModuleData(updatedModuleData);
    });
  };

  const updateModuleGuide = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      let updatedModuleData = Object.assign({}, moduleData);
      updatedModuleData["moduleGuide"] = res;
      setModuleData(updatedModuleData);
    });
  };

  const onUploadSigned = (event) => {
    userActions
      .uploadSignedUrl(token, get(event, "target.files[0]"))
      .then((res) => {
        setProfilePic(res);
      });
  };
  const addModuleData = (values = {}) => {
    let competencyArr = [];
    let productCodeArr = [];
    for (let i = 0; i < productCode.length; i++) {
      competencyArr.push(productCode[i].competencyName);
      productCodeArr.push(productCode[i].productCode);
    }

    moduleData.competencies = competencyArr;
    moduleData.competencyProductCodes = productCodeArr;

    moduleActions
      .addModule(props.auth.token, moduleData)
      .then(() => {
        Swal.fire({
          allowOutsideClick: false,
          title: "Success",
          text: "Module  Added Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(function () {
          history.push("/module");
        });
        history.push("/module");
      })
      .catch((error) =>
        Swal.fire({
          allowOutsideClick: false,
          title: "Failed to create module.",
          text: "Please enter all fields!",
          icon: "warning",
          confirmButtonText: "Ok",
        })
      );
  };

  const onCompetencyClick = (selectedCompetency) => {
    const newCompetencies = competencies.filter(
      (item) => item !== selectedCompetency
    );
    props.change("competency", newCompetencies);
    setCompetencies([...newCompetencies]);
  };

  const onModuleType = (error) => {
    setErrorBorderCss(error ? "error-border" : null);
  };
  const onStatusError = (error) => {
    setStatusErrCss(error ? "error-border" : null);
  };
  const onPlatformsError = (error) => {
    setPlatformsErrCss(error ? "error-border" : null);
  };

  const resetFormAndExit = () => {
    props.dispatch(destroy("addmodule"));
    history.push("/module");
  };

  var modifyBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${get(HomePage, "Location") || Background})`,
  };

  const updateModuleData = (e) => {
    let updatedModuleData = Object.assign({}, moduleData);
    updatedModuleData[e.target.name] = e.target.value;
    setModuleData(updatedModuleData);
  };

  const updateModuleType = (e) => {
    let updatedModuleData = Object.assign({}, moduleData);
    updatedModuleData["moduleType"] = e.target.value;
    setModuleData(updatedModuleData);
  };

  const updateModulePlatform = (e) => {
    let updatedModuleData = Object.assign({}, moduleData);
    if (!updatedModuleData.platforms.includes(e.target.value)) {
      updatedModuleData.platforms.push(e.target.value);
    } else {
      for (let i = 0; i < updatedModuleData.platforms.length; i++) {
        if (updatedModuleData.platforms[i] === e.target.value) {
          updatedModuleData.platforms.splice(i, 1);
        }
      }
    }
    setModuleData(updatedModuleData);
  };

  const deleteCompetency = (e, index) => {
    let updatedModuleData = Object.assign({}, moduleData);
    updatedModuleData["competencies"].splice(index, 1);
    setModuleData(updatedModuleData);

    let updatedProductCode = [...productCode];
    for (let i = 0; i < updatedProductCode.length; i++) {
      if (updatedProductCode[i].index == index) {
        updatedProductCode.splice(i, 1);
      }
    }
    setProductCode(updatedProductCode);
  };

  const addCompetency = () => {
    if (
      addmodule.competency &&
      addmodule.competency.trim() &&
      !competencies.includes(addmodule.competency)
    ) {
      const newCompetencies = [...competencies, addmodule.competency];
      props.change("competency", "");

      let updatedModuleData = Object.assign({}, moduleData);
      updatedModuleData["competencies"].push(addmodule.competency);
      setModuleData(updatedModuleData);

      let updatedProductCode = [...productCode];
      updatedProductCode.push({
        index: updatedProductCode.length,
        competencyName: "",
        productCode: "",
      });
      setProductCode(updatedProductCode);

      // setCompetencies([...newCompetencies]);
    } else if (competencies.includes(addmodule.competency)) {
      toast("Duplicate competency");
    }
  };

  return (
    <>
      <div class="overview-cont">
        <div class="row">
          <div class="col-xl-12 col-lg-12 overview-left-area your-module">
            <form onSubmit={handleSubmit(addModuleData)}>
              <MobileNavBar />
              <div class="d-flex align-items-center justify-content-between">
                <h2 class="right_hading d-none d-sm-inline">
                  Add a New Module
                </h2>

                <div class="mt-3">
                  <a href="module" class="adcm-btn Cancel ms-2">
                    Return
                  </a>
                  <a>
                    <button onClick={addModuleData} className="adcm-btn ms-1">
                      <img src={plus} style={{ width: "10px" }} />
                      Add
                    </button>
                  </a>
                </div>
              </div>
              <h2 class="right_hading d-sm-none d-block">Add a New Module</h2>

              <div class="row">
                <div class="col-lg-12">
                  <div class="company-main add-module">
                    <div class="company-profile" style={modifyBackgroundStyle}>
                      <label className="profile-changer" for="nhg">
                        <img className="me-1" src={linkBg} alt="" /> Change
                        Image
                      </label>
                      <input
                        onChange={onUploadHome}
                        accept={".jpg, .jpeg, .png"}
                        type="file"
                        style={{
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                        }}
                        id="nhg"
                      ></input>
                    </div>
                    <div class="company-fillout">
                      <h3 class="fill-heaing">Module Information</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Module Name</label>
                            <input
                              name="moduleName"
                              type="text"
                              placeholder="Module name"
                              className="form-control"
                              onChange={(e) => updateModuleData(e)}
                            />
                            {/* <Field
                              name="moduleName"
                              component={renderInputField}
                              type="text"
                              label="Module name"
                            /> */}
                          </div>
                          <div class="form-group mb-5">
                            <div class="custom-file">
                              <label>Module Guide</label>
                              <input
                                name="moduleGuide"
                                type="file"
                                placeholder="Module Guide"
                                className="form-control"
                                onChange={(e) => updateModuleGuide(e)}
                              />
                              {/* <Field
                                name="moduleGuide"
                                token={auth ? token : ""}
                                component={RenderAwsFileField}
                                uploadLabel="Browse"
                                isPlaceHolderImage={false}
                                className="mr"
                                fileType=".pdf"
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Module Demo Link</label>
                            <input
                              name="moduleDemoLink"
                              type="text"
                              placeholder="Module demo link"
                              className="form-control"
                              onChange={(e) => updateModuleData(e)}
                            />
                            {/* <Field
                              name="moduleDemoLink"
                              component={renderInputField}
                              type="text"
                              label="Module demo link"
                            /> */}
                          </div>
                          {/* <div class="form-group">
                            <label>Product Code</label>
                            <input
                              name="product_code"
                              type="text"
                              placeholder="Product Code"
                              className="form-control"
                              onChange={(e) => updateModuleData(e)}
                            />
                          </div> */}
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Module Description</label>
                            <textarea
                              className="form-control"
                              name="moduleDescription"
                              placeholder="Module Description"
                              onChange={(e) => updateModuleData(e)}
                            ></textarea>
                            {/* <Field
                              name="moduleDescription"
                              component={renderTextAreaField}
                              type="textarea"
                              label="This module is...."
                            /> */}
                          </div>
                        </div>

                        <div class="col-md-12">
                          <h3 class="fill-heaing">Additional Information</h3>
                          <div class="row">
                            <div class="col-md-6">
                              <div className="form-group">
                                <label>Industry</label>
                                <input
                                  name="industry"
                                  type="text"
                                  placeholder="Industry"
                                  className="form-control"
                                  onChange={(e) => updateModuleData(e)}
                                />
                                {/* <Field
                                  name="industry"
                                  component={renderInputField}
                                  type="text"
                                  label="Industry"
                                /> */}
                              </div>
                              <div className="form-group">
                                <label>Module Type</label>
                                <div
                                  className={`d-flex flex-column ${errorBorderCss}`}
                                >
                                  {/* <Field
                                    name="moduleType"
                                    component={renderSelect}
                                    onError={onModuleType}
                                  > */}
                                  <select
                                    className="form-control"
                                    onChange={(e) => updateModuleType(e)}
                                  >
                                    <option>Please Select</option>
                                    <option value="Unity">Unity</option>
                                    <option value="Unreal Engine">
                                      Unreal Engine
                                    </option>
                                    <option value="Other">Other</option>
                                  </select>
                                  {/* </Field> */}
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6 ">
                              <div className="form-group">
                                <label>Modules Platform</label>
                                <div className="d-flex radioGroup verticleCardradio flex-column">
                                  <div className="radioGroupItem mr-4">
                                    <label htmlFor="UserManager">Windows</label>
                                    <input
                                      component={renderCheckBoxField}
                                      name="Windows"
                                      value="Windows"
                                      type="checkbox"
                                      className="checkCss"
                                      onClick={(e) => updateModulePlatform(e)}
                                    />
                                  </div>
                                  <div className="radioGroupItem">
                                    <label htmlFor="UserAdmin">Oculus</label>
                                    <input
                                      component={renderCheckBoxField}
                                      name="Oculus"
                                      value="Oculus"
                                      type="checkbox"
                                      className="checkCss"
                                      onClick={(e) => updateModulePlatform(e)}
                                    />
                                  </div>
                                  <div className="radioGroupItem">
                                    <label htmlFor="UserEmployee">HTC</label>
                                    <input
                                      component={renderCheckBoxField}
                                      name="HTC"
                                      value="HTC"
                                      type="checkbox"
                                      className="checkCss"
                                      onClick={(e) => updateModulePlatform(e)}
                                    />
                                  </div>
                                  <div className="radioGroupItem">
                                    <label htmlFor="UserEmployee">PICO</label>
                                    <input
                                      component={renderCheckBoxField}
                                      name="PICO"
                                      value="PICO"
                                      type="checkbox"
                                      className="checkCss"
                                      onClick={(e) => updateModulePlatform(e)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Competencies</label>
                                <div className="InputTextInBttn">
                                  <div className="form-group">
                                    <Field
                                      name="competency"
                                      component={renderInputField}
                                      type="text"
                                      label="competencies..."
                                      validate={[
                                        () =>
                                          competencies &&
                                          competencies.length > 0
                                            ? undefined
                                            : "Required",
                                      ]}
                                    />
                                  </div>
                                  <div className="form-group inputINbttn">
                                    <button
                                      type="button"
                                      onClick={addCompetency}
                                      className="btn middiumBttn themesecondarybackground"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                                {moduleData.competencies.length > 0 && (
                                  <div className="compitencyResult">
                                    {moduleData.competencies.map(
                                      (item, index) => (
                                        <div
                                          key={`${item}-${index}`}
                                          className="moduleCompetencyCard"
                                          onClick={() =>
                                            onCompetencyClick(item)
                                          }
                                        >
                                          <label htmlFor="UserManager">
                                            {item}
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Product Code"
                                            onChange={(e) => {
                                              let updatedProductCode = [
                                                ...productCode,
                                              ];
                                              for (
                                                let i = 0;
                                                i < updatedProductCode.length;
                                                i++
                                              ) {
                                                if (
                                                  updatedProductCode[i].index ==
                                                  index
                                                ) {
                                                  updatedProductCode[
                                                    i
                                                  ].productCode =
                                                    e.target.value;
                                                  updatedProductCode[
                                                    i
                                                  ].competencyName =
                                                    moduleData.competencies[
                                                      index
                                                    ];
                                                }
                                              }
                                              setProductCode(
                                                updatedProductCode
                                              );
                                            }}
                                          />
                                          <button
                                            className="competencyDelete"
                                            onClick={(e) => {
                                              deleteCompetency(e, index);
                                            }}
                                          >
                                            X
                                          </button>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

AddModule = reduxForm({
  form: "addmodule",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: addModuleValidator,
})(AddModule);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    addmodule: state.form.addmodule && state.form.addmodule.values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddModule);
