import initialState from "./initialState";

export default function moduleReducer(state = initialState.modules, action) {
  switch (action.type) {
    case "LOAD_MODULES":
      return { ...state, modulesList: action.modules };
    case "MODULE_SELECTED":
      return { ...state, selectedModule: action.selectedModule };

    case "LOAD_USER_MODULES":
      return { ...state, userModulesList: action.modules };
    case "MODULE_VERSION_SELECTED":
      return { ...state, selectedModuleVersion: action.selectedModuleVersion };

    case "MODULE_COMPANY_SELECTED":
      return { ...state, selectedModuleCompany: action.selectedModuleCompany };
    case "LOAD_MODULES_BY_COMPANY":
      return { ...state, allModulesByCompany: action.modules };

    case "LOAD_MODULE_REPORT":
      return {
        ...state,
        moduleReport: action.payload.data,
      };

    case "LOAD_MODULE_TRAINING_REPORT":
      return {
        ...state,
        moduleTrainingReport: action.payload.data,
      };

    case "LOAD_DEBRIEFING_REPORT":
      return {
        ...state,
        deberiefReport: action.payload.data,
      };

    case "LOAD_DEBRIEFING_TRAINING_REPORT":
      return {
        ...state,
        deberiefReport: action.payload.data,
      };

    case "LOAD_GLOBALDATA_REPORT":
      return {
        ...state,
        globaldataReport: action.payload.data,
      };

    default:
      return state;
  }
}
