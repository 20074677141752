import React, { useState, useEffect, useRef } from "react";
import { reduxForm, Field, initialize, destroy } from "redux-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as moduleActions from "../../../action/moduleActions";
import Ellipse9 from "../../../images/Ellipse9.png";
import linkBg from "../../../images/link.svg";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";
import * as userActions from "../../../action/userActions";
import { get } from "lodash";
import plus from "../../../images/plus.svg";
import { useHistory } from "react-router-dom";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import MobileNavBar from "../../../components/mobileNavBar";
import { addModuleValidator } from "../Modules/ModuleValidators";
import { RxCross2 } from 'react-icons/rx';
import { FaTrash, FaChevronDown } from 'react-icons/fa';
import { debounce } from 'lodash';
import { loadState } from "../../../store/LocalStorage";




function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let AddGroup = (props) => {
  const { handleSubmit, addmodule = {}, token, auth } = props;

  const history = useHistory();
  const [HomePage, setHomepage] = useState(Background);
  const [productCode, setProductCode] = useState([]);

  const [moduleData, setModuleData] = useState({
    name: "",
    description: "",
    modules: ""
  });
  const [groupDetail, setGroupDetail] = useState({});
  const [groupModuleData, setGroupModuleData] = useState([]);
  const [fetchModuleData, setFetchModuleData] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [assignedUserNames, setAssignedUserNames] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  let [moduleList, setAllModules] = useState([]);
  const [allModuleData, setAllModuleData] = useState([]);

  let query = useQuery();

  const [userInfo, setuserInfo] = useState("");
  const dispatch = useDispatch();
  const { loggedIn, user, tokens, modules } = get(loadState(), "auth") || {};

  let auths = {
    token: tokens || "",
    loggedIn: loggedIn || false,
    modules: modules || [],
    user: {
      ...user,
    },
  };
  useEffect(() => {
    setuserInfo(auths.user.role);
    console.log("user role", auths.user.role)
  }, []);

  useEffect(() => {
    const fetchGroupDetail = async () => {
      try {
        const response = await moduleActions.getGroupDetail(
          query.get("categoryId")
        );
        console.log(
          "Group Detail Data",
          response.data.modules.map((item) => item.moduleName)
        );
        const moduleValue = response.data.modules;
        setGroupModuleData(moduleValue);
        setGroupDetail(response.data);
      } catch (error) {
        console.error("Error fetching group detail:", error);
      }
    };
    fetchGroupDetail();
  }, [query]);


  useEffect(() => {
    // props.moduleActions.getAllModules(auth.token);

    // props.userActions.getAllUsersByCompany(auth.token, auth.user.companyId);
    // loadAllModules();
    userActions.getModules().then((response) => {
      setAllModules(response.data);
      console.log("module data", response.data)
    });
  }, []);

  useEffect(() => {
    const fetchModule = async () => {
      try {
        const response = await moduleActions.getModule();
        console.log("module name data", response.data);
        setFetchModuleData(response.data);
      } catch (error) {
        console.log("error in fetchmodule", error);
      }
    };

    fetchModule();
  }, []);

  const fetchUsers = async (term) => {
    if (!term || !term.trim()) return; // Don't fetch if the term is empty or just whitespace
    console.log("Fetching users for query:", term); // Log the term being searched
    try {
      const response = await moduleActions.getAllUser(term);
      if (Array.isArray(response.data)) {
        setAllUsers(response.data);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.log("Error while fetching users", error); // Log the error object
    }
  };

  const debouncedFetchUsers = useRef(debounce((term) => {
    fetchUsers(term);
  }, 300)).current;

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedFetchUsers(value)
  };

  const handleUserSelect = (user) => {
    if (!selectedUserIds.includes(user._id)) {
      setSelectedUserIds([...selectedUserIds, user._id]);
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleUserRemove = (userId) => {
    setSelectedUserIds(selectedUserIds.filter(id => id !== userId));
    setSelectedUsers(selectedUsers.filter(user => user._id !== userId));
  };

  const assignModulesToUsers = async () => {
    const categoryId = query.get("categoryId");
    const userId =  selectedUserIds

    try {
      await moduleActions.assignCategory(categoryId, userId)
      .then(() => {
        setSelectedUserIds([]);
        setSelectedUsers([]);
        Swal.fire({
          allowOutsideClick: false,
          title: 'Success',
          text: 'Assigned user Successfully',
          icon: 'success',
          confirmButtonText: "Ok"
        })
      });
    } catch (error) {
      console.error("Error updating group module data:", error);
      Swal.fire({
        allowOutsideClick: false,
        title: 'Failed to assign',
        text: 'Please assign',
        icon: 'warning',
        confirmButtonText: "Ok"
      })
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  // const handleModuleSelection = (selectedModuleId) => {
  //   const selectedModule = fetchModuleData.find(
  //     (module) => module._id === selectedModuleId
  //   );
  //   if (
  //     selectedModule &&
  //     !groupModuleData.some((module) => module._id === selectedModule._id)
  //   ) {
  //     setGroupModuleData((prevModules) => [...prevModules, selectedModule]);
  //   }
  // };
  const handleModuleSelection = (selectedModule) => {
    setGroupModuleData(prevModules => {
      const exists = prevModules.some(module => module._id === selectedModule._id);
      if (exists) {
        return prevModules.filter(module => module._id !== selectedModule._id);
      } else {
        return [...prevModules, selectedModule];
      }
    });
  };

  const handleManagerModuleSelection = (selectedModule) => {
    setGroupModuleData(prevModules => {
      const exists = prevModules.some(module => module._id === selectedModule._id);
      if (exists) {
        console.log("selected module data 1", selectedModule)
        return prevModules.filter(module => module._id !== selectedModule._id);
      } else {
        console.log("selected module data", selectedModule)
        return [...prevModules, selectedModule];
      }
    });
  };

  const removeModule = (moduleId) => {
    setGroupModuleData(prevModules => prevModules.filter(module => module._id !== moduleId));
  };

  const clearAllModules = () => {
    setGroupModuleData([]); 
  };

  const addGroupData =  async() => {
    const { name, description } = moduleData;
    var departmentId = auth.user.departmentId[0];
    console.log("user role", auth.user.role);
    if(auth.user.role === "SuperAdmin") {
      console.log("super admin")
      departmentId = 0;
    }
    const modules = groupModuleData.map(module => module._id);
    const payload = {
      name,
      description,
      modules,
      departmentId
    }

    moduleActions.addGroup(payload)
    .then(() => {
      Swal.fire({
        allowOutsideClick: false,
        title: 'Success',
        text: 'Group Added Successfully',
        icon: 'success',
        confirmButtonText: "Ok"
      }).then(function() {
        history.push("/group");
      })
      .catch((error) => {
        Swal.fire({
          allowOutsideClick: false,
          title: 'Failed to create group',
          text: 'Please enter all fields',
          icon: 'warning',
          confirmButtonText: "Ok"
        })
      })
    })
  }

  const dropdownStyles = {
    container: {
      position: 'relative',
      width: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    list: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderTop: 'none',
      maxHeight: '200px',
      overflowY: 'auto',
      zIndex: 1000,
    },
    item: {
      padding: '8px 10px',
    },
    clearAllButton: {
      position: 'absolute',
      right: '-30px',
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    checkbox: {
    marginLeft: '10px',
    marginTop: '3px'
    },
  };
  
  return (
    <>
      <div class="overview-cont">
        <div class="row">
          <div class="col-xl-12 col-lg-12 overview-left-area your-module">
            <form onSubmit={handleSubmit(addGroupData)}>
              <MobileNavBar />
              <div class="d-flex align-items-center justify-content-between">
                <h2 class="right_hading d-none d-sm-inline">
                  Add a New Category
                </h2>

                <div class="mt-3">
                  <a href="group" class="adcm-btn Cancel ms-2">
                    Return
                  </a>
                  <a>
                    <button type="submit" className="adcm-btn ms-1">
                      <img src={plus} style={{ width: "10px" }} />
                      Add
                    </button>
                  </a>
                </div>
              </div>
              <h2 class="right_hading d-sm-none d-block">Add a New Category</h2>

              <div class="row">
                <div class="col-lg-12">
                  <div class="company-main add-module">
                    <div class="company-fillout">
                      <h3 class="fill-heaing">Category Information</h3>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Category Name</label>
                            <input
                              name="name"
                              type="text"
                              placeholder="Group name"
                              className="form-control"
                              onChange={(e) => setModuleData({ ...moduleData, name: e.target.value })}
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Category Description</label>
                            <textarea
                              className="form-control"
                              name="description"
                              placeholder="Group Description"
                              onChange={(e) => setModuleData({ ...moduleData, description: e.target.value })}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      {userInfo === 'SuperAdmin' ? (
                      <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Select Module</label>
                            {/* <div
                              className="dropdown-container"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <select
                                class="form-control"
                                value={module._id}
                                onChange={(e) =>
                                  handleModuleSelection(e.target.value)
                                }
                                name="moduleType"
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <option>Please Select Module</option>
                                {fetchModuleData.map((module) => (
                                  <option key={module._id} value={module._id}>
                                    {module.moduleName}
                                  </option>
                                ))}
                              </select>
                              <button 
                              onClick={clearAllModules}
                                style={{
                                  marginLeft: "20px",
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                                aria-label="Clear all selected modules"
                              >
                                <FaTrash color="red" />
                              </button>
                            </div> */}
                            <div style={dropdownStyles.container} ref={dropdownRef}>
                                <div style={dropdownStyles.header} onClick={toggleDropdown}>
                                  <span>{groupModuleData.length ? `${groupModuleData.length} module selected` : 'Please Select Module'}</span>
                                  <FaChevronDown />
                                </div>
                                {isOpen && (
                                  <div style={dropdownStyles.list}>
                                    {fetchModuleData.map((module) => (
                                      <div key={module._id} style={dropdownStyles.item}>
                                        <label>
                                          <input
                                            style={dropdownStyles.checkbox}
                                            type="checkbox"
                                            checked={groupModuleData.some(m => m._id === module._id)}
                                            onChange={() => handleModuleSelection(module)}
                                          />
                                          {module.moduleName}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                <button 
                                  onClick={clearAllModules}
                                  style={dropdownStyles.clearAllButton}
                                  aria-label="Clear all selected modules"
                                >
                                  <FaTrash color="red" />
                                </button>
                              </div>
                            {/* <div className="form-group inputINbttn">
                              <button
                                type="button"
                                className="btn middiumBttn themesecondarybackground  mt-2"
                              >
                                Add
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                      ) : (
                        <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Select Module</label>
                            <div style={dropdownStyles.container} ref={dropdownRef}>
                                <div style={dropdownStyles.header} onClick={toggleDropdown}>
                                  <span>Please Select Module</span>
                                  <FaChevronDown />
                                </div>
                                {isOpen && (
                                  <div style={dropdownStyles.list}>
                                    {moduleList.map((module) => (
                                      <div key={module._id} style={dropdownStyles.item}>
                                        <label>
                                          <input
                                            style={dropdownStyles.checkbox}
                                            type="checkbox"
                                            checked={groupModuleData.some(m => m._id === module._id)}
                                            onChange={() => handleManagerModuleSelection(module)}
                                          />
                                          {module.moduleName}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                <button 
                                  onClick={clearAllModules}
                                  style={dropdownStyles.clearAllButton}
                                  aria-label="Clear all selected modules"
                                >
                                  <FaTrash color="red" />
                                </button>
                              </div>
                            {/* <div className="form-group inputINbttn">
                              <button
                                type="button"
                                className="btn middiumBttn themesecondarybackground  mt-2"
                              >
                                Add
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                      ) }
                    
                      {userInfo === 'SuperAdmin' ? (
                    <div className="flex2fieldForm">
                      <div className="field-group">
                        {groupModuleData && groupModuleData?.length > 0 && (
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              <div
                                className="radioGroup"
                                style={{ marginTop: "3em" }}
                              >
                                {groupModuleData.map((item, index) => {
                                  if (item.moduleName) {
                                    return (
                                      <div
                                        key={`${item}-${index}`}
                                        className="mr-4 moduleCompetencyCard"
                                      >
                                        <label htmlFor="Module">
                                          {item.moduleName}
                                        </label>
                                        <button>
                                          <RxCross2 size={20} />
                                        </button>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>) : (
                      <div className="flex2fieldForm">
                      <div className="field-group">
                        {groupModuleData && groupModuleData?.length > 0 && (
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              <div
                                className="radioGroup"
                              >
                                {groupModuleData.map((item, index) => {
                                  if (item.moduleName) {
                                    return (
                                      <div
                                        key={`${item}-${index}`}
                                        className="mr-4 moduleCompetencyCard"
                                      >
                                        <label htmlFor="Module">
                                          {item.moduleName}
                                        </label>
                                        <button type="button" onClick={() => removeModule(item._id)}>
                                          <RxCross2 size={20} />
                                        </button>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    )}


                    <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group" style={{ marginTop: '30px' }}>
                                  <label>Assign Users</label>
                                  <div className="" style={{ 
                                    display: "flex", 
                                    alignItems: "center", 
                                    width: 'auto', 
                                    gap: '20px', 
                                    padding: '10px',
                                    backgroundColor: '#fff', 
                                    borderRadius: '4px'
                                  }}>
                                    <div style={{
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                      overflow: 'visible',
                                      position: 'relative',
                                      flexGrow: 1
                                    }}>
                                      <input
                                        type="text"
                                        placeholder="Search users..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        style={{ 
                                          width: '100%', 
                                          padding: '10px',
                                          border: 'none',
                                          outline: 'none'
                                        }}
                                      />
                                      {searchTerm && allUsers.length > 0 && (
                                        <div style={{
                                          position: 'absolute',
                                          top: '100%',
                                          left: 0,
                                          right: 0,
                                          backgroundColor: '#fff',
                                          border: '1px solid #ccc',
                                          borderTop: 'none',
                                          maxHeight: '200px',
                                          overflowY: 'auto',
                                          zIndex: 1000,
                                          boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                                        }}>
                                          {allUsers.map((item) => (
                                            <div 
                                              key={item._id}  
                                              onClick={() => handleUserSelect(item)}
                                              style={{
                                                padding: '10px',
                                                cursor: 'pointer',
                                                hover: {
                                                  backgroundColor: '#f0f0f0'
                                                }
                                              }}
                                            >
                                              {item.name}
                                            </div>
                                          ))}
                                        </div>
                                      )}

                                      {/* <div>
                                        {selectedUsers.map(user => (
                                          <div key={user._id} style={{ display: 'inline-block', margin: '5px', padding: '5px', border: '1px solid #000' }}>
                                            {user.name}
                                            <button onClick={() => handleUserRemove(user._id)} style={{ marginLeft: '5px' }}>Remove</button>
                                          </div>
                                        ))}
                                      </div> */}
                                    </div>
                                    <button 
                                      type="button" 
                                      className="btn btn-primary"
                                      onClick={assignModulesToUsers} disabled={selectedUserIds.length === 0}
                                    >
                                      Assign
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex2fieldForm">
                                <div className="field-group">
                                  {selectedUsers && selectedUsers?.length > 0 && (
                                    <div className="flex2fieldForm">
                                      <div className="field-group">
                                        <div
                                          className="radioGroup"
                                        >
                                          {selectedUsers.map((item, index) => {
                                            if (item.name) {
                                              return (
                                                <div
                                                  key={`${item}-${index}`}
                                                  className="mr-4 moduleCompetencyCard"
                                                >
                                                  <label htmlFor="Module">
                                                    {item.name}
                                                  </label>
                                                  <button type="button" onClick={() => handleUserRemove(item._id)}>
                                                    <RxCross2 size={20} />
                                                  </button>
                                                </div>
                                              );
                                            }
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

AddGroup = reduxForm({
  form: "addmodule",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: addModuleValidator,
})(AddGroup);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    addmodule: state.form.addmodule && state.form.addmodule.values,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);
