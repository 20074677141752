import React, { useState, useEffect } from "react";
import Autocomplete from "react-autocomplete";
import { case_insensitive_search } from "../../../common/utilityFunctions";
import { get } from "lodash";

const Search = (props) => {
  const {
    data = [],
    onItemSelected,
    populateFieldName,
    placeholderLabel,
    getStringData,
  } = props;
  let [initialData, setInitialData] = useState(data);
  let [searchString, setSearchString] = useState(props.initialValue || "");

  useEffect(() => {
    setInitialData(props.data);
  }, [props.data]);

  const getFilteredData = (searchString) => {
    setInitialData(
      data &&
        data.filter((item) =>
          case_insensitive_search(
            get(item, `${populateFieldName}`),
            searchString
          )
        )
    );
  };

  return (
    <div className="mainSearchBar" style={{ zIndex: 20, textAlign: "left", width: '400px' }}>
      <Autocomplete
        inputProps={{
          id: "daycare-autocomplete",
          className: "form-control",
          placeholder: placeholderLabel || "Search",
        }}
        value={searchString}
        items={initialData || []}
        getItemValue={(item) => get(item, `${populateFieldName}`)}
        onSelect={(value, item) => {
          // set the menu to only the selected item
          setSearchString(value);
          onItemSelected(item);
        }}
        onChange={(event, value) => {
          setSearchString(value);
          getFilteredData(value);
          getStringData(value);
        }}
        renderItem={(item, isHighlighted) => (
          <div
            style={{ textAlign: "left" }}
            className={`item ${isHighlighted ? "item-highlighted" : ""} ml-2 `}
            key={item._id}
          >
            <span className="text-center">
              {get(item, `${populateFieldName}`)}
            </span>{" "}
          </div>
        )}
      ></Autocomplete>

      <i className="fa fa-search"></i>
    </div>
  );
};

export default Search;
