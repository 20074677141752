import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import * as moduleActions from "../../../action/moduleActions";
import * as userActions from "../../../action/userActions";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import * as departmentActions from "../../../action/departmentActions";
import Ellipse9 from "../../../images/Ellipse9.png";
import camera from "../../../images/camera.svg";
import Swal from "sweetalert2";
import { get } from "lodash";
import Search from "../../common/Search";
import { getDepartment } from "../../../action/departmentActions";
import MobileNavBar from "../../../components/mobileNavBar";

let ModifyUser = (props) => {
  const { handleSubmit, allModulesByCompany, auth, token, user } = props;
  const [department, setDepartment] = useState({});

  const selectModel = localStorage.getItem("selectedModule");
  const [selectModule, setSelectModule] = useState(
    selectModel === null ? undefined : JSON.parse(selectModel)
  );
  let [allDepartments, setAllDepartments] = useState([]);
  let [departmentName, setdepartmentName] = useState(null);
  const [profilePic, setProfilePic] = useState(selectModule.profilePic);

  const [formData, setName] = useState({
    name: selectModule.name,
    emailAddress: selectModule.emailAddress,
    location: selectModule.location,
    employeeId: selectModule.employeeId,
    role: selectModule.role,
  });
  const [selectedModules, setSelectedModules] = useState([]);
  const [usersMobileNos, setUsersMobileNos] = useState([]);

  const history = useHistory();

  var modifyBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${Background})`,
  };

  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);

  useEffect(() => {
    props.moduleActions.getAllModulesByCompany(token, user.companyId);
    setSelectedModules(
      selectModule.modules && allModulesByCompany
        ? selectModule.modules.map(
            (assignedModule) =>
              allModulesByCompany.filter(
                (module) => module._id === assignedModule.moduleId
              )[0]
          )
        : []
    );
    userActions
      .getUsersMobileNo()
      .then((res) => setUsersMobileNos(res.data || []));
    loadAllDepartments();
  }, []);

  const loadAllDepartments = () => {
    departmentActions
      .getAllDepartments()
      .then((response) => setAllDepartments(response.data))
      .catch((error) => {
        // alert(JSON.stringify(err));
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(error)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        // setLoading(false);
      });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const modules =
      selectedModules &&
      selectedModules.map((item) => {
        return { moduleId: item._id, status: true };
      });
    const companyId = props.user.companyId;

    const values = {
      _id: selectModule._id,
      status: selectModule.status,
      firstTimeLogin: selectModule.firstTimeLogin,
      changePasswordAtFirstLogin: selectModule.changePasswordAtFirstLogin,
      name: formData.name,
      emailAddress: formData.name,
      department: departmentName,
      employeeId: formData.employeeId,
      location: formData.location,
      role: formData.role,
      companyId: companyId,
      departmentId: selectModule.departmentId,
      modules: modules,
      mobileNo: selectModule.mobileNo,
      __v: selectModule.__v,
      profilePic: profilePic,
      string: selectModule.string,
    };

    userActions
      .modifyUser(auth.token, values)
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "User Modified Successfully",
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(function () {
          history.push("/company-user-management");
        });
      })
      .catch((error) =>
        //   alert(error.response ? error.response.data.message : error.message)
        Swal.fire({
          title: "Error",
          text: `Error: ${
            error.response ? error.response.data.message : error.message
          }`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        })
      );
  };
  const handleChange = (e) => {
    setName({ ...formData, [e.target.name]: e.target.value });
  };
  const onModuleItemSelected = (module) => {
    if (
      !selectedModules.find(
        (selectedModule) => selectedModule._id === module._id
      )
    )
      setSelectedModules([...selectedModules, module]);
  };
  const onRemoveModuleClick = (e, moduleID) => {
    e.stopPropagation();
    const newSelectedModules = selectedModules.filter(
      (item) => item._id !== moduleID
    );
    setSelectedModules([...newSelectedModules]);
  };
  const onUpload = (event) => {
    userActions
      .uploadFile(auth.token, get(event, "target.files[0]"))
      .then((res) => {
        setProfilePic(res);
      });
  };
  const handleChangeDrop = (event) => {
    setdepartmentName(event.target.value);
    // setSelectedUsers([...selectedUsers, user]);
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <form onSubmit={handleFormSubmit}>
              <MobileNavBar />
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="right_hading d-none d-sm-inline">Modify User</h2>

                <div className="mt-3">
                  {/* <a href="#" className="adcm-btn Cancel ms-1"> Cancel</a> */}
                  <a>
                    <Link
                      to="/company-user-management"
                      className="adcm-btn Cancel ms-1"
                    >
                      <span>Cancel</span>
                    </Link>
                  </a>
                  <a className="adcm-btn ms-2">
                    <button
                      type="submit"
                      className="btn"
                      style={{ color: "#fff" }}
                    >
                      Save
                    </button>
                  </a>
                </div>
              </div>
              <h2 className="right_hading d-sm-none d-block">Dashboard</h2>

              <div className="row">
                <div className="col-lg-12">
                  <div className="company-main">
                    <div
                      className="company-profile"
                      style={modifyBackgroundStyle}
                    ></div>
                    <div className="company-fillout">
                      <div className="circle-outer">
                        <div className="circle">
                          {/* <img src={Ellipse9} alt="" /> */}
                          <img
                            className="profile-pic"
                            src={get(profilePic, "Location") || Ellipse9}
                            alt=""
                          />
                        </div>
                        <div className="p-image">
                          {/* <img className="upload-button" src="assets/images/camera.svg" alt="" /> */}
                          <img className="profile-pic" src={camera} alt="" />

                          {/* <input className="file-upload" type="file" accept="image/*" /> */}
                          <div
                            className="photoEdit mt-3"
                            style={{ position: "relative" }}
                          >
                          <input
                          onChange={onUpload}
                          accept={".jpg, .jpeg, .png"}
                          type="file"
                          className="overlaydpchange"
                        ></input>
                          </div>
                        </div>
                      </div>
                      <h3 className="fill-heaing">Employee Information</h3>
                      <div className="row align-items-end">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Company Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="ABC"
                              autocomplete="off"
                            />
                          </div>
                          <div className="form-group">
                            <label>User Name</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="User name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                            {/* <Field
                                                            name="name"
                                                            component={renderInputField}
                                                            type="text"
                                                            label="User name"
                                                        /> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label for="exampleInputName2">
                              User Email Address
                            </label>
                            {/* <input type="text" className="form-control" placeholder="akshay@abcmail.com" autocomplete="off" /> */}
                            <input
                              type="text"
                              name="emailAddress"
                              className="form-control"
                              placeholder="akshay@abcmail.com"
                              value={formData.emailAddress}
                              autocomplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="company-fillout snd">
                      <h3 className="fill-heaing">Employment Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Department</label>
                            {/* <input type="text" className="form-control" placeholder="Admin" autocomplete="off" /> */}
                            {/* <input
                                                            type="text"
                                                            name="department"
                                                            className="form-control"
                                                            placeholder="User name"
                                                            value={formData.department}
                                                            onChange={handleChange}

                                                        /> */}
                            <select
                              className="form-control select2"
                              value={
                                departmentName === null
                                  ? selectModule.department
                                  : departmentName
                              }
                              onChange={handleChangeDrop}
                            >
                              <option value="">--Select--</option>
                              {allDepartments &&
                                allDepartments.length > 0 &&
                                allDepartments.map((result, i) => {
                                  return (
                                    <option
                                      value={result.departmentName}
                                      key={result._id}
                                    >
                                      {result.departmentName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label>Course</label>
                            {/* <input type="text" className="form-control" placeholder="1234567890" autocomplete="off" /> */}
                            <input
                              type="text"
                              name="employeeId"
                              className="form-control"
                              placeholder="Course Name"
                              value={formData.employeeId}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>Modules</label>
                            <div className="flex2fieldForm">
                              <div className="field-group">
                                <Search
                                  {...{
                                    data: allModulesByCompany,
                                    onItemSelected: onModuleItemSelected,
                                    populateFieldName: "moduleName",
                                    placeholderLabel: "Select Modules",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="flex2fieldForm">
                              <div className="field-group">
                                {selectedModules &&
                                  selectedModules.length > 0 && (
                                    <div className="flex2fieldForm">
                                      <div className="field-group">
                                        <div className="d-flex radioGroup">
                                          {selectedModules.map(
                                            (item, index) => (
                                              <div
                                                key={`${item}-${index}`}
                                                className="mr-4 moduleCompetencyCard"
                                              >
                                                <label htmlFor="Module">
                                                  {item.moduleName}
                                                </label>
                                                <button
                                                  onClick={(e) =>
                                                    onRemoveModuleClick(
                                                      e,
                                                      item._id
                                                    )
                                                  }
                                                >
                                                  X
                                                </button>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Location</label>
                            {/* <input type="text" className="form-control" placeholder="Mumbai" autocomplete="off" /> */}
                            <input
                              type="text"
                              name="location"
                              className="form-control"
                              placeholder="User name"
                              value={formData.location}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label>Role</label>
                            {/* <input type="text" className="form-control" placeholder="Please Select" autocomplete="off" /> */}
                            <input
                              type="text"
                              name="role"
                              className="form-control"
                              placeholder="User name"
                              value={formData.role}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

// ModifyCompanyUser = reduxForm({
//     form: "modifcompanyyuser",
//     validate,
//     enableReinitialize: true,

//   })(ModifyCompanyUser);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    initialValues: state.users.selectedUser,
    token: state.auth.token,
    allModulesByCompany: state.modules.allModulesByCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifyUser);
