import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import caretrightsolid from "../../images/video-thumb.png";

export class VideoModal extends Component {
  constructor(propdata) {
    super();
    this.state = {
      isOpen: false,
      para: propdata,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div>
        <div>
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            // videoId="aeQa9U0fLCI"
            videoId="wpWFV41U5Wk"
            onClose={() => this.setState({ isOpen: false })}
          />
          {/* <span className="play" onClick={this.openModal}></span> */}
          <button className="vedioImg mb-4" onClick={this.openModal}>
            <img src={caretrightsolid} alt="" />
          </button>
        </div>
      </div>
    );
  }
}

export default VideoModal;
