import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
const colors = ["blue", "red", "orange"];
const CustomAreaChart = (props) => {
  let sid = "colorUv" + Math.random();
  return (
    <AreaChart
      width={props.width}
      height={props.height}
      data={props.data}
      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#4458FE" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#4458FE" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey={props.nameKey} />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Area
        type="monotone"
        dataKey={props.dataKey}
        stroke="#8884d8"
        fillOpacity={1}
        fill="url(#colorUv)"
      />
    </AreaChart>
  );
};
CustomAreaChart.propTypes = {
  hideLegend: PropTypes.bool,
  data: PropTypes.array,
  datakey: PropTypes.string,
  nameKey: PropTypes.string,
  legendPosition: PropTypes.oneOf(["right", "inset", "bottom"]),
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default CustomAreaChart;
