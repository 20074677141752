export const  raiseError=error=>
{
    return {type: 'ON_ERROR', error};
}

export const  clearError=()=>
{
    return {type: 'CLEAR_ERROR'};
}

export const  initiateLoad=()=>
{
    return {type: 'LOADING_STARTED'};
}

export const endLoad=()=>{
    return {type: 'LOADING_STOPPED'};
}
export const loadingStarted = () => {
    return (dispatch) => 
              dispatch(initiateLoad());
       
  };

  export const loadingEnded = () => {
    return (dispatch) => 
              dispatch(endLoad());
       
  };