import React, { useState, Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { get } from "lodash";
import camera from "../../../images/camera.svg";
import * as userActions from "../../../action/userActions";
import Search from "../../common/Search";
import Swal from "sweetalert2";
import { bindActionCreators } from "redux";
import * as moduleActions from "../../../action/moduleActions";
import { createDepartment } from "../../../action/departmentActions";
import Ellipse9 from "../../../images/Ellipse9.png";
import MobileNavBar from "../../../components/mobileNavBar";

let AddCompany = (props) => {
  // const AddCompany = (values) => {
  //   companyActions
  //     .addCompany(props.auth.token, {
  //       ...values,
  //       maximumNumberOfUsers: parseInt(values.maximumNumberOfUsers || 0),
  //     })
  //     .then((result) => {
  //       alert.info("Company  Added Successfully");

  //       history.push("/departments");
  //     })
  //     .catch((error) =>
  //       alert.error(
  //         error.response ? error.response.data.message : error.message
  //       )
  //     );
  // };

  // const nextStep = () => setStep(step + 1);
  // const prevStep = () => (step > 1 ? setStep(step - 1) : setStep(1));

  // switch (step) {
  //   case 1:
  //     return <AddCompanyFormStep1 onSubmit={nextStep} />;
  //   case 2:
  //     return (
  //       <AddCompanyFormStep2 onPrevious={prevStep} onSubmit={AddCompany} />
  //     );
  // }
  const { token, user, allModulesByCompany } = props;
  const [department, setDepartment] = useState({});
  const [selectedModules, setSelectedModules] = useState([]);
  const [CompanyLogo, setCompanyLogo] = useState();
  const history = useHistory();

  useEffect(() => {
    props.moduleActions.getAllModulesByCompany(token, user.companyId);
  }, []);

  const onModuleItemSelected = (module) => {
    if (
      !selectedModules.find(
        (selectedModule) => selectedModule._id === module._id
      )
    )
      setSelectedModules([...selectedModules, module]);
  };

  const onRemoveModuleClick = (e, moduleID) => {
    const newSelectedModules = selectedModules.filter(
      (item) => item._id !== moduleID
    );
    setSelectedModules([...newSelectedModules]);
  };

  const updateDepartment = (e) => {
    const updatedDepartment = Object.assign({}, department);
    updatedDepartment[e.target.name] = e.target.value;
    setDepartment(updatedDepartment);
  };

  const saveDepartment = () => {
    department.modules = selectedModules;
    department.status = true;
    department.companyId = user.companyId;
    department.departmentLogo = CompanyLogo;
    createDepartment(department)
      .then(() => {
        Swal.fire({
          allowOutsideClick: false,
          title: "Success",
          text: "Department Added Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(function () {
          if (user.role === "SuperAdmin") {
            history.push("/departments");
          } else {
            history.push("/departments");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          allowOutsideClick: false,
          title: "Error",
          text: `Error: ${
            error.response.data.message
              ? error.response.data.message
              : error.response.data
              ? error.response.data
              : error.message
          }`,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const onUpload = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      setCompanyLogo(res);
    });
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="right_hading d-none d-sm-inline">
                Add Department
              </h2>
              <div className="mt-3">
                <Link to="/departments" className="adcm-btn Cancel ms-1">
                  <span>Cancel</span>
                </Link>
                <button className="adcm-btn ms-1" onClick={saveDepartment}>
                  + Add
                </button>
              </div>
            </div>
            <h2 className="right_hading d-sm-none d-block">Dashboard</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="company-main">
                  <div className="company-fillout" style={{ marginTop: "5em" }}>
                    <div className="circle-outer">
                      <div className="circle">
                        <img
                          className="profile-pic"
                          src={get(CompanyLogo, "Location") || Ellipse9}
                          alt=""
                        />
                      </div>
                      <div className="p-image">
                        <div
                          className="photoEdit"
                          style={{ position: "relative" }}
                        >
                          <input
                            onChange={onUpload}
                            accept={".jpg, .jpeg, .png"}
                            type="file"
                            style={{
                              opacity: 0,
                              width: "100%",
                              position: "absolute",
                            }}
                          ></input>
                          <img className="upload-button" src={camera} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Department Name</label>
                          <input
                            name="departmentName"
                            type="text"
                            placeholder="Department Name"
                            className="form-control"
                            onChange={(e) => updateDepartment(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Maximum Number of Users</label>
                          {/* <input type="text" className="form-control" placeholder="100" autocomplete="off" /> */}
                          <input
                            name="max_users"
                            type="number"
                            placeholder="Maximum Number of Users"
                            className="form-control"
                            value={department?.max_users}
                            onChange={(e) => updateDepartment(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="form-group">
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              <label>Modules</label>
                              <Search
                                {...{
                                  data: allModulesByCompany,
                                  onItemSelected: onModuleItemSelected,
                                  populateFieldName: "moduleName",
                                  placeholderLabel: "Select Modules",
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              {selectedModules &&
                                selectedModules.length > 0 && (
                                  <div className="flex2fieldForm">
                                    <div className="field-group">
                                      <div
                                        className="radioGroup"
                                        style={{ marginTop: "3em" }}
                                      >
                                        {selectedModules.map((item, index) => (
                                          <div
                                            key={`${item}-${index}`}
                                            className="mr-4 moduleCompetencyCard"
                                          >
                                            <label htmlFor="Module">
                                              {item.moduleName}
                                            </label>
                                            <button
                                              onClick={(e) =>
                                                onRemoveModuleClick(e, item._id)
                                              }
                                            >
                                              X
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    allModulesByCompany: state.modules.allModulesByCompany,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
    dispatch: dispatch,
  };
};
AddCompany = connect(mapStateToProps, mapDispatchToProps)(AddCompany);
export default AddCompany;
