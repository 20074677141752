import initialState from './initialState';

export default function globalErrorReducer(state=initialState.globalError, action) {
  
    switch(action.type) {
        case 'ON_ERROR':
            return action.error.message;
       case 'CLEAR_ERROR':
                return {};
    
        default:
            return state;
    }
}
