import { createStore, compose, applyMiddleware } from 'redux'

import createRootReducer from './Reducers';

import thunk from 'redux-thunk';


import { createBrowserHistory, createMemoryHistory } from 'history';
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
// import { routerMiddleware } from 'connected-react-router';
// import throttle from 'lodash.throttle';
// import defaultState from './Reducers/initialState';
// import thunkMiddleware from 'redux-thunk-recursion-detect';
// import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
export const history = createMemoryHistory();
//const savedState = loadState();




const configureStorenew = (preloadedState, history) => {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    applyMiddleware(thunk)
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./Reducers', () => {
      const nextRootReducer = require('./Reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStorenew;
//export default configureStore;