import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { getDepartment } from "../../../action/departmentActions";
import MobileNavBar from "../../../components/mobileNavBar";

let ImportUsersStep2 = (props) => {
  const { auth } = props;
  const [department, setDepartment] = useState({});
  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);
  return (
    <>
      <div className="col-xl-12 col-lg-12 overview-left-area your-module">
        <MobileNavBar />
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="right_hading d-none d-sm-inline">Import Users</h2>

          <div className="mt-3">
            <a>
              <Link to={"/import-user"} className="adcm-btn Cancel ms-1">
                <span>Cancel</span>
              </Link>
            </a>
            <button className="adcm-btn ms-2" onClick={props.onSubmit}>
              Import
            </button>
          </div>
        </div>
        <div>
          <div className="col-xl-12">
            <div className="modular-table table-responsive-xl">
              <table className="table align-middle-modify">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col" className="text-center">
                      Email Address
                    </th>
                    <th scope="col" className="text-center">
                      User ID
                    </th>
                    <th scope="col" className="text-center">
                      Course
                    </th>
                    <th scope="col" className="text-center">
                      Role
                    </th>
                    <th scope="col" className="text-center">
                      Location
                    </th>
                    <th scope="col" className="text-center">
                      Department
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.users?.map((item) => (
                    <tr key={item._id}>
                      <td>{item?.name}</td>
                      <td className="text-center">{item?.emailAddress}</td>
                      <td className="text-center">{item?.mobileNo || "N/A"}</td>
                      <td className="text-center">
                        {item?.employeeId || "N/A"}
                      </td>
                      <td className="text-center">{item?.role}</td>
                      <td className="text-center">{item?.location || "N/A"}</td>
                      <td className="text-center">
                        {item?.department.length > 0
                          ? item?.department.map((dpt, index) => {
                              if (index + 1 == item?.department.length) {
                                return `${dpt}`;
                              } else {
                                return `${dpt}, `;
                              }
                            })
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(ImportUsersStep2);
