import React, { useEffect, useRef, useState } from "react";
import MobileNavBar from "../../../components/mobileNavBar";
import * as moduleActions from "../../../action/moduleActions";
import { connect } from "react-redux";
import { Multiselect } from "react-widgets";
import * as departmentActions from "../../../action/departmentActions";
import Swal from "sweetalert2";
import { bindActionCreators } from "redux";
import * as userActions from "../../../action/userActions";
import { reportFilterValidator } from "../Report/ReportValidators";
import { Field, reduxForm } from "redux-form";
import { getStatisticsReport } from "../../../action/moduleActions";
import { get } from "lodash";
import {
  renderInputField,
  renderRadioButtonField,
} from "../../../common/InputFields";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getBulkReport } from "../../../action/moduleActions";

let GlobalDashboard = (props) => {
  console.log("props value", props);

  const { handleSubmit, auth, moduleList, token, user, module } = props;

  const [filterModule, setfilterModule] = useState(true);
  const [filterDepartment, setfilterDepartment] = useState(false);
  const [filterCourse, setfilterCourse] = useState(false);
  const [filterAll, setfilterAll] = useState(false);
  const [Header, setHeader] = useState("Department");
  let [allCourses, setAllCourses] = useState([]);
  let [allUserCourses, setAllUserCourses] = useState([]);
  let [allDepartments, setAllDepartments] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchIds, setSearchIds] = useState([]);
  const [report, setReport] = useState({
    max_session_duration: 0,
    average_session_duration: 0,
    user_count: 0,
    session_count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("Training");
  const [filterUser, setfilterUser] = useState(false);

  let multiSelectData = [];

  useEffect(() => {
    setLoading(true);
    if (user.role == "Manager") {
      setLoading(false);
    } else {
      getStatisticsReport({
        filter_type: "All",
        filter_value: 1,
        from: "2000-12-11T00:00:00.000Z",
        to: new Date(),
      }).then((result) => {
        setLoading(false);
        setReport(result.data);
        setLoading(false);
      });
      // loadGlobalDataReport();
    }

    props.moduleActions.getAllModulesByCompany(auth.token, user.companyId);

    if (user.role === "Manager") {
      userActions.getModules().then((result) => setAllCourses(result.data));
      userActions.getCourses().then((result) => setAllUserCourses(result.data));
    } else {
      userActions.getModules().then((result) => {
        setAllCourses(result.data);
      });
      userActions.getCourses().then((result) => setAllUserCourses(result.data));
    }
    loadAllDepartments();
  }, []);

  const onChange = (event) => {
    let updatedId = [];
    for (let i = 0; i < event.length; i++) {
      updatedId.push(event[i]._id);
    }
    setSearchIds(updatedId);
  };

  const loadAllDepartments = () => {
    departmentActions
      .getAllDepartments()
      .then((response) => {
        setAllDepartments(response.data);
        if (user.role == "Manager") {
          let updatedDepartments = [];
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < user.departmentId.length; j++) {
              if (user.departmentId[j] == response.data[i]._id) {
                updatedDepartments.push(response.data[i]);
              }
            }
          }
          setAllDepartments(updatedDepartments);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(error)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        // setLoading(false);
      });
  };

  let [searchTerm, setsearchTerm] = useState("");
  let [usersAll, setUsersAll] = useState([]);
  let [searchUsers, setSearchUsers] = useState([]);

  const getReport = () => {
    // setshowResult(false);
    // setLoading(true);
    // setResultType(reportType);
    // console.log("the ids", searchIds)

    if (searchIds.length == 0 || fromDate === "" || toDate === "") {
      if (toDate === "" || fromDate === "") {
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(`Please select a date range !`)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }

      if (searchIds.length == 0) {
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(`Please select a ${Header} !`)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }

      //   setLoading(false);
    } else {
      setLoading(true);
      getBulkReport({
        search: filterModule
          ? "module"
          : filterDepartment
          ? "department"
          : filterCourse
          ? "course"
          : "user",
        reportType: reportType,
        searchIds: searchIds,
        from: new Date(fromDate),
        to: new Date(toDate),
        stats: true,
      })
        // getStatisticsReport({
        //   filter_type: filterDepartment
        //     ? "Department"
        //     : filterCourse
        //     ? "Module"
        //     : "Course",
        //   filter_value: searchIds,
        //   from: new Date(fromDate),
        //   to: new Date(toDate),
        // })
        .then((result) => {
          setReport(result.data);
        })
        .finally(() => setLoading(false));
    }
  };

  multiSelectData = filterDepartment
    ? allDepartments &&
      allDepartments.length > 0 &&
      allDepartments.map((item) => ({
        _id: item._id,
        moduleName: item.departmentName,
      }))
    : filterModule
    ? allCourses &&
      allCourses.length > 0 &&
      allCourses.map((item) => ({
        _id: item._id,
        moduleName: item.moduleName,
      }))
    : filterCourse &&
      allUserCourses &&
      allUserCourses.length > 0 &&
      allUserCourses.map((item) => ({
        _id: item._id,
        moduleName: item.courseName,
      }));

  const getDurationString = (d) => {
    if (d === null || typeof d === "undefined" || d === 0) {
      return "";
    }
    let x = d.toString().split(".");

    x[0] = parseInt(x[0]);
    x[1] = parseInt(x[1].slice(0, 2)) / 100;

    let durationString = x[0] + " hrs " + (x[1] * 60).toFixed(0) + " mins";
    return durationString;
  };

  const searchUsermod = () => {
    setLoading(true);
    let userAllData = [];

    usersAll.forEach((user) => {
      searchIds.forEach((sUser) => {
        if (user._id == sUser) {
          userAllData.push(user);
        }
      });
    });

    userActions
      .searchUsers(searchTerm)
      .then((response) => {
        // setUsersAll(response.data);
        response.data.forEach((user) => {
          userAllData.push(user);
        });

        setUsersAll(userAllData);
      })
      .finally(() => setLoading(false));
  };

  const inputRef = useRef(null);

  const filterModulefun = () => {
    setfilterModule(true);
    setfilterDepartment(false);
    setfilterCourse(false);
    setfilterUser(false);
    setHeader("Module");
  };

  const filterDepartmentfun = () => {
    setfilterModule(false);
    setfilterDepartment(true);
    setfilterCourse(false);
    setfilterUser(false);
    setHeader("Department");
  };

  const filterCoursefun = () => {
    setfilterModule(false);
    setfilterDepartment(false);
    setfilterUser(false);
    setfilterCourse(true);
    setHeader("Course");
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">
                Global Performance
              </h2>
            </div>
            {!loading ? (
              <></>
            ) : (
              <div className="loader-wrapper">
                <div className="loader"></div>
              </div>
            )}
            <div className="row pb-5">
              <div className="col-xl-3 col-md-6 col-sm-6 progress-bar-sec">
                <div className="sec1">
                  <h4>Total number of sessions</h4>
                  <div className="row">
                    <div className="col-7 pr-0">
                      <h5>{report?.session_count}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 progress-bar-sec">
                <div className="sec1">
                  <h4>Active users</h4>
                  <div className="row">
                    <div className="col-7 pr-0">
                      <h5>{report?.user_count}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 progress-bar-sec">
                <div className="sec1">
                  <h4>Avg session duration</h4>
                  <div className="row">
                    <div className="col-7 pr-0">
                      <h5>
                        {
                          getDurationString(report?.average_session_duration) ||
                            0
                          // (module.globaldataReport?.average_session_duration * 100
                          // ).toFixed(0)
                        }
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-6 progress-bar-sec">
                <div className="sec1">
                  <h4>Maximum session duration</h4>
                  <div className="row">
                    <div className="col-12 pr-0">
                      <h5>
                        {report?.max_session_duration == 0
                          ? 0
                          : getDurationString(report?.max_session_duration)}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="company-main report">
                <div className="company-fillout">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Select Criteria</label>
                      <ul>
                        <li>
                          <a
                            className={
                              filterDepartment ? "data-btn active" : "data-btn"
                            }
                            onClick={filterDepartmentfun}
                          >
                            Department
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              filterCourse ? "data-btn active" : "data-btn"
                            }
                            onClick={filterCoursefun}
                          >
                            Module
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              filterAll ? "data-btn active" : "data-btn"
                            }
                            onClick={filterAllfun}
                          >
                            Course
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{Header}</label>
                        {multiSelectData && multiSelectData.length > 0 && (
                          <Multiselect
                            key={JSON.stringify(multiSelectData)}
                            data={multiSelectData || []}
                            onChange={onChange}
                            placeholder="Please Select..."
                            textField="moduleName"
                            className="form-control"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>From Date</label>
                        <input
                          name="fromDate"
                          className="form-control"
                          component={renderInputField}
                          type="date"
                          label="From date"
                          onChange={(e) => setFromDate(e.target.value)}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>To Date</label>
                        <input
                          name="toDate"
                          className="form-control"
                          component={renderInputField}
                          type="date"
                          label="To date"
                          data-date-format="MM DD YYYY"
                          onChange={(e) => setToDate(e.target.value)}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="adcm-btn search"
                    onClick={() => {
                      getReport();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-lg-12"> */}
              <div className="company-main report">
                <div className="company-fillout">
                  <h3 className="fill-heaing">Data Selection</h3>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Select Report Type</label>
                        <ul>
                          <li>
                            <a
                              className={
                                reportType === "Training"
                                  ? "data-btn active"
                                  : "data-btn"
                              }
                              onClick={() => setReportType("Training")}
                            >
                              Training
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                reportType === "Assessment"
                                  ? "data-btn active"
                                  : "data-btn"
                              }
                              onClick={() => setReportType("Assessment")}
                            >
                              Assessment
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {!loading ? (
                      <></>
                    ) : (
                      <div className="loader-wrapper">
                        <div className="loader"></div>
                      </div>
                    )}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Select Criteria</label>
                        <ul>
                          <li>
                            <a
                              className={
                                filterModule ? "data-btn active" : "data-btn"
                              }
                              onClick={filterModulefun}
                            >
                              Module
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                filterDepartment
                                  ? "data-btn active"
                                  : "data-btn"
                              }
                              onClick={filterDepartmentfun}
                            >
                              Department
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                filterCourse ? "data-btn active" : "data-btn"
                              }
                              onClick={filterCoursefun}
                            >
                              Course
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>{Header}</label>
                        {/* {multiSelectData && multiSelectData.length > 0 && ( */}
                        <Multiselect
                          key={JSON.stringify(multiSelectData)}
                          data={multiSelectData || []}
                          onChange={onChange}
                          placeholder="Please Select..."
                          textField="moduleName"
                          className="form-control"
                        />
                        {/* )} */}
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>From Date</label>
                        <input
                          name="fromDate"
                          className="form-control"
                          component={renderInputField}
                          type="date"
                          label="From date"
                          onChange={(e) => setFromDate(e.target.value)}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>To Date</label>
                        <input
                          name="toDate"
                          className="form-control"
                          component={renderInputField}
                          type="date"
                          label="To date"
                          data-date-format="MM DD YYYY"
                          onChange={(e) => setToDate(e.target.value)}
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="adcm-btn search"
                    style={{ margin: 0 }}
                    onClick={() => {
                      getReport();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              {/* </div>
              </div> */}

              <div className="col-md-8">
                <div className="d-flex justify-content-between align-items-center">
                  <h2 className="right_hading d-none d-md-inline">
                    Most Attempted Programs
                  </h2>
                </div>
              </div>

              <div className="col-xl-12">
                <div className="modular-table big-table table-responsive">
                  <table className="table align-middle-modify">
                    <thead>
                      <tr>
                        <th scope="col">Program</th>
                        {/* <th scope="col" className="text-center">
                                                    Industry
                                                </th>
                                                <th scope="col" className="text-center">
                                                    Module Type
                                                </th> */}
                        <th scope="col" className="text-center">
                          Module
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {report?.top_five_modules?.map((item) => (
                        <tr key={item._id}>
                          <td>{item.module.moduleName}</td>
                          {/* <td className="text-center">
                                                            {item.module.industry}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.module.moduleType}
                                                        </td> */}

                          <td className="text-center">
                            {item.module.competencies.join(", ")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
GlobalDashboard = reduxForm({
  form: "globalDashboard",
  enableReinitialize: true,
  validate: reportFilterValidator,
})(GlobalDashboard);

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth.user,
  initialValues: state.users.selectedUser,
  token: state.auth.token,
  // globalDashboard: get(state, "form.globalDashboard.values"),
  module: state.modules,
  moduleList: state.modules.allModulesByCompany,
});

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDashboard);
