import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../action/userActions";
import * as companyActions from "../../../action/companyActions";
import { Link, useHistory } from "react-router-dom";
import { initialize, destroy } from "redux-form";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { get } from "lodash";
import Ellipse9 from "../../../images/Ellipse9.png";
import DataGrid from "../../../common/dataGrid";
import Search from "../../common/Search";
import Swal from "sweetalert2";
import $ from "jquery";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { getDepartment } from "../../../action/departmentActions";
import { GrAdd } from "react-icons/gr";
import { FiEdit3 } from "react-icons/fi";
import { FcPrevious } from "react-icons/fc";
import { FcNext } from "react-icons/fc";
import { IoTrashBin } from "react-icons/io5";
import MobileNavBar from "../../../components/mobileNavBar";

const UserManagement = (props) => {
  const { auth } = props;
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(10);
  let [sortColumn, setSortColumn] = useState("name");
  let [sortOrder, setSortOrder] = useState(1);
  let [users, setUsers] = useState([]);
  let [usersAll, setUsersAll] = useState([]);
  let [usersCount, setUsersCount] = useState(0);
  let [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  let [searchTerm, setsearchTerm] = useState("");
  const [department, setDepartment] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const inputRef = useRef(null);
  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);

  useEffect(() => {
    getDepartment(auth.user.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
    loadUsers();
  }, [sortColumn, sortOrder, pageSize, pageNumber]);
  const history = useHistory();

  const onItemSelected = (data) => {
    console.log("the id data", data);
    userActions.selectedUser(data);
    const serializedState = JSON.stringify(data);
    localStorage.setItem("selectedModule", serializedState);
    localStorage.setItem("userSearch", true);
    history.push(`/modify-companyuser?user_id=${data._id}`);
  };

  const modifyUser = (data) => {
    userActions.selectedUser(data);
    const serializedState = JSON.stringify(data);
    localStorage.setItem("selectedModule", serializedState);
    localStorage.setItem("userSearch", false);
    history.push(`/modify-companyuser?user_id=${data._id}`);
  };
  const deleteUser = (data) => {
    Swal.fire({
      allowOutsideClick: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6a61f6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      if (result.isConfirmed) {
        userActions.deleteUser(data._id).then((responce) => {
          if (responce.data.status) {
            Swal.fire({
              title: "Success",
              text: "User Deleted Successfully",
              icon: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            }).then(function () {
              history.push("/user-management");
            });
          }
        });
      }
    });
  };
  const handleModalOpened = () => {
    inputRef.current.focus();
  };

  const searchUsermod = () => {
    setLoading(true);

    userActions
      .searchUsers(searchTerm)
      .then((response) => {
        setUsersAll(response.data);
      })
      .finally(() => setLoading(false));
  };

  const modifyUserForSearch = (data) => {
    userActions.selectedUser(data);
    props.dispatch && props.dispatch(initialize("modifyuser", { ...data }));
    const serializedState = JSON.stringify(data);
    localStorage.setItem("selectedModule", serializedState);
    history.push(`/modify-user-details?user_id=${data._id}`);
  };

  const loadUserforsearch = () => {
    const limit = pageSize;
    const offset = pageNumber;
    userActions.searchUsers(searchTerm, limit, offset).then((response) => {
      setUsersAll(response.data);
    });
  };

  const loadUsers = () => {
    setLoading(true);
    userActions
      .fetchUsers(pageNumber, pageSize, sortOrder, sortColumn, auth.user?._id)
      .then((response) => {
        setUsers(response.data.users);
        setUsersCount(response.data.usersCount);
      })
      .catch((error) => console.error(error.data.response))
      .finally(() => setLoading(false));
  };

  const customStyles = {
    headCells: {
      style: {
        "border-top": "none",
        "font-size": "15px",
        color: "#2F4153",
        "font-weight": 700,
        "&:last-child": {
          "text-align": "right",
        },
      },
    },
  };

  const onChangePage = (page, totalRows) => {
    setPageNumber(page);
  };

  const onSort = (column, sortDirection, event) => {
    setSortColumn(column.selector);
    setSortOrder(sortDirection === "asc" ? 1 : -1);
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setPageNumber(1);
    setPageSize(currentRowsPerPage);
  };

  const actionCell = (row) => {
    return (
      <div style={{ textAlign: "right" }}>
        <span
          type="submit"
          className="primary_link"
          onClick={() => modifyUser(row)}
        >
          <FiEdit3 />
        </span>
        <span
          type="submit"
          className="primary_link"
          onClick={() => deleteUser(row)}
        >
          <IoTrashBin />
        </span>
      </div>
    );
  };
  const selectedModule = (data, goto) => {
    props.moduleActions.selectedModule(data);
    history.push(`/${goto}`);
  };

  const columns = [
    { name: "User Name", selector: "name", sortable: true, wrap: true },
    {
      name: "Email Address",
      selector: "emailAddress",
      sortable: true,
      wrap: true,
    },
    { name: "Role", selector: "role", sortable: true },
    { name: "Action", cell: actionCell },
  ];

  const handleSearchClick = (event) => {
    event.preventDefault();
    toggle();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };
  const handleSearchClick2 = (event) => {
    event.preventDefault();
    toggle2();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300); // Adjust the delay if needed
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="row justify-content-between align-items-center my-3">
              <div className="col-lg-2 col-md-12 order-d">
                <h2 className="right_hading d-none d-md-inline">All Users</h2>
              </div>
              <div className="col-xl col-lg-12 text-lg-end">
                <form action="" className="search">
                  {/* <input type="text" autoFocus onClick={toggle} placeholder="Search..." /> */}
                  <input
                    type="text"
                    onClick={handleSearchClick}
                    placeholder="Search..."
                  />
                  {/* <input
                    type="text"
                    onClick={(event) => {
                      toggle();
                      event.currentTarget.focus();
                    }}
                    placeholder="Search..."
                  /> */}
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    {...props}
                    className="search-modal"
                  >
                    {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
                    <ModalBody>
                      {/* <SearchUser onItemSelected={modifyUserForSearch} /> */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="input-group">
                            <input
                              ref={inputRef}
                              type="text"
                              autoFocus
                              placeholder="Search users..."
                              onChange={(event) => {
                                setsearchTerm(event.target.value);
                              }}
                            />
                            <button
                              className="custombtnSearch"
                              onClick={searchUsermod}
                            >
                              {" "}
                              search
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="list-container">
                            <ul>
                              {usersAll &&
                                usersAll
                                  .filter((val) => {
                                    if (searchTerm == "") {
                                      return val;
                                    } else if (
                                      val.name
                                        ?.toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                    ) {
                                      return val;
                                    }
                                  })
                                  .slice(
                                    (currentPage - 1) * itemsPerPage,
                                    currentPage * itemsPerPage
                                  )
                                  .map(function (item, key) {
                                    return key < 5 ? (
                                      <li key={key}>
                                        <div className="imgbk">
                                          <img
                                            className="profile-pic"
                                            src={
                                              get(
                                                item.profilePic,
                                                "Location"
                                              ) || Ellipse9
                                            }
                                            alt=""
                                          />
                                        </div>

                                        <div
                                          className="desc"
                                          style={{ flex: 1 }}
                                        >
                                          <span className="text-left">
                                            {item.name}
                                          </span>
                                          <span className="textEmail">
                                            {item.emailAddress}
                                          </span>
                                        </div>

                                        <div className="text-right">
                                          <button
                                            className="btn secondary-btn btn-sm m-1"
                                            onClick={() =>
                                              modifyUserForSearch(item)
                                            }
                                          >
                                            View
                                          </button>

                                          <button
                                            className="btn third-btn btn-sm m-1"
                                            onClick={() => onItemSelected(item)}
                                            style={{ color: "#fff" }}
                                          >
                                            Edit
                                          </button>
                                        </div>
                                      </li>
                                    ) : null;
                                  })
                                  .filter((x) => x)}
                            </ul>
                          </div>
                        </div>
                        {usersAll.length > 5 ? (
                          <div className="col-md-12">
                            <div className="d-flex justify-content-center py-2">
                              <button
                                className="paginationBtn"
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    Math.max(prev - 1, 1)
                                  )
                                }
                                disabled={currentPage === 1}
                              >
                                <FcPrevious />
                              </button>
                              <button
                                className="paginationBtn"
                                onClick={() =>
                                  setCurrentPage((prev) =>
                                    Math.min(
                                      prev + 1,
                                      Math.ceil(usersAll.length / itemsPerPage)
                                    )
                                  )
                                }
                                disabled={
                                  currentPage ===
                                  Math.ceil(usersAll.length / itemsPerPage)
                                }
                              >
                                <FcNext />
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </ModalBody>
                  </Modal>
                </form>
              </div>

              <div className="col-xl-5 col-md-12 text-end mt-2 mt-xl-0">
                {/* <a href="#" className="adcm-btn"><i className="fa-solid fa-plus me-1"></i> Import Users</a> */}
                <Link to="/import-user" className="third-btn adcm-btn ms-1">
                  <span>Import Users</span>
                </Link>
                {/* <CSVLink
                  className="adcm-btn ms-1"
                  data={usersAll}
                  target="_blank"
                  filename={"report.csv"}
                > */}
                <Link to="/export-user" className="adcm-btn secondary-btn ms-1">
                  <span>Export Users</span>
                </Link>
                {/* </CSVLink> */}
                {/* <Link to="/import-user" className="adcm-btn ms-1">
                  <span>Export Users</span>
                </Link> */}

                {/* <a href="#" className="adcm-btn ms-1"><i className="fa-solid fa-plus me-1"></i> Add User</a> */}
                <Link to="/add-companyuser" className="adcm-btn ms-1">
                  <span>
                    <GrAdd /> Add User
                  </span>
                </Link>
              </div>
            </div>

            {/* <h2 className="right_hading d-md-none d-block">All Users</h2> */}
            <div className="row">
              <div className="col-xl-12">
                <div className="modular-table table-responsive">
                  <DataGrid
                    noHeader
                    data={users}
                    paginationTotalRows={usersCount}
                    columns={columns}
                    onChangePage={onChangePage}
                    onSort={onSort}
                    keyField="_id"
                    customStyles={customStyles}
                    paginationPerPage={pageSize}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    // progressPending={loading}
                    sortServer={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {!loading ? (
            <></>
          ) : (
            <div className="loader-wrapper">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    companies: state.companies,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    companyActions: bindActionCreators(companyActions, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

// export default UserManagement;
