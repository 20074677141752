import React from "react";
import { Link } from "react-router-dom";
const SkillTableDetail = ({ data }) => {
  const getDurationString = (d) => {
    let x = d.toString().split(".");
    x[0] = parseInt(x[0]);
    x[1] = parseInt(x[1].slice(0, 2)) / 100;
    let durationString = x[0] + " hrs " + (x[1] * 60).toFixed(0) + " mins";
    return durationString;
  };

  let tableData = [];
  for (const [key, value] of Object.entries(data)) {
    for (let i = 0; i < value.length; i++) {
      tableData.push(
        <tr>
          <td>{key}</td>
          <td className="text-center">
            {new Date(value[i].updatedAt).toLocaleDateString() +
              " - " +
              new Date(value[i].updatedAt).toLocaleTimeString()}
          </td>
          <td className="text-center">
            {(value[i].averageCompetencyScore * 100).toFixed(0)} %
          </td>
          <td>{getDurationString(value[i].duration)}</td>
        </tr>
      );
    }
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Competency</th>
            <th className="text-center">Date / Time</th>
            <th className="text-center">Score</th>
            <th className="text-center">Duration</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
          {/* {data &&
            data.map((item) => (
              <tr>
                <td>{item.competencyName}</td>
                <td className="text-center">
                  {new Date(item.updatedAt).toLocaleDateString()}
                </td>
                <td className="text-center">
                  {(item.averageCompetencyScore * 100).toFixed(0)} %
                </td>
                <td>{getDurationString(item.duration)}</td>
              </tr>
            ))} */}
        </tbody>
      </table>
    </>
  );
};

export default SkillTableDetail;
