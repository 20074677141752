import React, { useState, useEffect } from "react";
import Autocomplete from "react-autocomplete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as companyActions from "../../../action/companyActions";
import { case_insensitive_search } from "../../../common/utilityFunctions";

let SearchCompany = (props) => {
  let [companies, setCompanies] = useState([]);
  let [selectedCompany, selectCompany] = useState({});
  let [searchString, setSearchString] = useState(props.initialValue || "");

  useEffect(() => {
    props.companyActions.getAllCompanies(props.token);
  }, []);

  const getCompanies = (searchString) => {
    setCompanies(
      props.companyList.filter((x) =>
        case_insensitive_search(x.companyName, searchString)
      )
    );
  };
  return (
    <div
      className={`mainSearchBar mb-4 ${props.className}`}
      style={{ zIndex: 10, textAlign: "left" }}
    >
      <Autocomplete
        inputProps={{
          id: "daycare-autocomplete",
          className: "form-control",
          placeholder: "Search companies...",
        }}
        value={searchString}
        items={props.companyList}
        getItemValue={(item) => item.companyName}
        onSelect={(value, item) => {
          // set the menu to only the selected item
          selectCompany(item);
          setSearchString(value);
          //   this.setState({ selectedDayCare:item,searchString:value })
          props.onItemSelected(item);
          // or you could reset it to a default list again
          // this.setState({ unitedStates: getStates() })
        }}
        onChange={(event, value) => {
          setSearchString(value);
          getCompanies(value);
        }}
        renderItem={(item, isHighlighted) => (
          <div
            className={`item ${isHighlighted ? "item-highlighted" : ""} ml-2 `}
            key={item._id}
          >
            <span className="text-center">{item.companyName}</span>{" "}
          </div>
        )}
      ></Autocomplete>
      <i className="fa fa-search"></i>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    companyActions: bindActionCreators(companyActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    companiesList: state.companies ? state.companies.companiesList : [],
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchCompany);
