import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import Home from "./View/Home";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import Signin from "./View/Authentication/Signin";
import ProtectedRoute from "./components/ProtectedRoute";
import { ThemeContext, themes } from "./components/Theam";
import { connect } from "react-redux";
import Page404 from "./View/pages/Page404/Page404";
import "./App.css";
import "./View/Home/Deshbord.css";
function App() {
  const getBasename = (path) => path.substr(0, path.lastIndexOf("/"));

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/signin" render={(props) => <Signin {...props} />} />
        <Route
          exact
          path="/404"
          name="Page 404"
          render={(props) => <Page404 {...props} />}
        />

        <ProtectedRoute path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    companyDefaults: state.companyDefaults,
  };
};

export default connect(mapStateToProps)(App);
