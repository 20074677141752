import React, { useEffect, useState } from "react";

import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import Ellipse9 from "../../../images/Ellipse9.png";
import camera from "../../../images/camera.svg";
import eye from "../../../images/eye-regular.svg";
import chartSix from "../../../images/chartsix.jpg";
import { loadState } from "../../../store/LocalStorage";
import * as userdashboardActions from "../../../action/userdashboardActions";
import { get } from "lodash";
import UserTable from "../../../View/pages/Modules/UserTable";
import SkillTable from "../../../View/pages/Skills/SkillTable";
import { AreaChart, HorizontalBarChart } from "../../../common/chart";
import AreaChart2 from "../../../common/chart/areaChart2";

var profileBackgroundStyle = {
  width: "100%",
  backgroundImage: `url(${Background})`,
};

const ReportUserpage = (props) => {
  const { loggedIn, user, token } = get(loadState(), "auth") || {};
  const [allModules, setAllModules] = useState([]);
  const [allCompetencies, setAllCompetencies] = useState([]);
  const allCompetenciesScore = [];
  const allModulessScore = [];
  const allCompetenciesScoreTotal = [];
  const allModulessScoreTotal = [];
  useEffect(() => {
    // loadUserAverageScore();
    // loadUserModulesStatus();
    userdashboardActions.getAllModulesWithScore().then((res) => {
      setAllModules(res.data);
      // allModules=res.data
    });
    userdashboardActions.getAllCompetenciesWithScore().then((res) => {
      setAllCompetencies(res.data);
    });
  }, []);

  allCompetencies.forEach((element) => {
    let monthS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let s = {
      count: element.score.split("%")[0],
      year: new Date().getFullYear(),
      month: monthS[Math.floor(Math.random() * 11) + 0],
    };

    allCompetenciesScore.push(s);
  });

  allModules.forEach((element) => {
    let monthS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let s = {
      count: element.score.split("%")[0],
      year: new Date().getFullYear(),
      month: monthS[Math.floor(Math.random() * 11) + 0],
    };
    allModulessScore.push(s);
  });

  return (
    <>
      <div class="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area">
            <div className="d-flex justify-content-between align-items-center">
              <div className="sec1 logo d-block d-lg-none">
                <a href="/">
                  <img src={EldcLogo} alt="" />
                </a>
              </div>

              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div className="d-flex align-items-center">
              <h2 className="right_hading d-none d-md-inline">Reports</h2>
              <form action="" className="search m-auto">
                <input type="text" placeholder="Search..." />
              </form>
              <a href="#" class="adcm-btn Cancel ms-2">
                {" "}
                Cancel
              </a>
            </div>

            <h2 className="right_hading d-md-none d-block">User Reports</h2>

            <div class="row">
              <div class="col-lg-12">
                <div class="company-main user-report">
                  <form action="">
                    <div
                      className="company-profile"
                      style={profileBackgroundStyle}
                    >
                      {/* <a href="#" class="profile-changer"> <img class="me-1" src="assets/images/link.svg" alt=""> Change Image</a>  */}
                    </div>
                    <div class="company-fillout">
                      <div class="circle-outer">
                        <div class="circle">
                          <img
                            class="profile-pic"
                            src={user.profilePic.Location}
                            alt=""
                          />
                        </div>
                        <div class="p-image">
                          <img class="upload-button" src={camera} alt="" />
                          {/* <!-- <i class="fa fa-camera upload-button"></i> --> */}
                          <input
                            class="file-upload"
                            type="file"
                            accept="image/*"
                          />
                        </div>
                      </div>
                      <div class="row align-items-end">
                        <div class="col-md-12">
                          <div class="form-group mb-0">
                            <ul>
                              <li>
                                <span>Name</span> {user.name}
                              </li>
                              <li>
                                <span> Username</span> {user.employeeId}{" "}
                              </li>
                              <li>
                                <span> Email</span> {user.emailAddress}
                              </li>
                              <li>
                                <span>Department</span> {user.role}{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2 class="right_hading">Colostomy Care</h2>

                    <div class="col-lg-12">
                      <nav class="colostomy">
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                          <a
                            class="nav-link active"
                            id="an-report-tab"
                            data-bs-toggle="tab"
                            href="#an-report"
                            role="tab"
                            aria-controls="nav-an-report"
                            aria-selected="true"
                          >
                            {" "}
                            Competency Details
                          </a>
                          <a
                            class="nav-link"
                            id="q-rslt-tab"
                            data-bs-toggle="tab"
                            href="#q-rslt"
                            role="tab"
                            aria-controls="nav-q-rslt"
                            aria-selected="false"
                          >
                            {" "}
                            Module Details
                          </a>
                        </div>
                      </nav>

                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="an-report"
                          role="tabpanel"
                          aria-labelledby="an-report-tab"
                        >
                          <div class="sec2">
                            <form action="">
                              <div class="sec2-header d-flex justify-content-between">
                                <h2>Competency Details</h2>
                                <div class="dropdopwn-area-double">
                                  <div class="dropdown ">
                                    <button
                                      class="btn dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      Select Competency
                                    </button>
                                    <ul
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          Action
                                        </a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          Another action
                                        </a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          Something else here
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="dropdown">
                                    <button
                                      class="btn dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      Select Duration
                                    </button>
                                    <ul
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton2"
                                    >
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          Action
                                        </a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          Another action
                                        </a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" href="#">
                                          Something else here
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* <img src={chartSix} alt=""/> */}
                              <AreaChart
                                data={allCompetenciesScore}
                                dataKey="count"
                                axisLine={false}
                                width={1000}
                                height={300}
                                nameKey="month"
                              />
                            </form>

                            <br />

                            <div class="col-xl-12">
                              <div class="modular-table big-table table-responsive">
                                {/* <table class="table align-middle">
                                            <thead>
                                                <tr>
                                                  <th scope="col">Lesson Name 1</th>
                                                  <th scope="col" class="text-center">Date / Time</th>
                                                  <th scope="col" class="text-center">Score</th>
                                                  <th scope="col" class="text-center">Duration</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                    <td>Patient Preparation</td>
                                                    <td class="text-center">15 - 09 - 22 | 12:24:34</td>
                                                    <td class="text-center"> 89%</td>
                                                    <td class="text-center">4 min 32 secs</td> </tr>
                                                  <tr>
                                                    <td>Changing Colostomy Bag</td>
                                                    <td class="text-center">15 - 09 - 22 | 12:24:34</td>
                                                    <td class="text-center">72%</td>
                                                    <td class="text-center">3 min 12 secs</td>
                                                </tr>
                                                <tr>
                                                    <td>Patient Preparation</td>
                                                    <td class="text-center">15 - 09 - 22 | 12:24:34</td>
                                                    <td class="text-center">90%</td>
                                                    <td class="text-center">4 min 18 secs</td>
                                                </tr>
                                                <tr>
                                                    <td>Changing Colostomy Bag</td>
                                                    <td class="text-center">15 - 09 - 22 | 12:24:34</td>
                                                    <td class="text-center">68%</td>
                                                    <td class="text-center">5 min 10 secs</td>
                                                </tr>
                                              </tbody>
                                        </table> */}
                                <SkillTable data={allCompetencies} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="q-rslt"
                          role="tabpanel"
                          aria-labelledby="q-rslt-tab"
                        >
                          <div class="sec2">
                            <form action="">
                              <div class="sec2-header">
                                <h2>Module Details</h2>
                                <div class="dropdown ">
                                  <button
                                    class="btn dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton3"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Select Duration
                                  </button>
                                  <ul
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton3"
                                  >
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        Action
                                      </a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        Another action
                                      </a>
                                    </li>
                                    <li>
                                      <a class="dropdown-item" href="#">
                                        Something else here
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {/* <img src={chartSix} alt=""/> */}

                              <AreaChart
                                data={allModulessScore}
                                dataKey="count"
                                axisLine={false}
                                width={1000}
                                height={300}
                                nameKey="month"
                              />
                            </form>
                            <br />

                            <div class="col-sm-12">
                              <div class="modular-table big-table table-responsive">
                                <UserTable data={allModules} />

                                {/* <table class="table align-middle">
                                            <thead>
                                                <tr>
                                                  <th scope="col">Lesson Name 2</th>
                                                  <th scope="col" class="text-center">Date / Time</th>
                                                  <th scope="col" class="text-center">Score</th>
                                                  <th scope="col" class="text-center">Duration</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                    <td>Patient Preparation</td>
                                                    <td class="text-center">15 - 09 - 22 | 12:24:34</td>
                                                    <td class="text-center"> 89%</td>
                                                    <td class="text-center">4 min 32 secs</td> </tr>
                                                  <tr>
                                                    <td>Changing Colostomy Bag</td>
                                                    <td class="text-center">15 - 09 - 22 | 12:24:34</td>
                                                    <td class="text-center">72%</td>
                                                    <td class="text-center">3 min 12 secs</td>
                                                </tr>
                                                <tr>
                                                    <td>Patient Preparation</td>
                                                    <td class="text-center">15 - 09 - 22 | 12:24:34</td>
                                                    <td class="text-center">90%</td>
                                                    <td class="text-center">4 min 18 secs</td>
                                                </tr>
                                                <tr>
                                                    <td>Changing Colostomy Bag</td>
                                                    <td class="text-center">15 - 09 - 22 | 12:24:34</td>
                                                    <td class="text-center">68%</td>
                                                    <td class="text-center">5 min 10 secs</td>
                                                </tr>
                                              </tbody>
                                        </table> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportUserpage;
