import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
//const NoPhotoImage = '/img/no-photo-icon.jpg'
import { getInstance } from "../action/axiosFactory";
import { rest } from "lodash";
import axios from "axios";
const axiosInstance = getInstance();

export const RenderAwsFileField = ({
  input: { name, value, onChange },
  label,
  uploadlabel,
  type,
  meta: { touched, error, warning },
  token,
  cssClass,
  imageWidth,
}) => {
  let [progress, setProgress] = useState(null);
  let fileSize = 0;
  let [key, setkey] = useState("");
  let [Key, setKey] = useState("");

  const onUpload = (event) => {
    //  onChange(event.target.files[0]);
    fileSize = event.target.files[0] && event.target.files[0].size;
    let key = event.target.files[0] && event.target.files[0].name;
    let Key = event.target.files[0] && event.target.files[0].name;
    setKey(Key);
    setkey(key);
    uploadFiles(event.target.files[0]);
  };

  function uploadFile(file, signedRequest) {
    return axios.put(signedRequest, file, {
      onUploadProgress: function (progressEvent) {
        let progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress.toPrecision(3));
      },
    });
  }
  const uploadFiles = (file) => {
    let { name, type } = file;

    return new Promise(function (resolve, reject) {
      axiosInstance
        .post(
          "/file/getUploadSignedUrl",
          { fileName: name, fileType: type },
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          let { signedRequest, url } = response.data;

          uploadFile(file, signedRequest)
            .then(function (result) {
              onChange({
                Location: url,
                fileSize: fileSize,
                key: name,
                Key: name,
              });
              setProgress(null);
              // onUploadSuccess(response.data.path);
              resolve(url);
            })
            .catch((err) => {
              reject(err);
            });

          //let image = { publicId: path.public_id, imageUrl: path.path }
        })
        .catch((err) => {
          error = err.message;
          reject(err);
        });
    });
  };
  return (
    <div className={touched && error ? "error-border" : ""}>
      <label> {key} </label>

      <div className="fileUpload" role="button">
        {/* <button 
          for={name}
          type="button"
          className="btn themesecondarybackground fileUpload"
        >
          {uploadlabel || 'Browse'}
        </button> */}
        <input
          name={name}
          onChange={onUpload}
          id={name}
          type="file"
          className="custom-file-input"
        ></input>
        <span className="custom-file-label">Choose file...</span>
      </div>

      {progress && (
        <div>
          <progress value={progress} max="100">
            {" "}
          </progress>
          <label>{progress}%</label>
        </div>
      )}
      {/* {touched &&
        ((error && <span className="is-danger">{error}</span>) ||
          (warning && <span>{warning}</span>))} */}
    </div>
  );
};

RenderAwsFileField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  uploadlabel: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
  onUploadSuccess: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.string,
  warning: PropTypes.string,
  token: PropTypes.string,
  cssClass: PropTypes.string,
};

export const RenderImageField = ({
  input: { name, value, onChange },
  label,
  uploadLabel,
  type,
  meta: { touched, error, warning },
  token,
  cssClass,
  imageWidth,
  isPlaceHolderImage = true,
  className,
  fileType,
}) => {
  let [progress, setProgress] = useState(null);
  let fileSize;
  let [fileName, setFileName] = useState(null);
  const onUpload = (event) => {
    //  onChange(event.target.files[0]);
    fileSize = event.target.files[0] && event.target.files[0].size;
    setFileName(
      (fileSize = event.target.files[0] && event.target.files[0].name)
    );
    uploadFiles(event.target.files[0]);
  };
  const uploadFiles = (file) => {
    let formData = new FormData();
    formData.append("image", file);
    return new Promise(function (resolve, reject) {
      axiosInstance
        .post("/file/uploadfile", formData, {
          headers: {
            "Content-Type": "image/*",
            authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            setProgress((progressEvent.loaded / progressEvent.total) * 100);
          },
        })
        .then((response) => {
          let file = response.data;
          file.fileSize = fileSize;
          //let image = { publicId: path.public_id, imageUrl: path.path }
          onChange(file);
          setProgress(null);
          // onUploadSuccess(response.data.path);
          resolve(response.data);
        })
        .catch((err) => {
          error = err.message;
          reject(err);
        });
    });
  };
  return (
    <div
      className={`fileFilledViewer d-block ${className} ${
        touched && error ? "error-border" : ""
      }`}
    >
      <label> {fileName} </label>
      <div className="uploadImageSection mb-2">
        {isPlaceHolderImage && (
          <div className={!value ? "fileDemoArea" : ""}>
            <i className="fa fa-user"></i>
            <i className="fa fa-camera pr-1 mr-2"></i>
            {value && value.Location && (
              <img
                style={{ maxWidth: "300px" }}
                src={value && value.Location}
                alt={name}
              />
            )}
          </div>
        )}
        <div className="fileUpload ml-2" role="button">
          <button
            type="button"
            className="btn themesecondarybackground fileUpload"
          >
            {uploadLabel || "Upload"}
          </button>
          <input
            name={name}
            onChange={onUpload}
            id={name}
            accept={fileType || ".jpg, .jpeg, .png"}
            type="file"
            style={{ opacity: 0 }}
          ></input>
        </div>
      </div>

      {progress && (
        <div>
          <progress value={progress} max="100"></progress>
          <label>{progress}%</label>
        </div>
      )}
    </div>
  );
};

RenderImageField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  uploadlabel: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
  onUploadSuccess: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.string,
  warning: PropTypes.string,
  token: PropTypes.string,
  cssClass: PropTypes.string,
};

export const renderCheckBoxField = ({
  input,
  label,
  meta: { touched, error, warning },
  onError,
}) => {
  const changeValue = (event) => {
    input.onChange(event.target.checked);
  };
  return (
    <>
      <input
        {...input}
        placeholder={label}
        type="checkbox"
        checked={input.value ? true : false}
        className="checkboxSet"
      />
      {touched && error ? (onError ? onError(true) : null) : null}
      {touched && !error ? (onError ? onError(false) : null) : null}
    </>
  );
};
renderCheckBoxField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  meta: PropTypes.object,
};

export const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  readOnly,
}) => {
  return (
    <div className={touched && error ? "error-border" : ""}>
      <input
        className="form-control"
        {...input}
        placeholder={label}
        type={type}
        readOnly={readOnly}
      />
      {/*
      {touched &&
        ((error && <span className="is-danger">{error}</span>) ||
          (warning && <span>{warning}</span>))}
      */}
    </div>
  );
};
renderInputField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

export const renderTextAreaField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  style,
}) => (
  <div className={touched && error ? "error-border" : ""}>
    <textarea
      {...input}
      className="form-control"
      placeholder={label}
      type={type}
      rows="4"
      style={style}
    />
    {/* {touched &&
        ((error && <span className="is-danger">{error}</span>) ||
          (warning && <span>{warning}</span>))} */}
  </div>
);

renderTextAreaField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};
export const Select = (props) => {
  const renderSelectOptions = (key, index) => {
    return (
      <option key={`${key}-${index}`} value={props.options[key]}>
        {key}
      </option>
    );
  };

  if (props && props.options) {
    return (
      <select {...props.input} className="form-control">
        <option value="">Select {props.label}</option>
        {Object.keys(props.options).map(renderSelectOptions)}
      </select>
    );
  }
  return <div></div>;
};

Select.propTypes = {
  options: PropTypes.any,
  input: PropTypes.object,
  label: PropTypes.string,
};

export default Select;

export const renderRadioButtonField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  onError,
}) => {
  return (
    <span>
      <input {...input} placeholder={label} type={type} />
      {touched && error ? (onError ? onError(true) : null) : null}
      {touched && !error ? (onError ? onError(false) : null) : null}
    </span>
  );
};

renderRadioButtonField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

export const renderSelect = ({
  input,
  label,
  meta: { touched, error },
  children,
  onError,
}) => (
  <div className="field">
    <div className="control">
      <div
        className={
          "select " + (touched ? (error ? "is-danger" : "is-success") : "")
        }
      >
        <select {...input} className="form-control">
          {children}
        </select>
        {/* {touched && error && <p className="help is-danger">{error}</p>} */}
        {touched && error ? (onError ? onError(true) : null) : null}
        {touched && !error ? (onError ? onError(false) : null) : null}
      </div>
    </div>
  </div>
);

renderSelect.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  children: PropTypes.any,
  meta: PropTypes.object,
};

export const renderImageDropDown = ({
  input,
  label,
  type,
  id,
  meta: { touched, error, warning },
}) => (
  <div>
    <div id={id}></div>
    {touched &&
      ((error && <span className={id + " is-danger"}>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
);

renderImageDropDown.propTypes = {
  input: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
  id: PropTypes.string,
};
