import React, { useEffect, useState } from "react";
import { getValueByName } from "../../../common/utilityFunctions";
import * as userdashboardActions from "../../../action/userdashboardActions";
import { Link, useHistory } from "react-router-dom";
import arrowdown from "../../../images/arrow-down-left.svg";
import EldcLogo from "../../../images/MedVR_Logo.png";
import * as moduleActions from "../../../action/moduleActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "../../../action/userActions";
import { get } from "lodash";
import { loadState } from "../../../store/LocalStorage";
import { Button } from "reactstrap";
import UserTable from "./UserTable";
import { getDepartment } from "../../../action/departmentActions";
import MobileNavBar from "../../../components/mobileNavBar";
import { GrView } from 'react-icons/gr';
import { FiEdit3 } from 'react-icons/fi';

// import { toast } from 'react-toastify';

const YourModule = (props) => {
  const { handleSubmit, allModulesByCompany, token, user } = props;
  const [selectedModules, setSelectedModules] = useState([]);
  const selectModel = localStorage.getItem("selectedModule");
  const [usersMobileNos, setUsersMobileNos] = useState([]);
  const [department, setDepartment] = useState({});

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  const [selectModule, setSelectModule] = useState({});
  // selectModel === null ? undefined : JSON.parse(selectModel)

  useEffect(() => {
    const updatedModule = Object.assign(
      {},
      selectModel === null ? undefined : JSON.parse(selectModel)
    );
    console.log(1, updatedModule.modules);

    updatedModule.modules = removeDuplicates(updatedModule.modules);
    console.log(2, removeDuplicates(updatedModule.modules));
    setSelectModule(updatedModule);

    moduleActions.getModuleByUser();
  }, []);

  const { loggedIn, modules } = get(loadState(), "auth") || {};
  let auth = {
    token: token || "",
    loggedIn: loggedIn || false,
    modules: modules || [],
    user: {
      ...user,
    },
  };
  const [moduleList, setmoduleList] = useState(auth.modules);

  if (moduleList && selectModule.modules) {
    for (let i = 0; i < selectModule.modules.length; i++) {
      for (let j = 0; j < moduleList.length; j++) {
        if (selectModule.modules[i].moduleId === moduleList[j]._id) {
          selectModule.modules[i].moduleId = moduleList[j];
        }
      }
    }
  }
  let [averageScore, setAverageScore] = useState(null);
  // const [allModules, setAllModules] = useState([]);
  let [modulesStatus, setModulesStatus] = useState(null);
  let [searchTerm, setsearchTerm] = useState("");
  const [data, setData] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    props.moduleActions.getAllModulesByCompany(token, user.companyId);
    setSelectedModules(
      selectModule.modules && allModulesByCompany
        ? selectModule.modules.map(
            (assignedModule) =>
              allModulesByCompany.filter(
                (module) => module._id === assignedModule?.moduleId
              )[0]
          )
        : []
    );
    setLoading(true)
    userActions
      .getUsersMobileNo()
      .then((res) => setUsersMobileNos(res.data || []))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadUserAverageScore();
    loadUserModulesStatus();
    getDepartment(auth?.user?.departmentId[0]).then((result) =>
      setDepartment(result.data)
    );
  }, []);

  const loadUserAverageScore = () => {
    
    userdashboardActions
      .getUserAverageScore()
      .then((response) => setAverageScore(response?.data?.score))
      .catch((error) =>
        console.error(
          `failed to get user Average Score  ${error?.response?.data?.message}`
        )
      );
  };
  const loadUserModulesStatus = () => {
    userdashboardActions
      .getUserModuleStatus()
      .then((response) => setModulesStatus(response.data))
      .catch((error) =>
        console.error(
          `failed to get user modules data  ${error?.response?.data?.message}`
        )
      );
  };

  const keys = ["moduleName"];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) =>
        item?.moduleId[key]?.toLowerCase().includes(searchTerm)
      )
    );
  };

  // console.log(selectModule.modules ? search(selectModule.modules) : undefined);

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">Module Report</h2>
              <form action="" className="search">
                <input
                  type="text"
                  placeholder="search ..."
                  onChange={(event) => {
                    setsearchTerm(event?.target?.value?.toLowerCase());
                  }}
                />
              </form>
            </div>
            <h2 className="right_hading d-md-none d-block">Module Library</h2>
            {!loading ?
              <></>
              :
              <div className="loader-wrapper">
                <div className="loader"></div>

              </div>

            }
            <div className="row">
              <div className="col-xl-12">
                <div className="modular-table table-responsive-xl">
                  <table className="table align-middle-modify">
                    <thead>
                      <tr>
                        <th scope="col">Program Name</th>
                        <th scope="col" className="text-center">
                          Assign Date
                        </th>
                        <th scope="col" className="text-center">
                          Status
                        </th>
                        <th scope="col" className="text-center">
                          Last Attempt
                        </th>
                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectModule &&
                        selectModule.modules &&
                        search(selectModule.modules).map((item) => (
                          <tr key={item._id}>
                            <td>{item?.moduleId?.moduleName}</td>
                            <td className="text-center">
                              {new Date(item?.createdAt).toLocaleDateString()}
                            </td>
                            {/* <td className="text-center">{item.industry}</td> */}
                            <td className="text-center">
                              {item.status ? (
                                <label className="badge text-bg-success">Active</label>
                              ) : (
                                <label className="badge text-bg-secondary">Inactive</label>
                              )}
                            </td>
                            <td className="text-center">
                              {(item.score * 100).toFixed(0)} %
                            </td>

                            <td className="text-center">
                              <Link
                                to={`/your-module-detail/${item?.moduleId?._id}`}
                                className="primary_link">
                                
                                <GrView/>
                               
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    initialValues: state.users.selectedUser,
    token: state.auth.token,
    allModulesByCompany: state.modules.allModulesByCompany,
    module: state.modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(YourModule);
