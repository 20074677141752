import React, { useState, useEffect } from "react";
import Autocomplete from "react-autocomplete";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Ellipse9 from "../../../images/Ellipse9.png";
import { get } from "lodash";
import * as userActions from "../../../action/userActions";

let SearchUser = (props) => {
  let [users, setUsers] = useState([]);

  let [searchString, setSearchString] = useState(props.initialValue || "");

  useEffect(() => {
    // props.userActions.getAllUsers(props.token);
  }, []);

  const getUsers = (searchString) => {
    userActions
      .searchUsers(searchString)
      .then((response) => setUsers(response.data));
  };

  return (
    <div
      className="mainSearchBar w-100"
      style={{ zIndex: 10, textAlign: "left" }}
    >
      <Autocomplete
        inputProps={{
          id: "daycare-autocomplete",
          className: "form-control",
          placeholder: "Search User",
        }}
        value={searchString}
        items={users}
        getItemValue={(item) => item.name}
        onSelect={(value, item) => {
          // set the menu to only the selected item

          setSearchString(value);
          //   this.setState({ selectedDayCare:item,searchString:value })
          props.onItemSelected(item);
          // or you could reset it to a default list again
          // this.setState({ unitedStates: getStates() })
        }}
        onChange={(event, value) => {
          setSearchString(value);
          getUsers(value);
        }}
        renderItem={(item, isHighlighted) => (
          <div
            className={`item ${isHighlighted ? "item-highlighted" : ""} ml-2 `}
            key={item._id}
          >
            <div class="imgbk">
              <img
                className="profile-pic"
                src={get(item.profilePic, "Location") || Ellipse9}
                alt=""
              />
            </div>
            <div className="desc">
              <span className="text-left">{item.name}</span>
              <span className="textEmail">{item.emailAddress}</span>
            </div>
          </div>
        )}
      ></Autocomplete>
      <i className="fa fa-search"></i>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchUser);
