import { getInstance } from "./axiosFactory";

const axiosInstance = getInstance();

export const getUserSkillPerformances = () => {
  return axiosInstance.get("/dashboard/getUserSkillPerformances");
};

export const getUserModuleStatus = () => {
  return axiosInstance.get("/dashboard/getUserModuleStatus");
};

export const getUserPeerComparison = (moduleId) => {
  return axiosInstance.post("/dashboard/getUserPeerComparison", {
    moduleId: moduleId,
  });
};

export const getUserEngagementMetrices = () => {
  return axiosInstance.get("/dashboard/getUserEngagementMetrices");
};

export const getUserAverageScore = () => {
  return axiosInstance.get("/dashboard/getUserAverageScore");
};

export const getAllModulesWithScore = () => {
  return axiosInstance.get("/userresponses/getallmoduleswithscore");
};

export const getAllCompetenciesWithScore = () => {
  return axiosInstance.get("/userresponses/getallcompetencieswithscore");
};

export const getUserModulesTimeSpendByMonth = (date) => {
  return axiosInstance.get(
    `dashboard/getusermodulestimespendbymonth?date=${encodeURI(date)}`
  );
};
