import React, { useState } from "react";
import moment from "moment";

const UserTable = ({ data }) => {
  useState(() => {});

  return (
    <>
      <table className="table align-middle">
        <thead>
          <tr>
            <th scope="col">Module Name</th>
            <th scope="col" className="text-center">
              Start Date | Time
            </th>
            <th scope="col" className="text-center">
              End Date | Time
            </th>
            <th scope="col" className="text-center">
              Status
            </th>
            <th scope="col" className="text-center">
              Score %
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item._id}>
              <td>{item.moduleName}</td>
              <td className="text-center">
                {moment(item.startDate).format("DD MMM YYYY | h:mm A")}
              </td>
              <td className="text-center">
                {moment(item.endDate).format("DD MMM YYYY | h:mm A")}
              </td>
              <td className="text-center">
                {item.trainingCompletionStatus === "Completed" ? (
                  <a className="Completed">Complete</a>
                ) : (
                  <a className="incomplete">Incomplete</a>
                )}
              </td>
              <td className="text-center">{item.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default UserTable;
