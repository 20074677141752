import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import EldcLogo from "../../../images/MedVR-Logo.png";
import avatar from "../../../images/avatar.png";
import Notification from "../../../images/notifivation-Icon.png";
import overviewicon from "../../../images/overview-icon.svg";
import modulesicon from "../../../images/your-modules-icon.svg";
import modulelibrary from "../../../images/module-library.svg";
import personalsetting from "../../../images/personal-setting.svg";
import skillsicon from "../../../images/skills-icon.svg";
import report from "../../../images/report.svg";
import closecircle from "../../../images/close-circled.svg";
import GlobalSetting from "../../../images/global-setting.svg";
import UserManageicon from "../../../images/userManagementicon.svg";
import assignment from "../../../images/Vector.svg";
import bulkassignment from "../../../images/bulk-assignment.svg";
import company from "../../../images/company.svg";
import * as authActions from "../../../action/authActions";
import { saveState } from "../../../store/LocalStorage";
import $ from "jquery";
import { get } from "lodash";
import Swal from "sweetalert2";

import { connect } from "react-redux";

const Sidebar = (props) => {
  const { auth } = props;
  const history = useHistory();

  const [over, setover] = useState(false);
  const [Your, setYour] = useState(false);
  const [Skill, setSkill] = useState(false);
  const [ModuleLi, setModuleLi] = useState(false);
  const [Personal, setPersonal] = useState(false);
  const [companyMenu, setcompany] = useState(false);
  const [Global, setGlobal] = useState(false);
  const [reportMenu, setreport] = useState(false);
  const [AssignmentLi, setAssignmentLi] = useState(false);
  const [BulkassignmentLi, setBulkassignmentLi] = useState(false);

  const handleOver = () => {
    setover(true);
    setcompany(false);
    setYour(false);
    setSkill(false);
    setModuleLi(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
  };
  const handleCompany = () => {
    setcompany(true);
    setover(false);
    setYour(false);
    setSkill(false);
    setModuleLi(false);
    setPersonal(false);
    setGlobal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
  };
  const handleYour = () => {
    setYour(true);
    setcompany(false);
    setGlobal(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
  };
  const handleSkill = () => {
    setSkill(true);
    setcompany(false);
    setGlobal(false);
    setYour(false);
    setover(false);
    setModuleLi(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
  };
  const handleModule = () => {
    setModuleLi(true);
    setcompany(false);
    setGlobal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setPersonal(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
  };
  const handlePersonal = () => {
    setPersonal(true);
    setcompany(false);
    setGlobal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
  };
  const handleGlobal = () => {
    setGlobal(true);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setreport(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
  };
  const handleReport = () => {
    setreport(true);
    setGlobal(false);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setAssignmentLi(false);
    setBulkassignmentLi(false);
  };
  const handleAssignment = () => {
    setAssignmentLi(true);
    setreport(false);
    setGlobal(false);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
    setBulkassignmentLi(false);
  };
  const handleBulkAssignment = () => {
    setBulkassignmentLi(true);
    setAssignmentLi(false);
    setreport(false);
    setGlobal(false);
    setcompany(false);
    setPersonal(false);
    setYour(false);
    setover(false);
    setSkill(false);
    setModuleLi(false);
  };

  const logoutUser = () => {
    Swal.fire({
      allowOutsideClick: false,
      title: "Are you sure?",
      text: "You want to Logout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        saveState({});
        authActions.logout();
        history.push("/signin");
      }
    });
    // window.location.pathname = "/signin";
  };
  const handleNavClose = () => {
    $(".navbar-collapse").removeClass("show");
  };

  return (
    <>
      <nav id="mainNav" className="navbar navbar-expand-lg side-bar">
        <div className="collapse navbar-collapse sec2" id="navbarResponsive">
          <div className="sec1 logo d-none d-lg-block">
            <img src={EldcLogo} alt=" " style={{ width: "200px" }} />
          </div>
          <ul className="navbar-nav">
            <a href="#" className="h-close" onClick={handleNavClose}>
              <img src={closecircle} alt="" />
            </a>
            <li
              className={over ? "active nav-item" : "nav-item"}
              onClick={handleOver}
            >
              <Link to="/dashboard" className="nav-link">
                <img src={overviewicon} alt=" " />
                <span>Overview</span>
              </Link>
            </li>
            {/* <li className="active nav-item"><a href="index.html" className="nav-link"> <img src="assets/images/company.svg" alt=""> <span> Companies</span></a></li> */}

            <li
              className={Your ? "active nav-item" : "nav-item"}
              onClick={handleYour}
            >
              <Link to="/company-user-management" className="nav-link">
                <img src={UserManageicon} alt=" " />
                <span>User Management</span>
              </Link>
            </li>
            <li
              className={Skill ? "active nav-item" : "nav-item"}
              onClick={handleSkill}
            >
              <Link to="/skills" className="nav-link">
                <img src={skillsicon} alt=" " />
                <span>Skill</span>
              </Link>
            </li>
            <li
              className={ModuleLi ? "active nav-item" : "nav-item"}
              onClick={handleModule}
            >
              <Link to="/module-library" className="nav-link">
                <img src={modulelibrary} alt=" " /> <span> Module Library</span>
              </Link>
            </li>
            <li
              className={AssignmentLi ? "active nav-item" : "nav-item"}
              onClick={handleAssignment}
            >
              <Link to="/assignment" className="nav-link">
                <img src={assignment} alt=" " /> <span> Assignment</span>
              </Link>
            </li>
            <li
              className={BulkassignmentLi ? "active nav-item" : "nav-item"}
              onClick={handleBulkAssignment}
            >
              <Link to="/bulk-assignment" className="nav-link">
                <img src={bulkassignment} alt=" " />{" "}
                <span> Bulk Assignment</span>
              </Link>
            </li>
            <li
              className={reportMenu ? "active nav-item" : "nav-item"}
              onClick={handleReport}
            >
              <Link to="/report" className="nav-link">
                <img src={report} alt=" " /> <span>Report</span>
              </Link>
            </li>
            <div className="side_hading">Settings</div>
            <li
              className={Personal ? "active nav-item" : "nav-item"}
              onClick={handlePersonal}
            >
              <Link to="/profile" className="nav-link">
                <img src={personalsetting} alt=" " />{" "}
                <span> Personal Settings</span>
              </Link>
            </li>
            <li className="sec3 prfile">
              <div className="row justify-content-between">
                <div className="avatar">
                  {/* <img src={avatar} alt=" " /> */}
                  <img
                    src={get(auth, "user.profilePic.Location") || avatar}
                    alt="Profile Picture"
                  />
                </div>
                <div className="position-relative">
                  <img src={Notification} alt=" " />
                  <span>5</span>
                </div>
              </div>

              <h3>{auth.user.name}</h3>
              <span>{auth.user.role}</span>
              <p>{auth.user.emailAddress}</p>
              <a href="profile">View profile</a>
              <Link className="dropdown-item my-2" to="#" onClick={logoutUser}>
                <i className="mr-2 fa fa-power-off"></i> Logout
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Sidebar);
