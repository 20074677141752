import { get } from "lodash";
import moment from "moment";

export const case_insensitive_search = (str, search_str) => {
  return str.toLowerCase().startsWith(search_str.toLowerCase());
};

export const getDateInFormat = (dateStr, formatStr = "DD/MM/YYYY") => {
  return dateStr && moment(dateStr).format(formatStr);
};

export const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (!bytes || bytes == 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const getYoutubeVideoID = (youtubeUrl) => {
  return youtubeUrl && youtubeUrl.substr(youtubeUrl.indexOf("v=") + 2);
};

export const getVimeoVideoID = (vimeoUrl) => {
  return vimeoUrl && vimeoUrl.substr(vimeoUrl.lastIndexOf("/") + 1);
};

export function convertArrayOfObjectsToCSV(array, columns) {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = columns.map((column) => column.name);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    columns.forEach((column) => {
      if (ctr > 0) result += columnDelimiter;

      result += get(item, `${column.selector}`);

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(array, columns) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array, columns);
  if (csv == null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export const getValueByName = (arrayOfObj, name) => {
  const module = arrayOfObj && arrayOfObj.filter((x) => x.name === name);
  return module && module.length > 0 && module[0].value;
};

export const getMonthListTillDate = (date = new Date()) => {
  const monthsL = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthsS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentMonth = date.getMonth() + 1;
  const monthsTillTheDate = monthsL.splice(0, currentMonth);
  const dateISOSplit = date
    .toISOString()
    .replace(date.getDate() < 10 ? "0" + date.getDate() : date.getDate(), "15")
    .split(currentMonth < 10 ? "0" + currentMonth : currentMonth);

  return monthsTillTheDate
    .map((month, index) => {
      const monthNumber = index + 1;
      return {
        monthFullName: month,
        monthSmallName: monthsS[index],
        dateISO: dateISOSplit.join(
          monthNumber < 10 ? "0" + monthNumber : monthNumber
        ),
      };
    })
    .reverse();
};
