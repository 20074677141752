import { getInstance } from "./axiosFactory";
import { raiseError } from "./globalActions";
import { loginSuccess } from "./authActions";
const axiosInstance = getInstance();

export const getUserResponsesByModules = (token, data) => {
  return axiosInstance.post("/reports/getUserResponsesByModules", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getUserResponsesByCompetencies = (token, data) => {
  return axiosInstance.post("/reports/getUserResponsesByCompetencies", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
