export const addModuleValidator = (values) => {
    const errors = {};

    if (!values.moduleName) {
        errors.moduleName = 'Required';
    }
    if (!values.moduleDescription) {
        errors.moduleDescription = 'Required';
    }
    if (!values.moduleDemoLink) {
        errors.moduleDemoLink = 'Required';
    } else if (!(values.moduleDemoLink.includes('youtube.com') || values.moduleDemoLink.includes('vimeo.com'))) {
        errors.moduleDemoLink = 'Invalid video url';
    }
    if (!values.moduleType) {
        errors.moduleType = 'Required';
    } else if (values.moduleType === 'Please Select') {
        errors.moduleType = 'Required';
    }
    if (!values.moduleGuide) {
        errors.moduleGuide = 'Required';
    }
    if (!values.bannerImage) {
        errors.bannerImage = 'Required';
    }

    if (!values.industry) {
        errors.industry = 'Required';
    }
    if (!values.status) {
        errors.status = 'Required';
    }
    if (!(values.Windows || values.Oculus || values.HTC || values.PICO)) {
        errors.Windows = 'Required';
    }

    return errors;
}

export const addVersionValidator = (values) => {
    const errors = {};

    if (!values.versionNumber) {
        errors.versionNumber = 'Required';
    }
    if (!values.fileSize) {
        errors.fileSize = 'Required';
    }
    if (!values.modulePackage) {
        errors.modulePackage = 'Required';
    }

    return errors;
}

export const addAssignCompanyValidator = (values) => {
    const errors = {};

    if (!values.companyId) {
        errors.companyId = 'Required';
    }

    return errors;
}