import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Multiselect } from "react-widgets";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import * as moduleActions from "../../../action/moduleActions";
import * as reportAction from "../../../action/reportActions";
import * as departmentActions from "../../../action/departmentActions";
import { BsEyeFill } from "react-icons/bs";
import {
  renderInputField,
  renderRadioButtonField,
} from "../../../common/InputFields";
import DataGrid from "../../../common/dataGrid";
import { get } from "lodash";
import { reportFilterValidator } from "./ReportValidators";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { downloadCSV } from "../../../common/utilityFunctions";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "react-widgets/styles.css";
import * as userActions from "../../../action/userActions";
import axios from "axios";
import { CSVLink, CSVDownload } from "react-csv";
import { getBulkReport } from "../../../action/moduleActions";
import moment from "moment";
import { getDepartment } from "../../../action/departmentActions";
import MobileNavBar from "../../../components/mobileNavBar";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

let multiSelectedData = [];
let multiSelectData = [];
const genericColumns = [
  { name: "User Name", selector: "userName", sortable: true },
  { name: "Email", selector: "email", sortable: true },
];

let ReportPage = (props) => {
  const {
    auth,
    moduleList,
    handleSubmit,
    reportpage,
    user,
    allUsersByCompany = [],
  } = props || {};
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([...genericColumns]);
  const [loading, setLoading] = useState(false);
  const [filterTypeErrorCss, setFilterTypeErrorCss] = useState(null);
  const [searchIds, setSearchIds] = useState([]);

  //Module or Department search
  const [filterModule, setfilterModule] = useState(true);
  const [filterDepartment, setfilterDepartment] = useState(false);
  const [filterCourse, setfilterCourse] = useState(false);
  const [filterUser, setfilterUser] = useState(false);
  let [allDepartments, setAllDepartments] = useState([]);
  let [allCourses, setAllCourses] = useState([]);
  let [allUserCourses, setAllUserCourses] = useState([]);
  const [Header, setHeader] = useState("Module");
  const [report, setReport] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [showResult, setshowResult] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [department, setDepartment] = useState({});
  const [reportType, setReportType] = useState("Training");
  const [resultType, setResultType] = useState();
  const [sameval, setSameval] = useState("");
  const [exportStepsData, setExportStepsData] = useState([]);
  const [searchUserVal, setSearchUserVal] = useState("");

  useEffect(() => {
    setLoading(true);
    getDepartment(auth.user.departmentId[0]).then((result) => {
      setDepartment(result.data);
      setLoading(false);
    });
  }, []);

  const getDurationString = (d) => {
    if (d === null || typeof d === "undefined" || d === 0) {
      return "";
    }
    let x = d?.toString().split(".");
    x[0] = parseInt(x[0]);
    x[1] = parseInt(x[1].slice(0, 2)) / 100;
    let durationString = x[0] + " hrs " + (x[1] * 60).toFixed(0) + " mins";
    return durationString;
  };

  const getReport = () => {
    setshowResult(false);
    setLoading(true);
    setResultType(reportType);
    if (searchIds.length == 0 || fromDate === "" || toDate === "") {
      if (toDate === "" || fromDate === "") {
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(`Please select a date range !`)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
      if (searchIds.length == 0) {
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(`Please select a ${Header} !`)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
      setLoading(false);
    } else {
      getBulkReport({
        search: filterModule
          ? "module"
          : filterDepartment
          ? "department"
          : filterCourse
          ? "course"
          : "user",
        reportType: reportType,
        searchIds: searchIds,
        from: new Date(fromDate),
        to: new Date(toDate),
      }).then((result) => {
        setReport(result.data);

        let data = [];

        if (reportType === "Assessment" && filterUser == false) {
          result.data?.map((item, index) => {
            let rd = {};
            rd.moduleName = item.moduleId?.moduleName;
            rd.course = item.userId?.employeeId;
            rd.user = item.userId?.name;
            rd.userEmail = item.userId?.emailAddress;
            rd.attemptedAt = item.updatedAt
              ? new Date(item.updatedAt).toLocaleDateString() +
                " - " +
                new Date(item.updatedAt).toLocaleTimeString()
              : new Date(item.createdAt).toLocaleDateString() +
                " - " +
                new Date(item.updatedAt).toLocaleTimeString();
            rd.comptencyName = item.competencyName;
            rd.competencyScore = `${(item.averageCompetencyScore * 100).toFixed(
              0
            )} %`;
            rd.noOfAttempts = item.competencyAttempts;
            if (item.duration) {
              rd.competencyDuration = getDurationString(item.duration);
            }
            data.push(rd);
          });
        } else if (reportType === "Training" && filterUser == false) {
          result.data?.map((item, index) => {
            let rd = {};
            rd.moduleName = item.moduleId?.moduleName;
            rd.course = item.userId?.employeeId;
            rd.user = item.userId?.name;
            rd.userEmail = item.userId?.emailAddress;
            rd.attemptedAt = item.updatedAt
              ? new Date(item.updatedAt).toLocaleDateString() +
                " - " +
                new Date(item.updatedAt).toLocaleTimeString()
              : new Date(item.createdAt).toLocaleDateString() +
                " - " +
                new Date(item.updatedAt).toLocaleTimeString();
            rd.comptencyName = item.competencyName || "N/A";
            if (item.duration) {
              rd.competencyDuration = getDurationString(item.duration);
            }
            data.push(rd);
          });
        }

        setReportData(data);
        setshowResult(true);
        setLoading(false);
      });
    }
  };

  let tableData = [];
  if (report.length !== 0) {
    report?.map((item, index) => {
      if (resultType === "Assessment") {
        tableData.push(
          <tr>
            <td>{item.moduleId?.moduleName}</td>
            <td>{item.userId?.employeeId}</td>
            <td className="text-center">{item.userId?.name}</td>
            <td className="text-center">{item.userId?.emailAddress}</td>
            <td className="text-center">
              {item.updatedAt
                ? new Date(item.updatedAt).toLocaleDateString() +
                  " - " +
                  new Date(item.updatedAt).toLocaleTimeString()
                : new Date(item.createdAt).toLocaleDateString() +
                  " - " +
                  new Date(item.updatedAt).toLocaleTimeString()}
            </td>
            <td>{item.competencyName}</td>
            <td>{(item.averageCompetencyScore * 100).toFixed(0)} %</td>
            <td>{item.duration && getDurationString(item.duration)}</td>
            <td>{item.competencyAttempts}</td>
            <td>
              <button
                className="adcm-btn search"
                style={{ margin: 0 }}
                onClick={() => exportToExcel(item._id)}
              >
                Export Data
              </button>
            </td>
          </tr>
        );
      }
      if (resultType === "Training") {
        tableData.push(
          <tr>
            <td>{item.moduleId?.moduleName}</td>
            <td>{item.userId?.employeeId}</td>
            <td className="text-center">{item.userId?.name}</td>
            <td className="text-center">{item.userId?.emailAddress}</td>
            <td className="text-center">
              {item.updatedAt
                ? new Date(item.updatedAt).toLocaleDateString() +
                  " - " +
                  new Date(item.updatedAt).toLocaleTimeString()
                : new Date(item.createdAt).toLocaleDateString() +
                  " - " +
                  new Date(item.updatedAt).toLocaleTimeString()}
            </td>
            <td>
              {item.competencyName ||
                item.debriefingData?.competencyResponse?.competencyName}
            </td>{" "}
            <td>{item.duration && getDurationString(item.duration)}</td>
            <td>{item.debriefingData?.attempt}</td>
            <td>
              <button
                className="adcm-btn search"
                style={{ margin: 0 }}
                onClick={() => exportToExcel(item._id)}
              >
                Export Data
              </button>
            </td>
          </tr>
        );
      }
    });
  } else {
    tableData.push(
      <tr>
        <td
          colspan="8"
          style={{
            textAlign: "center",
            height: "300px",
            verticalAlign: "middle",
          }}
        >
          <h2>No data found !</h2>
        </td>
      </tr>
    );
  }

  const exportToExcel = (modid) => {
    const workbook = XLSX.utils.book_new();
    const debriefinData = report
      .filter((item) => item._id === modid)
      .map((item) => {
        const debriefdata = {
          CompetencyName:
            item.debriefingData?.competencyResponse?.competencyName,
          ModuleName: item.moduleId?.moduleName,
          UserName: item.userId?.name,
          UserEmail: item.userId?.emailAddress,
          EventTime: item.debriefingData?.eventTime
            ? `${new Date(
                item.debriefingData?.eventTime
              ).toLocaleDateString()} - ${new Date(
                item.debriefingData?.eventTime
              ).toLocaleTimeString()}`
            : "N/A",
        };
        return debriefdata;
      })
      .flat();

    const debriefinDataSheet = XLSX.utils.json_to_sheet(debriefinData, {
      header: [
        "CompetencyName",
        "ModuleName",
        "UserName",
        "UserEmail",
        "EventTime",
      ],
    });

    XLSX.utils.book_append_sheet(
      workbook,
      debriefinDataSheet,
      "Debriefing Details"
    );

    // Sheet 2: Steps with correctAction true
    const correctActionData = report
      .filter((item) => item._id === modid)
      .map((item) => {
        if (item.debriefingData?.steps?.length > 0) {
          const steps = item.debriefingData.steps
            .filter((step) => step.correctAction)
            .map((step) => ({
              Name: step.name || "N/A",
              Action: step.action || "N/A",
              Timestamp: step.timestamp || "N/A",
            }));
          return steps;
        } else {
          return [];
        }
      })
      .flat();

    const correctActionSheet = XLSX.utils.json_to_sheet(correctActionData, {
      header: ["Name", "Action", "Timestamp"],
    });

    XLSX.utils.book_append_sheet(workbook, correctActionSheet, "Correct Steps");

    // Sheet 3: Steps with correctAction false
    const incorrectActionData = report
      .filter((item) => item._id === modid)
      .map((item) => {
        if (item.debriefingData?.steps?.length > 0) {
          const steps = item.debriefingData.steps
            .filter((step) => !step.correctAction)
            .map((step) => ({
              Name: step.name || "N/A",
              Action: step.action || "N/A",
              Timestamp: step.timestamp || "N/A",
            }));
          return steps;
        } else {
          return [];
        }
      })
      .flat();

    const incorrectActionSheet = XLSX.utils.json_to_sheet(incorrectActionData, {
      header: ["Name", "Action", "Timestamp"],
    });

    XLSX.utils.book_append_sheet(
      workbook,
      incorrectActionSheet,
      "Incorrect Steps"
    );

    // Sheet 4: Patient Assessment data
    const patientAssessmentData = report
      .filter((item) => item._id === modid)
      .map((item) => {
        if (item.debriefingData?.patientAssesment?.length > 0) {
          const assessments = item.debriefingData.patientAssesment.map(
            (assessment) => ({
              Name: assessment.name || "N/A",
              Question: assessment.question || "N/A",
              Answer: assessment.answer || "N/A",
              Timestamp: assessment.timestamp || "N/A",
            })
          );
          return assessments;
        } else {
          return [];
        }
      })
      .flat();

    const patientAssessmentSheet = XLSX.utils.json_to_sheet(
      patientAssessmentData,
      {
        header: ["Name", "Question", "Answer", "Timestamp"],
      }
    );

    XLSX.utils.book_append_sheet(
      workbook,
      patientAssessmentSheet,
      "Patient Assessment"
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(data, "Debriefing_Data.xlsx");
  };

  useEffect(() => {
    props.moduleActions.getAllModulesByCompany(auth.token, user.companyId);
    // if (user.role === "Manager") {
    //   userActions
    //     .getCourses(user._id)
    //     .then((result) => setAllCourses(result.data));
    // } else {
    //   userActions.getCourses().then((result) => setAllCourses(result.data));
    // }

    if (user.role === "Manager") {
      userActions.getModules().then((result) => setAllCourses(result.data));
      userActions.getCourses().then((result) => setAllUserCourses(result.data));
    } else {
      userActions.getModules().then((result) => {
        setAllCourses(result.data);
      });
      userActions.getCourses().then((result) => setAllUserCourses(result.data));
    }
    loadAllDepartments();
  }, []);

  const loadAllDepartments = () => {
    departmentActions
      .getAllDepartments()
      .then((response) => {
        setAllDepartments(response.data);
        if (user.role == "Manager") {
          let updatedDepartments = [];
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < user.departmentId.length; j++) {
              if (user.departmentId[j] == response.data[i]._id) {
                updatedDepartments.push(response.data[i]);
              }
            }
          }
          setAllDepartments(updatedDepartments);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(error)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        // setLoading(false);
      });
  };

  // multiSelectData = get(reportpage, 'filterType', '') === 'competency' ?
  multiSelectData = filterDepartment
    ? // auth.modules && auth.modules.length > 0 && [].concat(...auth.modules.map(x => x.competencies)) :
      allDepartments &&
      allDepartments.length > 0 &&
      allDepartments.map((item) => ({
        _id: item._id,
        moduleName: item.departmentName,
      }))
    : filterModule
    ? allCourses &&
      allCourses.length > 0 &&
      allCourses.map((item) => ({
        _id: item._id,
        moduleName: item.moduleName,
      }))
    : filterCourse
    ? allUserCourses &&
      allUserCourses.length > 0 &&
      allUserCourses.map((item) => ({
        _id: item._id,
        moduleName: item.courseName,
      }))
    : allUsersByCompany &&
      allUsersByCompany.length > 0 &&
      allUsersByCompany.map((item) => ({
        _id: item._id,
        moduleName: item.name,
      }));

  const onChange = (event) => {
    let updatedId = [];
    for (let i = 0; i < event.length; i++) {
      updatedId.push(event[i]._id);
    }
    setSearchIds(updatedId);
  };

  const onUserSearch = (event) => {
    if (event) {
      setSearchUserVal(event);
    }
  };

  const searchUser = () => {
    toggle();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  };

  let [searchTerm, setsearchTerm] = useState("");
  let [usersAll, setUsersAll] = useState([]);
  let [searchUsers, setSearchUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const inputRef = useRef(null);

  let userSelectData = [];

  userSelectData = usersAll.map((item) => {
    return {
      _id: item._id,
      moduleName: item.name,
    };
  });

  const searchUsermod = () => {
    setLoading(true);
    let userAllData = [];

    usersAll.forEach((user) => {
      searchIds.forEach((sUser) => {
        if (user._id == sUser) {
          userAllData.push(user);
        }
      });
    });

    userActions
      .searchUsers(searchTerm)
      .then((response) => {
        // setUsersAll(response.data);
        response.data.forEach((user) => {
          userAllData.push(user);
        });

        setUsersAll(userAllData);
      })
      .finally(() => setLoading(false));
  };

  const selectUserSearch = (user) => {
    let searchIdList = searchIds;
    let searchUserList = searchUsers;

    if (searchIdList.includes(user._id)) {
      const index = searchIdList.indexOf(user._id);
      if (index > -1) {
        searchIdList.splice(index, 1);
        searchUserList.splice(index, 1);
      }
    } else {
      searchIdList.push(user._id);
      searchUserList.push({
        _id: user._id,
        moduleName: user.name,
      });
    }

    setSearchIds(searchIdList);
    setSearchUsers(searchUserList);

    let userDta = usersAll;
    setUsersAll([]);
    setUsersAll(userDta);
    toggle();
  };

  function HasUserIdTag({ user, searchIds }) {
    if (!searchIds.includes(user._id)) {
      return (
        <button
          className="btn secondary-btn btn-sm m-1"
          onClick={() => selectUserSearch(user)}
        >
          Add
        </button>
      );
    } else {
      return (
        <button
          className="btn third-btn btn-sm m-1"
          onClick={() => selectUserSearch(user)}
          style={{ color: "#fff" }}
        >
          Remove
        </button>
      );
    }
  }

  const filterModulefun = () => {
    setfilterModule(true);
    setfilterDepartment(false);
    setfilterCourse(false);
    setfilterUser(false);
    setHeader("Module");
  };

  const filterDepartmentfun = () => {
    setfilterModule(false);
    setfilterDepartment(true);
    setfilterCourse(false);
    setfilterUser(false);
    setHeader("Department");
  };

  const filterCoursefun = () => {
    setfilterModule(false);
    setfilterDepartment(false);
    setfilterUser(false);
    setfilterCourse(true);
    setHeader("Course");
  };

  const filterUserfun = () => {
    setfilterModule(false);
    setfilterDepartment(false);
    setfilterCourse(false);
    setfilterUser(true);
    setHeader("User");

    setSearchIds([]);
    setSearchUsers([]);
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="right_hading d-none d-sm-inline">Reports</h2>
              <div className="mt-3">
                <a>
                  <Link to="/" className="adcm-btn Cancel ms-1">
                    <span>Cancel</span>
                  </Link>
                </a>
              </div>
            </div>
            <h2 className="right_hading d-sm-none d-block">Reports</h2>
            <div className="row">
              <div className="col-lg-12">
                <div className="company-main report">
                  <div className="company-fillout">
                    <h3 className="fill-heaing">Data Selection</h3>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select Report Type</label>
                          <ul>
                            <li>
                              <a
                                className={
                                  reportType === "Training"
                                    ? "data-btn active"
                                    : "data-btn"
                                }
                                onClick={() => setReportType("Training")}
                              >
                                Training
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  reportType === "Assessment"
                                    ? "data-btn active"
                                    : "data-btn"
                                }
                                onClick={() => setReportType("Assessment")}
                              >
                                Assessment
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {!loading ? (
                        <></>
                      ) : (
                        <div className="loader-wrapper">
                          <div className="loader"></div>
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select Criteria</label>
                          <ul>
                            <li>
                              <a
                                className={
                                  filterModule ? "data-btn active" : "data-btn"
                                }
                                onClick={filterModulefun}
                              >
                                Module
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  filterDepartment
                                    ? "data-btn active"
                                    : "data-btn"
                                }
                                onClick={filterDepartmentfun}
                              >
                                Department
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  filterCourse ? "data-btn active" : "data-btn"
                                }
                                onClick={filterCoursefun}
                              >
                                Course
                              </a>
                            </li>
                            <li>
                              <a
                                className={
                                  filterUser ? "data-btn active" : "data-btn"
                                }
                                onClick={filterUserfun}
                              >
                                User
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {!filterUser ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>{Header}</label>
                            {/* {multiSelectData && multiSelectData.length > 0 && ( */}
                            <Multiselect
                              key={JSON.stringify(multiSelectData)}
                              data={multiSelectData || []}
                              onChange={onChange}
                              placeholder="Please Select..."
                              textField="moduleName"
                              className="form-control"
                            />
                            {/* )} */}
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>{Header}</label>

                            <div className="row">
                              <div className="col-12 mb-3" style={{ flex: 1 }}>
                                <Multiselect
                                  key={JSON.stringify(userSelectData)}
                                  disabled
                                  data={userSelectData || []}
                                  onChange={onChange}
                                  onSearch={onUserSearch}
                                  defaultValue={searchUsers}
                                  placeholder="Please Select..."
                                  textField="moduleName"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-12">
                                <button
                                  type="button"
                                  onClick={searchUser}
                                  className="btn btn-success"
                                >
                                  Select Users
                                </button>
                              </div>
                            </div>
                          </div>

                          <Modal
                            isOpen={modal}
                            toggle={toggle}
                            {...props}
                            className="search-modal"
                          >
                            {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
                            <ModalBody>
                              {/* <SearchUser onItemSelected={modifyUserForSearch} /> */}
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="input-group">
                                    <input
                                      ref={inputRef}
                                      type="text"
                                      autoFocus
                                      placeholder="Search users..."
                                      onChange={(event) => {
                                        setsearchTerm(event.target.value);
                                      }}
                                    />
                                    <button
                                      className="custombtnSearch"
                                      onClick={searchUsermod}
                                    >
                                      search
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="list-container">
                                    <ul>
                                      {usersAll &&
                                        usersAll
                                          .filter((val) => {
                                            if (searchTerm == "") {
                                              return val;
                                            } else if (
                                              val.name
                                                ?.toLowerCase()
                                                .includes(
                                                  searchTerm.toLowerCase()
                                                )
                                            ) {
                                              return val;
                                            }
                                          })
                                          .map(function (item, key) {
                                            return (
                                              <li key={key}>
                                                <div
                                                  className="desc"
                                                  style={{ flex: 1 }}
                                                >
                                                  <span className="text-left">
                                                    {item.name}
                                                  </span>
                                                  <span className="textEmail">
                                                    {item.emailAddress}
                                                  </span>
                                                </div>

                                                <div className="text-right">
                                                  <HasUserIdTag
                                                    user={item}
                                                    searchIds={searchIds}
                                                  />
                                                  {/* {!hasUserId(item) ? (
                                                    <button
                                                      className="btn secondary-btn btn-sm m-1"
                                                      onClick={() =>
                                                        selectUserSearch(item)
                                                      }
                                                    >
                                                      Add
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className="btn third-btn btn-sm m-1"
                                                      onClick={() =>
                                                        selectUserSearch(item)
                                                      }
                                                      style={{ color: "#fff" }}
                                                    >
                                                      Remove
                                                    </button>
                                                  )} */}
                                                </div>
                                              </li>
                                            );
                                          })
                                          .filter((x) => x)}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </ModalBody>
                          </Modal>
                        </div>
                      )}

                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>From Date</label>
                          <input
                            name="fromDate"
                            className="form-control"
                            component={renderInputField}
                            type="date"
                            label="From date"
                            onChange={(e) => setFromDate(e.target.value)}
                            max={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>To Date</label>
                          <input
                            name="toDate"
                            className="form-control"
                            component={renderInputField}
                            type="date"
                            label="To date"
                            data-date-format="MM DD YYYY"
                            onChange={(e) => setToDate(e.target.value)}
                            max={moment().format("YYYY-MM-DD")}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="adcm-btn search"
                      style={{ margin: 0 }}
                      onClick={() => {
                        getReport();
                      }}
                    >
                      Search
                    </button>
                  </div>

                  {showResult ? (
                    <>
                      <div className="col-xl-12">
                        <div
                          className="modular-table table-responsive-xl"
                          style={{
                            overflowX: "scroll",
                            overflowY: "auto",
                            height: "350px",
                          }}
                        >
                          {filterUser ? (
                            <table className="table align-middle-modify">
                              <thead style={{ position: "sticky", top: "0" }}>
                                <tr scope="col">
                                  <th>Module Name</th>
                                  <th>Course Name</th>
                                  <th>Name</th>
                                  <th>Email Address</th>
                                  <th>Date / Time</th>
                                  <th>Competency Name</th>
                                  {reportType === "Assessment" && (
                                    <>
                                      <th>Competency Score</th>
                                    </>
                                  )}{" "}
                                  <th>Duration</th>
                                  <th>No. of Attempts</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {report?.map((item) => (
                                  <tr key={item._id}>
                                    <td>{item.moduleId?.moduleName}</td>
                                    <td>{item.userId?.employeeId || "N/A"}</td>
                                    <td className="text-center">
                                      {item.userId?.name || "N/A"}
                                    </td>
                                    <td className="text-center">
                                      {item.userId?.emailAddress || "N/A"}
                                    </td>
                                    <td className="text-center">
                                      {item.updatedAt
                                        ? new Date(
                                            item.updatedAt
                                          ).toLocaleDateString() +
                                          " - " +
                                          new Date(
                                            item.updatedAt
                                          ).toLocaleTimeString()
                                        : new Date(
                                            item.createdAt
                                          ).toLocaleDateString() +
                                          " - " +
                                          new Date(
                                            item.updatedAt
                                          ).toLocaleTimeString()}
                                    </td>
                                    <td className="text-center">
                                      {item?.competencyName ||
                                        item?.debriefingData?.competencyResponse
                                          ?.competencyName}
                                    </td>
                                    {reportType === "Assessment" && (
                                      <>
                                        <td className="text-center">
                                          {item.averageCompetencyScore &&
                                            (
                                              item.averageCompetencyScore * 100
                                            ).toFixed(0) + " %"}
                                        </td>
                                      </>
                                    )}
                                    <td className="text-center">
                                      {getDurationString(item?.duration)}
                                    </td>
                                    <td>{item?.debriefingData?.attempt}</td>
                                    <td>
                                      <button
                                        className="adcm-btn search"
                                        style={{ margin: 0 }}
                                        onClick={() => exportToExcel(item._id)}
                                      >
                                        Export Data
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <table className="table align-middle-modify">
                              <thead style={{ position: "sticky", top: "0" }}>
                                <tr scope="col">
                                  <th>Module Name</th>
                                  <th>Course Name</th>
                                  <th>Name</th>
                                  <th>Email Address</th>
                                  <th>Date / Time</th>
                                  <th>Competency Name</th>
                                  {reportType === "Assessment" && (
                                    <>
                                      <th>Competency Score</th>
                                    </>
                                  )}{" "}
                                  <th>Duration</th>
                                  <th>No. of Attempts</th>
                                  <th>Debriefing Data</th>
                                </tr>
                              </thead>
                              <tbody>{tableData}</tbody>
                            </table>
                          )}
                        </div>
                      </div>
                      <div className="text-end mt-2 mb-4">
                        <CSVLink
                          data={reportData}
                          target="_blank"
                          filename={"report.csv"}
                        >
                          <button type="button" className="adcm-btn export">
                            Export
                          </button>
                        </CSVLink>
                      </div>
                    </>
                  ) : (
                    loading && (
                      <h1 style={{ textAlign: "center" }}>Loading...</h1>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="modular-table table-responsive">
                  {filteredData && filteredData.length > 0 && (
                    <div>
                      <table className="table align-middle">
                        <thead>
                          <tr>
                            {columns &&
                              columns.map((col) => <th> {col.name} </th>)}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData && filteredData.length > 0 ? (
                            filteredData.map((item) => (
                              <tr>
                                {columns &&
                                  columns.map((col) => (
                                    <td>{get(item, col.selector, "")}</td>
                                  ))}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              {columns &&
                                columns.map((col) => <td>Loading...</td>)}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ReportPage = reduxForm({
  form: "reportpage",
  enableReinitialize: true,
  validate: reportFilterValidator,
})(ReportPage);

const mapStateToProps = (state) => {
  return {
    moduleList: state.modules.allModulesByCompany,
    auth: state.auth,
    reportpage: get(state, "form.reportpage.values"),
    user: state.auth.user,
    allUsersByCompany: state.users.allUsersByCompany,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    dispatch: dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
// export default ReportPage;
