import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import * as moduleActions from "../../../action/moduleActions";
import { reduxForm, Field, initialize } from "redux-form";
import {
  renderInputField,
  renderTextAreaField,
  renderRadioButtonField,
  RenderImageField,
  renderCheckBoxField,
  RenderAwsFileField,
  renderSelect,
} from "../../../common/InputFields";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import Ellipse9 from "../../../images/Ellipse9.png";
import linkBg from "../../../images/link.svg";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import plus from "../../../images/plus.svg";
import * as userActions from "../../../action/userActions";
import Swal from "sweetalert2";
import MobileNavBar from "../../../components/mobileNavBar";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
let ModifyModulepage = (props) => {
  const { handleSubmit, auth, token } = props;
  const [module, setModuleData] = useState({});
  const history = useHistory();
  const [competencies, setCompetencies] = useState([]);
  const [errorBorderCss, setErrorBorderCss] = useState(null);
  const [HomePage, setHomepage] = useState(Background);
  const [platformsErrCss, setPlatformsErrCss] = useState(null);
  const [productCode, setProductCode] = useState([]);
  let query = useQuery();

  useEffect(() => {
    moduleActions.getModuleDetail(query.get("moduleId")).then((response) => {
      setModuleData(response.data);
      console.log("module data", response.data)
      setHomepage(response.data?.bannerImage);
      setCompetencies(response.data.competencies);
      let p = [];
      for (let i = 0; i < response.data.competencies.length; i++) {
        p.push({
          index: i,
          competencyName: response.data.competencies[i],
          productCode: response.data.competencyProductCodes[i],
        });
      }
      setProductCode(p);
    });
  }, []);

  const updateModule = (e) => {
    let updatedModule = Object.assign({}, module);
    updatedModule[e.target.name] = e.target.value;
    setModuleData(updatedModule);
  };

  const modifyModuleData = () => {
    module.competencies = [...competencies];

    let competencyArr = [];
    let productCodeArr = [];
    for (let i = 0; i < productCode.length; i++) {
      competencyArr.push(productCode[i].competencyName);
      productCodeArr.push(productCode[i].productCode);
    }

    module.competencies = competencyArr;
    module.competencyProductCodes = productCodeArr;

    let data = module;

    moduleActions
      .modifyModule(auth, data)
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "Module  Modified Successfully",
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(function () {
          history.push("/module");
        });
      })
      .catch((error) => {
        if (
          error.response.data?.message.startsWith(
            "Validation failed: competencyProductCodes"
          )
        ) {
          Swal.fire("Error", "Product code is required.", "error");
        } else {
          Swal.fire("Error", error.response.data?.message, "error");
        }
      });
  };
  const onPlatformsError = (error) => {
    setPlatformsErrCss(error ? "error-border" : null);
  };

  var modifyBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${get(HomePage, "Location") || Background})`,
  };

  const onUploadHome = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      setHomepage(res);

      module.bannerImage = res;
    });
  };

  const deleteCompetency = (e, index) => {
    let updatedModuleData = Object.assign({}, module);
    updatedModuleData["competencies"].splice(index, 1);
    setModuleData(updatedModuleData);

    let updatedProductCode = [...productCode];
    updatedProductCode.splice(index, 1);
    setProductCode(updatedProductCode);
  };

  const [newCompetency, setNewCompetency] = useState();
  const addCompetency = () => {
    console.log(1);
    if (
      // module.competencies &&
      // module.competencies.trim() &&
      !competencies.includes(module.competencies)
    ) {
      let updatedProductCode = [...productCode];
      updatedProductCode.push({
        index: updatedProductCode.length,
        competencyName: newCompetency,
        productCode: "",
      });
      setProductCode(updatedProductCode);
    } else if (competencies.includes(module.competency)) {
      toast("Duplicate competency");
    }
  };

  const updateDevice = (e) => {
    let updatedModule = Object.assign({}, module);
    if (updatedModule.platforms.includes(e.target.value)) {
      console.log(updatedModule.platforms.indexOf(e.target.value));
      updatedModule.platforms.splice(
        updatedModule.platforms.indexOf(e.target.value),
        1
      );
    } else {
      updatedModule.platforms.push(e.target.value);
    }
    setModuleData(updatedModule);
  };
  return (
    <div className="overview-cont">
      <div className="row">
        <div className="col-xl-12 col-lg-12 overview-left-area your-module">
          <MobileNavBar />
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="right_hading d-none d-sm-inline">Modify Module</h2>

            <div className="mt-3">
              <a href="module" className="adcm-btn Cancel ms-2">
                {" "}
                Cancel
              </a>
              {/* <a href="#" className="adcm-btn ms-2"> Add</a> */}
              <a>
                <button
                  type="submit"
                  className="adcm-btn ms-1"
                  onClick={() => {
                    modifyModuleData();
                  }}
                >
                  <img src={plus} style={{ width: "10px" }} alt=" " />
                  Modify
                </button>
              </a>
            </div>
          </div>
          <h2 className="right_hading d-sm-none d-block">Modify Module</h2>

          <div className="row">
            <div className="col-lg-12">
              <div className="company-main add-module">
                <div className="company-profile" style={modifyBackgroundStyle}>
                  {/* <a href="#" className="profile-changer"> <img className="me-1" src="assets/images/link.svg" alt="" /> Change Image</a> */}
                  <label className="profile-changer" for="nhg">
                    <img className="me-1" src={linkBg} alt="" /> Change Image
                  </label>
                  <input
                    onChange={onUploadHome}
                    accept={".jpg, .jpeg, .png"}
                    type="file"
                    style={{
                      opacity: 0,
                      width: "100%",
                      position: "absolute",
                    }}
                    id="nhg"
                  ></input>
                </div>
                <div className="company-fillout">
                  <h3 className="fill-heaing">
                    Module Information (Module Id - {module?._id})
                  </h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Module Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Module name"
                          autocomplete="off"
                          value={module?.moduleName}
                          onChange={(e) => updateModule(e)}
                          name="moduleName"
                        />
                      </div>
                      <div className="form-group mb-5">
                        <div className="custom-file">
                          <label>Module Guide</label>
                          {/* <input type="file" className="custom-file-input" id="validatedCustomFile" required /> */}
                          {/* <input onChange={onUploadSigned} accept={'.jpg, .jpeg, .png'} type="file"
                                                                style={{ opacity: 0, width: '100%', position: 'absolute' }}

                                                            ></input>
                                                            <span className="custom-file-label" for="validatedCustomFile">Choose file...</span> */}
                          <Field
                            name="moduleGuide"
                            token={auth ? token : ""}
                            component={RenderAwsFileField}
                            uploadLabel="Browse"
                            isPlaceHolderImage={false}
                            className="mr"
                            fileType=".pdf"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Module Demo Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Module demo link"
                          autocomplete="off"
                          value={module?.moduleDemoLink}
                          onChange={(e) => updateModule(e)}
                          name="moduleDemoLink"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Module Description</label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="This module is...."
                          value={module?.moduleDescription}
                          onChange={(e) => updateModule(e)}
                          name="moduleDescription"
                        ></textarea>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <h3 className="fill-heaing">Additional Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Industry</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Industry"
                              autocomplete="off"
                              value={module?.industry}
                              onChange={(e) => updateModule(e)}
                              name="industry"
                            />
                          </div>
                          <div className="form-group">
                            <label>Module Type</label>
                            <div
                              className={`d-flex flex-column ${errorBorderCss}`}
                            >
                              <select
                                class="form-control"
                                value={module?.moduleType}
                                onChange={(e) => updateModule(e)}
                                name="moduleType"
                              >
                                <option>Please Select</option>
                                <option value="Unity">Unity</option>
                                <option value="Unreal Engine">
                                  Unreal Engine
                                </option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>Status</label>
                            <div
                              className={`d-flex flex-column ${errorBorderCss}`}
                            >
                              <select
                                class="form-control"
                                value={module?.status}
                                onChange={(e) => updateModule(e)}
                                name="status"
                              >
                                <option>Please Select</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 ">
                          <div className="form-group">
                            <label>Modules Platform</label>
                            <div className="d-flex radioGroup verticleCardradio flex-column">
                              <div className="radioGroupItem mr-4">
                                <label htmlFor="UserManager">Windows</label>
                                {/* <Field
                                  component={renderCheckBoxField}
                                  name="Windows"
                                  value="Windows"
                                  type="checkbox"
                                  onError={onPlatformsError}
                                  className="checkCss"
                                /> */}
                                {module?.platforms?.includes("Windows") ? (
                                  <input
                                    type="checkbox"
                                    name="Windows"
                                    value="Windows"
                                    onChange={(e) => updateDevice(e)}
                                    checked
                                  ></input>
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="Windows"
                                    value="Windows"
                                    onChange={(e) => updateDevice(e)}
                                  ></input>
                                )}
                              </div>
                              <div className="radioGroupItem">
                                <label htmlFor="UserAdmin">Oculus</label>
                                {/* <Field
                                  component={renderCheckBoxField}
                                  name="Oculus"
                                  value="Oculus"
                                  type="checkbox"
                                /> */}
                                {module?.platforms?.includes("Oculus") ? (
                                  <input
                                    type="checkbox"
                                    name="Oculus"
                                    value="Oculus"
                                    onChange={(e) => updateDevice(e)}
                                    checked
                                  ></input>
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="Oculus"
                                    value="Oculus"
                                    onChange={(e) => updateDevice(e)}
                                  ></input>
                                )}
                              </div>
                              <div className="radioGroupItem">
                                <label htmlFor="UserEmployee">HTC</label>
                                {/* <Field
                                  component={renderCheckBoxField}
                                  name="HTC"
                                  value="HTC"
                                  type="checkbox"
                                /> */}
                                {module?.platforms?.includes("HTC") ? (
                                  <input
                                    type="checkbox"
                                    name="HTC"
                                    value="HTC"
                                    onChange={(e) => updateDevice(e)}
                                    checked
                                  ></input>
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="HTC"
                                    value="HTC"
                                    onChange={(e) => updateDevice(e)}
                                  ></input>
                                )}
                              </div>
                              <div className="radioGroupItem">
                                <label htmlFor="UserEmployee">PICO</label>
                                {/* <Field
                                  component={renderCheckBoxField}
                                  name="PICO"
                                  value="PICO"
                                  type="checkbox"
                                /> */}
                                {module?.platforms?.includes("PICO") ? (
                                  <input
                                    type="checkbox"
                                    name="PICO"
                                    value="PICO"
                                    onChange={(e) => updateDevice(e)}
                                    checked
                                  ></input>
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="PICO"
                                    value="PICO"
                                    onChange={(e) => updateDevice(e)}
                                  ></input>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>Competencies</label>
                            <div className="InputTextInBttn">
                              <div className="form-group">
                                {/* <Field
                                  name="competency"
                                  component={renderInputField}
                                  type="text"
                                  label="competencies..."
                                  validate={[
                                    () =>
                                      competencies && competencies.length > 0
                                        ? undefined
                                        : "Required",
                                  ]}
                                /> */}
                                <input
                                  placeholder="Competency Name"
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    setNewCompetency(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="form-group inputINbttn">
                                <button
                                  type="button"
                                  onClick={addCompetency}
                                  className="btn middiumBttn themesecondarybackground"
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            {productCode?.length > 0 && (
                              <div className="compitencyResult">
                                {productCode.map((item, index) => (
                                  <div
                                    key={`${item}-${index}`}
                                    className="moduleCompetencyCard"
                                  >
                                    <label htmlFor="UserManager">
                                      {item.competencyName}
                                    </label>

                                    <input
                                      type="text"
                                      placeholder="Product Code"
                                      value={item.productCode}
                                      onChange={(e) => {
                                        let updatedProductCode = [
                                          ...productCode,
                                        ];

                                        updatedProductCode[index].productCode =
                                          e.target.value;

                                        setProductCode(updatedProductCode);
                                      }}
                                    />

                                    <button
                                      className="competencyDelete"
                                      onClick={(e) => {
                                        deleteCompetency(e, index);
                                      }}
                                    >
                                      X
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModifyModulepage = reduxForm({
  form: "modifymodule",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ModifyModulepage);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    module: state.modules.selectedModule,
    modifymodule: state.form.modifymodule
      ? state.form.modifymodule.values
      : null,
  };
};

export default connect(mapStateToProps)(ModifyModulepage);
