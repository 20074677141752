import globalError from './globalErrorReducer';
import companies from './companyReducer';
import auth from './authReducer';
import global from './globalReducer';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as formReducer } from 'redux-form';
import userReducer from './userReducer';
import moduleReducer from './moduleReducer';
import themeReducer from './themeReducer'
import companyDefaultsReducer from './companyDefaultsReducer';
import TestReducer from "./TestReducer";
  

export default (history) =>
  combineReducers({
    globalError,
    companies,
    auth,
    global,
    //router: connectRouter(history),
    form: formReducer,
    users: userReducer,
    modules: moduleReducer,
    theme: themeReducer,
    companyDefaults: companyDefaultsReducer,
    Test: TestReducer,
  });
