import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLoginLogs } from "../../action/adminDashboardActions";
import { Link, useHistory } from "react-router-dom";
import EldcLogo from "../../images/MedVR_Logo.png";
import plus from "../../images/plus.svg";
import { initialize, destroy } from "redux-form";

import Search from "../common/Search";
import Background from "../../images/company-profile-bg.jpg";
import DataGrid from "../../common/dataGrid";
import Swal from "sweetalert2";
import MobileNavBar from "../../components/mobileNavBar";
import { GrAdd } from "react-icons/gr";
import { FiEdit3 } from "react-icons/fi";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { get } from "lodash";
import { loadState } from "../../store/LocalStorage";
import moment from "moment";
import { Multiselect } from "react-widgets";
import { getAllDepartments } from "../../action/departmentActions";
import {
  renderInputField,
  renderRadioButtonField,
} from "../../common/InputFields";
const ModuleLibrary = (props) => {
  const [loginRecords, setLoginRecords] = useState([]);
  const history = useHistory();
  const { loggedIn, user, token, modules } = get(loadState(), "auth") || {};
  let auth = {
    token: token || "",
    loggedIn: loggedIn || false,
    modules: modules || [],
    user: {
      ...user,
    },
  };
  const [moduleList, setmoduleList] = useState(auth.modules);
  const [click, setclick] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState("moduleName");
  const [sortOrder, setSortOrder] = useState(1);
  const [total, setTotal] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  let [allDepartments, setAllDepartments] = useState([]);
  const [searchIds, setSearchIds] = useState([]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    setPageNumber(1);
    setPageSize(currentRowsPerPage);
  };

  useEffect(() => {
    getAllDepartments().then((response) => {
      setAllDepartments(response.data);
    });

    getLoginLogs(
      pageNumber,
      pageSize,
      searchIds.length > 0 && searchIds,
      fromDate !== "" && new Date(fromDate),
      toDate !== "" && new Date(toDate)
    ).then((res) => {
      let sessions = res.data.userSessions;
      for (let i = 0; i < sessions.length; i++) {
        sessions[i]["startTime"] = new Date(
          sessions[i]["startTime"]
        ).toLocaleString();
      }
      setLoginRecords(res.data.userSessions);
      setTotal(res.data.userSessionsCount);
      setLoading(false);
    });
  }, [pageNumber, pageSize]);

  const columns = [
    {
      name: "Username",
      selector: "userId",
      sortable: true,
      cell: (row) =>
        row?.userId?.name !== undefined ? row?.userId?.name : "N/A",
      wrap: true,
    },
    {
      name: "Department",
      selector: "userId",
      cell: (row) =>
        row?.userId?.department !== undefined
          ? row?.userId?.department.join(", ")
          : "N/A",
      sortable: true,
    },
    { name: "Login Time", selector: "startTime", sortable: true },
    {
      name: "Module Name",
      selector: "moduleID",
      cell: (row) =>
        row?.moduleID?.moduleName !== undefined
          ? row?.moduleID?.moduleName
          : "N/A",
      sortable: false,
    },
  ];
  const onChangePage = (page, totalRows) => {
    setPageNumber(page);
  };
  const onSort = (column, sortDirection, event) => {
    setSortColumn(column.selector);
    setSortOrder(sortDirection === "asc" ? 1 : -1);
  };

  const getLogs = () => {
    setPageNumber(1);
    console.log(searchIds, fromDate, toDate);
    if (fromDate == "" || toDate == "" || searchIds.length == 0) {
      if (fromDate == "") {
        Swal.fire("Warning", "Please select a from date.", "warning");
      } else if (toDate == "") {
        Swal.fire("Warning", "Please select a to date.", "warning");
      } else if (searchIds.length == 0) {
        Swal.fire("Warning", "Please select departments.", "warning");
      }
    } else {
      getLoginLogs(
        1,
        pageSize,
        searchIds.length > 0 && searchIds,
        fromDate !== "" && new Date(fromDate),
        toDate !== "" && new Date(toDate)
      ).then((res) => {
        console.log(res.data);
        let sessions = res.data.userSessions;
        for (let i = 0; i < sessions.length; i++) {
          sessions[i]["startTime"] = new Date(
            sessions[i]["startTime"]
          ).toLocaleString();
        }
        setLoginRecords(res.data.userSessions);
        setTotal(res.data.userSessionsCount);
        setLoading(false);
      });
    }
  };

  let customStyles = {
    headCells: {
      style: {
        "border-top": "none",
        "font-size": "15px",
        color: "#2F4153",
        "font-weight": 700,
        "&:last-child": {
          "text-align": "right",
        },
      },
    },
  };
  const onChange = (event) => {
    let updatedId = [];
    for (let i = 0; i < event.length; i++) {
      updatedId.push(event[i]._id);
    }
    setSearchIds(updatedId);
  };
  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="right_hading d-none d-md-inline">
                All Login Records
              </h2>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 overview-left-area your-module">
                {!loading ? (
                  <></>
                ) : (
                  <div className="loader-wrapper">
                    <div className="loader"></div>
                  </div>
                )}
                <div className="row pb-5">
                  <div className="company-main report">
                    <div className="company-fillout mb-0">
                      <h3 className="fill-heaing">Data Selection</h3>
                      <div className="row">
                        {!loading ? (
                          <></>
                        ) : (
                          <div className="loader-wrapper">
                            <div className="loader"></div>
                          </div>
                        )}

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Select Department</label>

                            {/* {multiSelectData && multiSelectData.length > 0 && ( */}
                            <Multiselect
                              key={"departmentName"}
                              data={allDepartments || []}
                              onChange={onChange}
                              placeholder="Please Select..."
                              textField="departmentName"
                              className="form-control"
                            />
                            {/* )} */}
                          </div>
                        </div>

                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <label>From Date</label>
                            <input
                              name="fromDate"
                              className="form-control"
                              component={renderInputField}
                              type="date"
                              label="From date"
                              onChange={(e) => setFromDate(e.target.value)}
                              max={moment().format("YYYY-MM-DD")}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <div className="form-group">
                            <label>To Date</label>
                            <input
                              name="toDate"
                              className="form-control"
                              component={renderInputField}
                              type="date"
                              label="To date"
                              data-date-format="MM DD YYYY"
                              onChange={(e) => setToDate(e.target.value)}
                              max={moment().format("YYYY-MM-DD")}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className="adcm-btn search"
                        style={{ margin: 0 }}
                        onClick={() => {
                          getLogs();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <h2 className="right_hading d-md-none d-block">
                  All Login Records
                </h2>

                <div className="row mb-5">
                  <div className="col-xl-12">
                    <div className="modular-table table-responsive">
                      <DataGrid
                        noHeader
                        data={loginRecords}
                        paginationTotalRows={total}
                        columns={columns}
                        onChangePage={onChangePage}
                        onSort={onSort}
                        keyField="_id"
                        customStyles={customStyles}
                        paginationPerPage={pageSize}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        progressPending={loading}
                        sortServer={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!loading ? (
            <></>
          ) : (
            <div className="loader-wrapper">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    moduleList: state.modules.userModulesList,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLibrary);
