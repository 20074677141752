import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../action/userActions";
import * as companyActions from "../../../action/companyActions";
import { Link, useHistory } from "react-router-dom";
import EldcLogo from "../../../images/MedVR_Logo.png";
import { initialize, destroy } from "redux-form";
import { Button } from "reactstrap";
import SearchUser from "./SearchUser";

import Swal from "sweetalert2";
import $ from "jquery";
import MobileNavBar from "../../../components/mobileNavBar";

const UserManagementByAdmin = (props) => {
  const { auth } = props;
  let [pageNumber, setPageNumber] = useState(1);
  let [pageSize, setPageSize] = useState(10);
  let [sortColumn, setSortColumn] = useState("name");

  let [sortOrder, setSortOrder] = useState(1);
  let [users, setUsers] = useState([]);
  let [usersCount, setUsersCount] = useState(0);
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    loadUsers();
  }, [sortColumn, sortOrder, pageSize, pageNumber]);
  const history = useHistory();

  const modifyUser = (data) => {
    userActions.selectedUser(data);
    props.dispatch && props.dispatch(initialize("modifyuser", { ...data }));

    const serializedState = JSON.stringify(data);
    localStorage.setItem("selectedModule", serializedState);

    // history.push({ pathname: "/modify-user" });
    history.push("/modify-user");
  };

  const modifyUserBYName = (data) => {
    userActions.selectedUser(data);
    props.dispatch && props.dispatch(initialize("modifyuser", { ...data }));

    const serializedState = JSON.stringify(data);
    localStorage.setItem("selectedModule", serializedState);

    // history.push({ pathname: '/modify-user-details' });
  };

  const modifyUserForSearch = (data) => {
    userActions.selectedUser(data);
    props.dispatch && props.dispatch(initialize("modifyuser", { ...data }));

    const serializedState = JSON.stringify(data);
    localStorage.setItem("selectedModule", serializedState);

    // history.push({ pathname: "/modify-user-details" });
    history.push("/modify-user-details");
  };

  const loadUsers = () => {
    setLoading(true);
    userActions
      .fetchUsers(pageNumber, pageSize, sortOrder, sortColumn)
      .then((response) => {
        // var holddata = response.data.users
        setUsers(response.data.users);
        setUsersCount(response.data.usersCount);
      })
      .catch((error) => console.error(error.data.response))
      .finally(() => setLoading(false));
  };

  const FirstPage = () => {
    $(".ptoa").removeClass("active");
    $(this).addClass("active");

    setPageNumber(1);
  };
  const secondPage = () => {
    $(".ptoa").removeClass("active");
    $(this).addClass("active");

    setPageNumber(2);
  };
  const thirdPage = () => {
    setPageNumber(3);
    $(".pto a").removeClass("active");
    $(this).addClass("active");
  };
  const forthPage = () => {
    setPageNumber(4);
  };
  const fifthPage = () => {
    setPageNumber(5);
  };
  const sixthPage = () => {
    setPageNumber(6);
  };
  const sevenPage = () => {
    setPageNumber(7);
  };
  const eightPage = () => {
    setPageNumber(8);
  };
  const ninethPage = () => {
    setPageNumber(9);
  };
  const lastPage = () => {
    setPageNumber(10);
  };
  const inviteLink = (email) => {};

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="row justify-content-between align-items-center mb-2">
              <div className="col-lg-2 col-md-12 order-d">
                <h2 className="right_hading d-none d-md-inline">All Users 123</h2>
              </div>
              <div className="col-lg-6 col-md-7">
                <form action="" className="search">
                  {/* <input type="text" placeholder="Search..."/>                    */}
                  <SearchUser onItemSelected={modifyUserForSearch} />
                </form>
              </div>

              <div className="col-lg-4 col-md-5 text-end">
                {/* <a href="#" className="adcm-btn"><i className="fa-solid fa-plus me-1"></i> Import Users</a> */}
                <Link to="/import-user" className="adcm-btn ms-1">
                  <span>Import Users</span>
                </Link>

                {/* <a href="#" className="adcm-btn ms-1"><i className="fa-solid fa-plus me-1"></i> Add User</a> */}
                <Link to="/add-companyuser" className="adcm-btn ms-1">
                  <span>Add User</span>
                </Link>
              </div>
            </div>
            <h2 className="right_hading d-md-none d-block">All Users</h2>

            <div className="row">
              <div className="col-xl-12">
                <div className="modular-table big-table table-responsive">
                  <table className="table align-middle">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col" className="text-center">
                          Username
                        </th>
                        <th scope="col" className="text-center">
                          Email Address
                        </th>
                        <th scope="col" className="text-center">
                          Company Name
                        </th>
                        <th scope="col" className="text-center">
                          Role
                        </th>
                        <th scope="col" className="text-center">
                          Status
                        </th>
                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        users.map((item) => (
                          <tr key={item._id}>
                            {/* <Link to='/PtientIntentList' onClick={() => ShowIntents(item.p_id,item.patient_Nm)}
                                                                            className=""><a href="#">{item.patient_Nm}</a>
                                                                        </Link> */}
                            <td className="custom-link-text">
                              <Link
                                to="/modify-user-details"
                                onClick={() => modifyUserBYName(item)}
                                className=""
                              >
                                {item.name}
                              </Link>
                            </td>
                            {/* <td>{item.name}</td> */}
                            <td className="text-center">{item.emailAddress}</td>
                            <td className="text-center">{item.emailAddress}</td>
                            <td className="text-center">
                              {item.companyId.companyName}
                            </td>
                            <td className="text-center">{item.role}</td>
                            <td className="text-center">
                              {item.status ? (
                                <a className="activate">active</a>
                              ) : (
                                <a className="incomplete">inactive</a>
                              )}
                            </td>
                            <td className="text-center">
                              <button
                                className="btn Modify-btn me-2"
                                value="Modify"
                                onClick={() => modifyUser(item)}
                              >
                                Modify
                              </button>
                              <a
                                href="#"
                                className="activate"
                                onClick={() =>
                                  userActions
                                    .sendInviteAction(
                                      auth.token,
                                      item.emailAddress
                                    )
                                    .then(() =>
                                      //   toast('The invitation link is sent to the email.')
                                      Swal.fire({
                                        title: "Success",
                                        text: "The invitation link is sent to the email",
                                        icon: "success",
                                        confirmButtonText: "Ok",
                                        allowOutsideClick: false,
                                      })
                                    )
                                    .catch((error) =>
                                      //   alert(error.response ? error.response.data.message : error.message)
                                      Swal.fire({
                                        title: "Error",
                                        text: `Error: ${error}`,
                                        icon: "error",
                                        confirmButtonText: "Ok",
                                        allowOutsideClick: false,
                                      })
                                    )
                                }
                              >
                                Invite
                              </a>
                            </td>
                            {/* <a className="activate">
                                                            <button type="submit">Invite</button></a>
                                                            </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <div className="pto">
                    <a className="active" onClick={FirstPage}>
                      1
                    </a>
                    <a onClick={secondPage}>2</a>
                    <a onClick={thirdPage}>3</a>
                    <a onClick={forthPage}>4</a>
                    <a onClick={fifthPage}>5</a>
                    <a onClick={sixthPage}>6</a>
                    <a onClick={sevenPage}>7</a>
                    <a onClick={eightPage}>8</a>
                    <a onClick={ninethPage}>9</a>
                    <a onClick={lastPage}>10</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    companies: state.companies,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    companyActions: bindActionCreators(companyActions, dispatch),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagementByAdmin);
// export default UserManagement;
