export const userProfileValidator = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.gender) {
    errors.gender = "Required";
  }
  if (values.passwordHash !== values.confirmPasswordHash) {
    errors.confirmPasswordHash = "Confirm password is not matching.";
  }

  return errors;
};
