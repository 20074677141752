import React, { useState } from "react";
import ArrowBack from "../../../images/arrow_back.png";
import AboutBg from "../../../images/about-bg.png";
import { Link } from "react-router-dom";
import { get } from "lodash";
import VideoModal from "../../../common/api/VideoModel";
import {
  getYoutubeVideoID,
  getVimeoVideoID,
} from "../../../common/utilityFunctions";

const LibraryDetails = (props) => {
  const selectModel = localStorage.getItem("selectedModule");
  const [selectModule, setSelectModule] = useState(
    selectModel === null ? undefined : JSON.parse(selectModel)
  );
  const [isOpen, setIsOpen] = useState(false);
  const [zIndex, setZIndex] = useState(1);

  const getVideoURL = (url) => {
    if (url && url.includes("vimeo.com")) {
      return `https://player.vimeo.com/video/${getVimeoVideoID(url)}`;
    } else {
      return `https://www.youtube.com/embed/${getYoutubeVideoID(url)}`;
    }
  };
  const closePopUpActions = () => {
    setZIndex(1);
    setIsOpen(false);
  };
  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-9 col-lg-8 overview-left-area">
            <div className="d-flex justify-content-between align-items-center">
              <div className="sec1 logo d-block d-lg-none">
                <a href="index.html">
                  <img src="assets/images/MedVR_Logo.png" alt="" />
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive"
                aria-controls="navbarResponsive"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <h2 className="right_hading">
              <Link to="/module-library">
                <img src={ArrowBack} alt="Arrow Back" />
              </Link>{" "}
              {selectModule && selectModule.moduleName}
            </h2>

            <div className="row">
              <div className="col-lg-12">
                <div className="sec2 ">
                  <div className="abt-top-pic">
                
                    {/* <img src={AboutBg} alt="" /> */}
                    <img
                      src={get(selectModule, "bannerImage.Location") || AboutBg}
                      alt="confined Space Safety"
                    />
                  </div>
                  <div className="devider2"></div>
                  <div className="cms-content">
                    <h5>DESCRIPTION</h5>
                    <p>{selectModule.moduleDescription}</p>

                    {/* <div className="seemore-block">
                      <a href="#">
                        Show More{" "}
                        <img src="assets/images/caret-down.svg" alt="" />
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 right-side-area">
            <VideoModal data={selectModule.moduleDemoLink} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LibraryDetails;
