import React, { useEffect, useState, useRef } from "react";
import * as departmentActions from "../../../action/departmentActions";
import * as moduleActions from "../../../action/moduleActions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Search from "../../common/Search";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import MobileNavBar from "../../../components/mobileNavBar";
import { getModules } from "../../../action/userActions";
import { FaChevronDown } from 'react-icons/fa';

const BulkAssignment = (props) => {
  const { user } = props || {};
  let [selectedModules, setSelectedModules] = useState([]);
  let [selectedDepartmemts, setSelectedDepartments] = useState([]);
  let [selectedOption, setSelectedOption] = useState("users");
  let [allModules, setAllModules] = useState([]);
  let [allDepartments, setAllDepartments] = useState([]);
  const [modal, setModal] = useState(false);
  let [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    loadAllDepartments();
    getModules().then((response) => {
      setAllModules(response.data);
    });
  }, []);
  const toggle = () => setModal(!modal);

  const assignModules = () => {
    if (selectedModules.length === 0) {
      Swal.fire({
        title: "Error",
        text: `Please select some modules to assign`,
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
      return;
    }
    if (selectedOption === "departments") {
      if (selectedDepartmemts.length === 0) {
        Swal.fire({
          title: "Error",
          text: `Please select some departments to assign`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        return;
      }
      setLoading(true);
      departmentActions
        .assignModules(
          selectedDepartmemts.map((department) => department._id),
          selectedModules.map((selectedModule) => selectedModule._id)
        )
        .then((response) => {
          setLoading(false);
          Swal.fire({
            title: "Success",
            text: "Modules succssfully assigned to departments",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        })
        .catch((error) =>
          Swal.fire({
            title: "Error",
            text: `There were errors while assigning modules to departments ${error.data.message}`,
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          })
        );
    } else {
      setLoading(true);

      moduleActions
        .assignModulesToAllCompanyUsers(
          selectedModules.map((selectedModule) => selectedModule._id)
        )
        .then(() => {
          setLoading(false);

          Swal.fire({
            title: "Success",
            text: "Modules succssfully assigned to users",
            icon: "success",
            confirmButtonText: "Ok",
          });
        })
        .catch((error) =>
          Swal.fire({
            title: "Error",
            text: `There were errors while assigning modules to users ${error.data.message}`,
            icon: "error",
            confirmButtonText: "Ok",
          })
        );
    }
  };
  // console.log("loading vale", loading)

  const loadAllModules = () => {
    setLoading(true);
    if (props.auth?.user?.role == "Manager") {
      moduleActions
        .getModuleByUser()
        .then((response) => setAllModules(response.data))
        .catch((error) =>
          // toast.error(`Unable to fetch modules ${error.data}`)
          Swal.fire({
            allowOutsideClick: false,
            title: "Error",
            text: `Unable to fetch modules: ${error}`,
            icon: "error",
            confirmButtonText: "Ok",
          })
        )
        .finally(() => setLoading(false));
    } else {
      moduleActions
        .fetchAllModulesByCompany()
        .then((response) => setAllModules(response.data))
        .catch((error) =>
          // toast.error(`Unable to fetch modules ${error.data}`)
          Swal.fire({
            allowOutsideClick: false,
            title: "Error",
            text: `Unable to fetch modules: ${error}`,
            icon: "error",
            confirmButtonText: "Ok",
          })
        )
        .finally(() => setLoading(false));
    }
  };

  // const loadAllDepartments = () => {
  //   departmentActions
  //     .getAllDepartments()
  //     .then((response) => setAllDepartments(response.data))
  //     .catch((error) =>
  //       toast.error(`Unable to fetch departments ${error.data.message}`)
  //     );
  // };

  const loadAllDepartments = () => {
    departmentActions
      .getAllDepartments()
      .then((response) => {
        setAllDepartments(response.data);
        if (user.role == "Manager") {
          let updatedDepartments = [];
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < user.departmentId.length; j++) {
              if (user.departmentId[j] == response.data[i]._id) {
                updatedDepartments.push(response.data[i]);
              }
            }
          }
          setAllDepartments(updatedDepartments);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: `Error: ${JSON.stringify(error)}`,
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
        // setLoading(false);
      });
  };

  const onDepartmentSelected = (department) => {
    if (
      selectedDepartmemts.some(
        (selectedDepartment) => selectedDepartment._id === department._id
      )
    )
      return;

    let departments = [...selectedDepartmemts, department];

    setSelectedDepartments(departments);
  };
  const onModuleSelected = (module) => {
    console.log("module id", module);
    if (
      selectedModules.some(
        (selectdeModule) => selectdeModule._id === module._id
      )
    )
      return;
    let modules = [...selectedModules, module];

    setSelectedModules(modules);
  };

  const onDepartmentRemoved = (e, department) => {
    e.stopPropagation();
    let departments = selectedDepartmemts.filter(
      (dept) => dept.departmentName !== department.departmentName
    );
    setSelectedDepartments(departments);
  };
  const onModuleRemoved = (e, module) => {
    e.stopPropagation();
    let modules = selectedModules.filter(
      (selectedModule) => selectedModule.moduleName !== module.moduleName
    );
    setSelectedModules(modules);
  };
  const onOptionsSelected = (option) => {
    option === "users"
      ? setSelectedOption("users")
      : setSelectedOption("departments");
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const dropdownStyles = {
    container: {
      position: 'relative',
      width: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    list: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderTop: 'none',
      maxHeight: '200px',
      overflowY: 'auto',
      zIndex: 1000,
    },
    item: {
      padding: '8px 10px',
    },
    clearAllButton: {
      position: 'absolute',
      right: '-30px',
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    checkbox: {
    marginLeft: '10px',
    marginTop: '3px'
    },
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <form action="">
              <MobileNavBar />
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="right_hading d-none d-sm-inline">
                  Bulk Assignment
                </h2>

                <div className="mt-3">
                  <a>
                    <Link to="/" className="adcm-btn Cancel ms-1">
                      <span>Return</span>
                    </Link>
                  </a>
                  <a href="#" className="adcm-btn ms-2" onClick={assignModules}>
                    {" "}
                    Save
                  </a>
                </div>
              </div>
              <h2 className="right_hading d-sm-none d-block">
                Bulk Assignment
              </h2>

              <div className="row">
                <div className="col-lg-12">
                  <div className="company-main add-module">
                    <div className="company-fillout">
                      <h3 className="fill-heaing">User Information</h3>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <ul>
                              <li>
                                <a
                                  className={
                                    selectedOption === "users"
                                      ? "data-btn active"
                                      : "data-btn"
                                  }
                                  onClick={() => onOptionsSelected("users")}
                                >
                                  All Users
                                </a>
                              </li>
                              <li>
                                <a
                                  className={
                                    selectedOption === "departments"
                                      ? "data-btn active"
                                      : "data-btn"
                                  }
                                  onClick={() =>
                                    onOptionsSelected("departments")
                                  }
                                >
                                  Department
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6">
                          {selectedOption === "departments" && (
                            <div className="form-group">
                              <label>Search Department</label>

                              <Search
                                {...{
                                  data: allDepartments,
                                  onItemSelected: onDepartmentSelected,
                                  populateFieldName: "departmentName",
                                  placeholderLabel: "Search Department",
                                }}
                              />
                              <div className="flex2fieldForm">
                                <div className="field-group">
                                  {selectedDepartmemts &&
                                    selectedDepartmemts.length > 0 && (
                                      <div className="flex2fieldForm">
                                        <div className="field-group">
                                          <div
                                            className="radioGroup"
                                            style={{ paddingTop: "1.5em" }}
                                          >
                                            {selectedDepartmemts.map(
                                              (item, index) => (
                                                <div
                                                  key={`${item}-${index}`}
                                                  className="mr-4 moduleCompetencyCard"
                                                >
                                                  <label htmlFor="Module">
                                                    {item?.departmentName}
                                                  </label>
                                                  <button
                                                    onClick={(e) =>
                                                      onDepartmentRemoved(
                                                        e,
                                                        item
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {!loading ? (
                      <></>
                    ) : (
                      <div className="loader-wrapper">
                        <div className="loader"></div>
                      </div>
                    )}
                    <div className="company-fillout snd">
                      <h3 className="fill-heaing">Module Information</h3>
                      <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                            <label>Select Module</label>
                            <div style={dropdownStyles.container} ref={dropdownRef}>
                                <div style={dropdownStyles.header} onClick={toggleDropdown}>
                                  <span>Please Select Module</span>
                                  <FaChevronDown />
                                </div>
                                {isOpen && (
                                  <div style={dropdownStyles.list}>
                                    {allModules.map((module) => (
                                      <div key={module._id} style={dropdownStyles.item}>
                                        <label>
                                          <input
                                            style={dropdownStyles.checkbox}
                                            type="checkbox"
                                            checked={selectedModules.some(selected => selected._id === module._id)}
                                            onChange={() => onModuleSelected(module)}
                                          />
                                          {module.moduleName}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              {selectedModules && selectedModules.length > 0 && (
                              <div className="flex2fieldForm">
                                <div className="field-group">
                                  <div
                                    className="radioGroup"
                                    style={{ paddingTop: "3em" }}
                                  >
                                    {selectedModules.map((item, index) => (
                                      <div
                                        key={`${item}-${index}`}
                                        className="mr-4 moduleCompetencyCard"
                                      >
                                        <label htmlFor="Module">
                                          {item.moduleName}
                                        </label>
                                        <button
                                          onClick={(e) =>
                                            onModuleRemoved(e, item)
                                          }
                                        >
                                          X
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* <div className="form-group">
                            <Search
                              {...{
                                data: allModules,
                                onItemSelected: onModuleSelected,
                                populateFieldName: "moduleName",
                                placeholderLabel: "Select module",
                              }}
                            />
                            {selectedModules && selectedModules.length > 0 && (
                              <div className="flex2fieldForm">
                                <div className="field-group">
                                  <div
                                    className="radioGroup"
                                    style={{ paddingTop: "3em" }}
                                  >
                                    {selectedModules.map((item, index) => (
                                      <div
                                        key={`${item}-${index}`}
                                        className="mr-4 moduleCompetencyCard"
                                      >
                                        <label htmlFor="Module">
                                          {item.moduleName}
                                        </label>
                                        <button
                                          onClick={(e) =>
                                            onModuleRemoved(e, item)
                                          }
                                        >
                                          X
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    allUsersByCompany: state.users.allUsersByCompany,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(BulkAssignment);
