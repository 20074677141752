let userValidator = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  }

  if (!values.emailAddress) {
    errors.emailAddress = "Required";
  }

  if (!values.role) {
    errors.role = "Required";
  }

  if (!values.companyId) {
    errors.companyId = "Required";
  }

  if (!values.mobileNo) {
    errors.mobileNo = "Required";
  } else if (values.mobileNo) {
    if (values.mobileNo.length !== 10) {
      errors.mobileNo = "Mobile no should be 10 digit number.";
    } else if (
      values.mobileNo.length === 10 &&
      !/^\d+$/.test(values.mobileNo)
    ) {
      errors.mobileNo = "Pin number should only be numeric.";
    }
  }

  return errors;
};

export default userValidator;
