import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { GrView } from 'react-icons/gr';

const SkillTable = ({ data }) => {

  let [loading, setLoading] = useState(false);

  // console.log("data skill", Object.entries(data).length )

  useEffect(() => {
    if (Object.entries(data).length === 0) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [data])

  const getDurationString = (d) => {
    let x = d.toString().split(".");
    x[0] = parseInt(x[0]);
    x[1] = parseInt(x[1].slice(0, 2)) / 100;
    let durationString = x[0] + " hrs " + (x[1] * 60).toFixed(0) + " mins";
    return durationString;
  };

  let tableData = [];
  for (const [key, value] of Object.entries(data)) {
    tableData.push(
      <tr>
        <td>{key}</td>
        <td className="text-center">{value.length}</td>
        <td className="text-center">
          <Link to={`/skill-detail/${key}`} className="primary_link">
            <GrView />
          </Link>
        </td>
      </tr>
    );
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>Competency</th>
            <th className="text-center">No of Attempts</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>{tableData}</tbody>
      </table>
      {!loading ?
        <></>
        :
        <div className="loader-wrapper">
          <div className="loader"></div>

        </div>

      }
    </>
  );
};

export default SkillTable;
