import initialState from './initialState';

export default function companyDefaultsReducer(state = initialState.companyDefaults, action) {
    switch (action.type) {
        case 'CHANGE_COMPANYDEFAULTS':
            return action.companyDefaults

        default:
            return state;
    }
}
