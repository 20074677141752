import { getInstance } from "./axiosFactory";
import { raiseError } from "./globalActions";
import { saveState } from "../store/LocalStorage";
import { get } from "lodash";
// import { Link, useHistory } from 'react-router-dom'

const axiosInstance = getInstance();

export function loginSuccess(loginResult) {
  return { type: "LOGIN_SUCCESSFUL", loginResult };
}

export function loginFailure(failure) {
  return { type: "LOGIN_FAILURE", failure };
}

export function logoutAction() {
  return { type: "LOGOUT" };
}
export const logoutActionAd = () => {
  localStorage && localStorage.setItem("isAuthenticated", false);
};

export const login = (mobileNo, pinNumber) => {
  // const navigate = useHistory();

  return (dispatch) =>
    axiosInstance
      .post("/account/login", {
        mobileNo: mobileNo,
        passwordHash: pinNumber,
      })
      .then((result) => {
        saveState({ auth: { ...get(result, "data"), loggedIn: true } });
        localStorage.setItem("isAuthenticated", true);
        // window.location.pathname = "/";
        window.location.href = "/";
        // dispatch(loginSuccess(result.data));
      })
      .catch((error) => {
        localStorage.setItem("isAuthenticated", false);
        dispatch(loginFailure(error));
      });
};
export const forgetPasswordAction = (emailAddress) => {
  return axiosInstance.post("account/forgetPassword", { emailAddress });
};

export const resetPasswordAction = (newPassword, resetToken) => {
  return axiosInstance.post("account/resetPassword", {
    newPassword,
    resetToken,
  });
};

export const resetPasswordURLVisitAction = (resetToken) => {
  return axiosInstance.post("account/resetpasswordurlvisitentry", {
    resetToken,
  });
};

export const logout = () => {
  // alert("hllo")
  localStorage && localStorage.setItem("keepMeLogin", false);
  localStorage && localStorage.removeItem("isAuthenticated");
  localStorage && localStorage.removeItem("state");
  sessionStorage && sessionStorage.removeItem("reloaded");

  // saveState({});
  // return dispatch => {

  //   localStorage && localStorage.setItem('keepMeLogin', false);
  //   localStorage && localStorage.removeItem('isAuthenticated');
  //   saveState({});
  //   dispatch(logoutAction());
  // }
};
