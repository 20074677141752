import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as authActions from "../action/authActions";
import { saveState } from "../store/LocalStorage";
import initialState from "../store/Reducers/initialState";
import { useHistory } from "react-router-dom";

import { bindActionCreators } from "redux";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const history = useHistory();

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      localStorage &&
      localStorage.getItem("keepMeLogin") === "false" &&
      sessionStorage &&
      !sessionStorage.getItem("reloaded")
    ) {
      saveState({});
    }

    if (localStorage.getItem("state") === null) {
      history.push("/signin");
      window.location.reload();
    }

    if (Object.keys(JSON.parse(localStorage.getItem("state"))).length === 0) {
      history.push("/signin");
      window.location.reload();
    }
  }, [localStorage.getItem("state")]);

  const checkAuth = () => {
    let authenticated = restOfProps.auth.loggedIn;
    // console.log("ROPs", restOfProps);
    if (!authenticated) return false;
    return true;
  };

  return (
    <Route
      {...restOfProps}
      render={
        (props) =>
          // localStorage && localStorage.getItem('isAuthenticated') === 'true' ? <Component {...props} /> : <Redirect to="/signin" />
          checkAuth() ? (
            <Component {...props} />
          ) : (
            () => {
              history.push("/signin");
              window.location.reload();
            }
          )
        // <Redirect to="/signin" />
      }
    />
  );
}

const mapStatetoProps = (state) => {
  return {
    isLoading: state.global.isLoading,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: bindActionCreators(dispatch),
  };
};
export default connect(mapStatetoProps, mapDispatchToProps)(ProtectedRoute);

// export default ProtectedRoute;
