import React, { useEffect, useState } from "react";
import EldcLogo from "../../../images/MedVR_Logo.png";
import Background from "../../../images/company-profile-bg.jpg";
import camera from "../../../images/camera.svg";
import { renderInputField } from "../../../common/InputFields";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../../action/userActions";
import { get } from "lodash";
import Swal from "sweetalert2";
import * as companyActions from "../../../action/companyActions";
import {
  getDepartment,
  updateDepartmentById,
} from "../../../action/departmentActions";
import Search from "../../common/Search";
import { bindActionCreators } from "redux";
import * as moduleActions from "../../../action/moduleActions";
import Ellipse9 from "../../../images/Ellipse9.png";
import MobileNavBar from "../../../components/mobileNavBar";

let ModifyCompany = (props) => {
  const { token, user, allModulesByCompany } = props;
  const [department, setDepartment] = useState({});
  const [selectedModules, setSelectedModules] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.moduleActions.getAllModulesByCompany(token, user.companyId);

    setLoading(true)
    getDepartment(props.match.params.id).then((result) => {
      setLoading(false)
      setDepartment(result.data);
      setSelectedModules(result.data.modules);
    });
  }, []);

  const onModuleItemSelected = (module) => {
    if (
      !selectedModules.find(
        (selectedModule) => selectedModule._id === module._id
      )
    )
      setSelectedModules([...selectedModules, module]);
  };

  const onRemoveModuleClick = (e, moduleID) => {
    const newSelectedModules = selectedModules.filter(
      (item) => item._id !== moduleID
    );
    setSelectedModules([...newSelectedModules]);
  };

  const updateDepartment = (e) => {
    const updatedDepartment = Object.assign({}, department);
    updatedDepartment[e.target.name] = e.target.value;
    setDepartment(updatedDepartment);
  };

  const saveDepartment = () => {
    department.modules = selectedModules.map(item => item._id);
    console.log("idd", department.modules)
    department.departmentLogo = CompanyLogo;

    updateDepartmentById(department._id, department)
      .then(() => {
        Swal.fire({
          allowOutsideClick: false,
          title: "Success",
          text: "Department Updated Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(function () {
          if (user.role === "SuperAdmin") {
            history.push("/departments");
          } else {
            history.push("/departments");
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          allowOutsideClick: false,
          title: "Error",
          text: `Error: ${error.response.data.message
              ? error.response.data.message
              : error.response.data
                ? error.response.data
                : error.message
            }`,
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  };

  const [CompanyLogo, setCompanyLogo] = useState();
  const [HomePage, setHomepage] = useState();
  const [Favicon, setFavicon] = useState();
  const history = useHistory();

  const onUpload = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      setCompanyLogo(res);
    });
  };
  const onUploadHome = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      setHomepage(res);
    });
  };

  const onUploadFavicon = (event) => {
    userActions.uploadFile(token, get(event, "target.files[0]")).then((res) => {
      setFavicon(res);
    });
  };

  const ModifyCompanyPage = (values = {}) => {
    values.logo = CompanyLogo;
    values.homepageImage = HomePage;
    values.favicon = Favicon;
    values.passingScoreInPercentage = "0";

    companyActions
      .modifyCompany(token, {
        ...values,
        maximumNumberOfUsers: parseInt(values.maximumNumberOfUsers || 0),
        theme: "light-theme",
        companyHeading: values.companyName,
      })
      .then((result) => {
        //   toast.info("Company  Added Successfully");
        Swal.fire({
          allowOutsideClick: false,
          title: "Success",
          text: "Company  Modify Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(function () {
          history.push("/departments");
        });
      })
      .catch((error) =>
        //   toast.error(error.response ? error.response.data.message:error.message)
        Swal.fire({
          allowOutsideClick: false,
          title: "Error",
          text: `Error: ${error.response.data.message
              ? error.response.data.message
              : error.response.data
            }`,
          icon: "error",
          confirmButtonText: "Ok",
        })
      );
  };

  var profileBackgroundStyle = {
    width: "100%",
    backgroundImage: `url(${get(HomePage, "Location") || Background})`,
  };

  return (
    <>
      <div className="overview-cont">
        <div className="row">
          <div className="col-xl-12 col-lg-12 overview-left-area your-module">
            <MobileNavBar />
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="right_hading d-none d-sm-inline">
                Modify Department
              </h2>

              <div className="mt-3">
                <a>
                  <Link to="/departments" className="adcm-btn Cancel ms-1">
                    <span>Cancel</span>
                  </Link>
                </a>
                <a>
                  <button className="adcm-btn ms-1" onClick={saveDepartment}>
                    Save
                  </button>
                </a>
              </div>
            </div>
            <h2 className="right_hading d-sm-none d-block">Dashboard</h2>

            <div className="row">
              <div className="col-lg-12">
                <div className="company-main">
                  {/* <div
                    className="company-profile"
                    style={profileBackgroundStyle}
                  >
                    <a className="profile-changer">
                      <img className="me-1" src={linkBg} alt="" /> Change Image
                      <input
                        onChange={onUploadHome}
                        accept={".jpg, .jpeg, .png"}
                        type="file"
                        style={{
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                        }}
                      ></input>
                    </a>
                  </div> */}
                  <div className="company-fillout" style={{ marginTop: "5em" }}>
                    <div className="circle-outer">
                      <div className="circle">
                        <img
                          className="profile-pic"
                          src={
                            get(CompanyLogo, "Location") ||
                            department.departmentLogo?.Location
                          }
                          alt=""
                        />
                      </div>
                      <div className="p-image">
                        <div
                          className="photoEdit"
                          style={{ position: "relative" }}
                        >
                          <input
                            onChange={onUpload}
                            accept={".jpg, .jpeg, .png"}
                            type="file"
                            style={{
                              opacity: 0,
                              width: "100%",
                              position: "absolute",
                            }}
                          ></input>
                          <img className="upload-button" src={camera} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Department Name</label>
                          <input
                            name="departmentName"
                            type="text"
                            label="Department Name"
                            className="form-control"
                            value={department?.departmentName}
                            onChange={(e) => updateDepartment(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Maximum Number of Users</label>
                          {/* <input type="text" className="form-control" placeholder="100" autocomplete="off" /> */}
                          <input
                            name="max_users"
                            type="number"
                            label="Maximum Number of Users"
                            className="form-control"
                            value={department?.max_users}
                            onChange={(e) => updateDepartment(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-4">
                        <div className="form-group">
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              <label>Modules</label>
                              <Search
                                {...{
                                  data: allModulesByCompany,
                                  onItemSelected: onModuleItemSelected,
                                  populateFieldName: "moduleName",
                                  placeholderLabel: "Select Modules",
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex2fieldForm">
                            <div className="field-group">
                              {selectedModules &&
                                selectedModules.length > 0 && (
                                  <div className="flex2fieldForm">
                                    <div className="field-group">
                                      <div
                                        className="radioGroup"
                                        style={{ marginTop: "3em" }}
                                      >
                                        {selectedModules.map((item, index) => (
                                          <div
                                            key={`${item}-${index}`}
                                            className="mr-4 moduleCompetencyCard"
                                          >
                                            <label htmlFor="Module">
                                              {item.moduleName}
                                            </label>
                                            <button
                                              onClick={(e) =>
                                                onRemoveModuleClick(e, item._id)
                                              }
                                            >
                                              X
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!loading ?
                      <></>
                      :
                      <div className="loader-wrapper">
                        <div className="loader"></div>

                      </div>

                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// ModifyCompany = reduxForm({
//   form: "modifycompany",

//   enableReinitialize: true,
//   validate,
// })(ModifyCompany);

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
    initialValues: state.companies.selectedCompany,
    allModulesByCompany: state.modules.allModulesByCompany,
    modifiedCompany: state.form.modifycompany
      ? state.form.modifycompany.values
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    moduleActions: bindActionCreators(moduleActions, dispatch),
    dispatch: dispatch,
  };
};
ModifyCompany = connect(mapStatetoProps, mapDispatchToProps)(ModifyCompany);
export default ModifyCompany;
// export default ModifyCompany;
